@charset "UTF-8";
/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #4f46e5;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #4f46e5;
  --bs-secondary: #000;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #1b1b1b;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1b1b;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.12; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 1.5rem; }

h2, .h2 {
  font-size: 1.375rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.8em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #4f46e5;
  text-decoration: none !important; }

a:hover {
  color: #7246e5; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8em; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

code {
  font-size: 0.8em;
  color: #d63384;
  word-wrap: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8em;
  color: #fff;
  background-color: #1b1b1b;
  border-radius: 0.2rem; }

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #495057;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

select:disabled {
  opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit; }

legend + * {
  clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2; }

.display-5 {
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 1.2; }

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem; }

.initialism {
  font-size: 0.8em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote > :last-child {
  margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.8em;
  color: #495057; }

.blockquote-footer::before {
  content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.8em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 560px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 750px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 970px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1170px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }

.row > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y); }

.col {
  -ms-flex: 1 0 0%;
      flex: 1 0 0%; }

.row-cols-auto > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto; }

.col-1 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 25%; }

.col-4 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 50%; }

.col-7 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 75%; }

.col-10 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 4.5rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 4.5rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 6rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 6rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 7.5rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 7.5rem; }

.g-9,
.gx-9 {
  --bs-gutter-x: 9rem; }

.g-9,
.gy-9 {
  --bs-gutter-y: 9rem; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 4.5rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 4.5rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 6rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 6rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 7.5rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 7.5rem; }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 9rem; }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%; }
  .row-cols-md-auto > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 4.5rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 4.5rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 6rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 6rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 7.5rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 7.5rem; }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 9rem; }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 4.5rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 4.5rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 6rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 6rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 7.5rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 7.5rem; }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 9rem; }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 4.5rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 4.5rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 6rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 6rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 7.5rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 7.5rem; }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 9rem; }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 9rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 4.5rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 4.5rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 6rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 6rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 7.5rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 7.5rem; }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 9rem; }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 9rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #1b1b1b;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #1b1b1b;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #1b1b1b;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #1b1b1b;
  vertical-align: top;
  border-color: #dee2e6; }

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }

.table > tbody {
  vertical-align: inherit; }

.table > thead {
  vertical-align: bottom; }

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #1046e5;
  --bs-table-striped-bg: #1c4fe6;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2859e8;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2254e7;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #2859e8; }

.table-secondary {
  --bs-table-bg: black;
  --bs-table-striped-bg: #0d0d0d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a1a1a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #131313;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1a1a1a; }

.table-success {
  --bs-table-bg: #058754;
  --bs-table-striped-bg: #128d5d;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #1e9365;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #189061;
  --bs-table-hover-color: #000;
  color: #fff;
  border-color: #1e9365; }

.table-info {
  --bs-table-bg: #03caf0;
  --bs-table-striped-bg: #02c0e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #02b6d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #02bbde;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #02b6d8; }

.table-warning {
  --bs-table-bg: #33c107;
  --bs-table-striped-bg: #30b707;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #2eae06;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #2fb306;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #2eae06; }

.table-danger {
  --bs-table-bg: #2c3545;
  --bs-table-striped-bg: #373f4e;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #414958;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #3c4453;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #414958; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #1b1b1b;
  --bs-table-striped-bg: #262626;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #323232;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2c2c2c;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #323232; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: #495057; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1b1b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type="file"] {
  overflow: hidden; }

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #1b1b1b;
  background-color: #fff;
  border-color: #a7a3f2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
     -moz-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #1b1b1b;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #1b1b1b;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #1b1b1b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
     -moz-margin-end: 0.5rem;
          margin-inline-end: 0.5rem; }

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
     -moz-margin-end: 1rem;
          margin-inline-end: 1rem; }

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 1.5rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.75rem; }

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0; }

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1b1b;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

.form-select:focus {
  border-color: #a7a3f2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none; }

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1b1b1b; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none; } }

.form-check-input[type="checkbox"] {
  border-radius: 0.25em; }

.form-check-input[type="radio"] {
  border-radius: 50%; }

.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%); }

.form-check-input:focus {
  border-color: #a7a3f2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.form-check-input:checked {
  background-color: #4f46e5;
  border-color: #4f46e5; }

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #4f46e5;
  border-color: #4f46e5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5; }

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none; } }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a7a3f2'/%3e%3c/svg%3e"); }

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

.form-range:focus {
  outline: 0; }

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(79, 70, 229, 0); }

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(79, 70, 229, 0); }

.form-range::-moz-focus-outer {
  border: 0; }

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4f46e5;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

.form-range::-webkit-slider-thumb:active {
  background-color: #cac8f7; }

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4f46e5;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

.form-range::-moz-range-thumb:active {
  background-color: #cac8f7; }

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range:disabled {
  pointer-events: none; }

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.form-floating {
  position: relative; }

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 1rem; }

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none; } }

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent; }

.form-floating > .form-control::-moz-placeholder {
  color: transparent; }

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent; }

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent; }

.form-floating > .form-control::placeholder {
  color: transparent; }

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3; }

.input-group .btn {
  position: relative;
  z-index: 2; }

.input-group .btn:focus {
  z-index: 3; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1b1b;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 3.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754; }

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 3.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545; }

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1b1b;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #1b1b1b; }

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.btn-primary:hover {
  color: #fff;
  background-color: #433cc3;
  border-color: #3f38b7; }

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #433cc3;
  border-color: #3f38b7;
  box-shadow: 0 0 0 0 rgba(105, 98, 233, 0.5); }

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3f38b7;
  border-color: #3b35ac; }

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(105, 98, 233, 0.5); }

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.btn-secondary {
  color: #fff;
  background-color: #000;
  border-color: #000; }

.btn-secondary:hover {
  color: #fff;
  background-color: black;
  border-color: black; }

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0 rgba(38, 38, 38, 0.5); }

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black; }

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(38, 38, 38, 0.5); }

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43; }

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5); }

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f; }

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5); }

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2; }

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5); }

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2; }

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5); }

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720; }

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5); }

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720; }

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5); }

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37; }

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834; }

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5); }

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5); }

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b; }

.btn-dark:hover {
  color: #fff;
  background-color: #171717;
  border-color: #161616; }

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #171717;
  border-color: #161616;
  box-shadow: 0 0 0 0 rgba(61, 61, 61, 0.5); }

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #161616;
  border-color: #141414; }

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(61, 61, 61, 0.5); }

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b; }

.btn-outline-primary {
  color: #4f46e5;
  border-color: #4f46e5; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0.5); }

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0.5); }

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #4f46e5;
  background-color: transparent; }

.btn-outline-secondary {
  color: #000;
  border-color: #000; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000; }

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000; }

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #000;
  background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5); }

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5); }

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5); }

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5); }

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-dark {
  color: #1b1b1b;
  border-color: #1b1b1b; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b; }

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(27, 27, 27, 0.5); }

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b; }

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(27, 27, 27, 0.5); }

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #1b1b1b;
  background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #4f46e5;
  text-decoration: none !important; }

.btn-link:hover {
  color: #7246e5; }

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 12.5rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #1b1b1b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0; }

.dropdown-menu-end {
  --bs-position: end; }

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1b1b1b;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:hover, .dropdown-item:focus {
  color: #181818;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #4f46e5;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #1b1b1b; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #1b1b1b;
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #4f46e5;
  background-color: #f8f9fa; }

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd; }

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0; }

.dropstart .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #4f46e5;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none; } }

.nav-link:hover, .nav-link:focus {
  color: #7246e5; }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4f46e5; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none; } }

.navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand-xxl .navbar-toggler {
    display: none; } }

.navbar-expand {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
      flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.navbar-expand .navbar-nav-scroll {
  overflow: visible; }

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: #4f46e5; }

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #4f46e5; }

.navbar-light .navbar-nav .nav-link {
  color: rgba(27, 27, 27, 0.9); }

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(27, 27, 27, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(27, 27, 27, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #4f46e5; }

.navbar-light .navbar-toggler {
  color: rgba(27, 27, 27, 0.9);
  border-color: transparent; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(27, 27, 27, 0.9); }

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #4f46e5; }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9); }

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9); }

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit; }

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.83333rem; }

.card-subtitle {
  margin-top: -0.41667rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: 0 0 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap; }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #1b1b1b;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none; } }

.accordion-button:not(.collapsed) {
  color: #473fce;
  background-color: #ededfc;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23473fce'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.accordion-button::after {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231b1b1b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none; } }

.accordion-button:hover {
  z-index: 2; }

.accordion-button:focus {
  z-index: 3;
  border-color: #a7a3f2;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-item:not(:first-of-type) {
  border-top: 0; }

.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.accordion-flush .accordion-item:first-child {
  border-top: 0; }

.accordion-flush .accordion-item:last-child {
  border-bottom: 0; }

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/"); }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #4f46e5;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none; } }

.page-link:hover {
  z-index: 2;
  color: #7246e5;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 3;
  color: #7246e5;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem; }

.alert-primary {
  color: #2f2a89;
  background-color: #dcdafa;
  border-color: #cac8f7; }

.alert-primary .alert-link {
  color: #26226e; }

.alert-secondary {
  color: black;
  background-color: #cccccc;
  border-color: #b3b3b3; }

.alert-secondary .alert-link {
  color: black; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }

.alert-success .alert-link {
  color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }

.alert-info .alert-link {
  color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }

.alert-warning .alert-link {
  color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }

.alert-danger .alert-link {
  color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light .alert-link {
  color: #4f5050; }

.alert-dark {
  color: #101010;
  background-color: #d1d1d1;
  border-color: #bbbbbb; }

.alert-dark .alert-link {
  color: #0d0d0d; }

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4f46e5;
  transition: width 0.6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none; } }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #1b1b1b;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #1b1b1b;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.list-group-item + .list-group-item {
  border-top-width: 0; }

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px; }

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row; }

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0; }

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0; }

.list-group-horizontal > .list-group-item.active {
  margin-top: 0; }

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0; }

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row; }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row; }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row; }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row; }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
        flex-direction: row; }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #2f2a89;
  background-color: #dcdafa; }

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #2f2a89;
  background-color: #c6c4e1; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2f2a89;
  border-color: #2f2a89; }

.list-group-item-secondary {
  color: black;
  background-color: #cccccc; }

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464; }

.list-group-item-dark {
  color: #101010;
  background-color: #d1d1d1; }

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #101010;
  background-color: #bcbcbc; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #101010;
  border-color: #101010; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 0.5; }

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75; }

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(79, 70, 229, 0);
  opacity: 1; }

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.25; }

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.toast:not(.showing):not(.show) {
  opacity: 0; }

.toast.hide {
  display: none; }

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }

.toast-container > :not(:last-child) {
  margin-bottom: 1.5rem; }

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none; }

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }

.modal-footer > * {
  margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.modal-fullscreen .modal-header {
  border-radius: 0; }

.modal-fullscreen .modal-body {
  overflow-y: auto; }

.modal-fullscreen .modal-footer {
  border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: 0.9; }

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem; }

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem; }

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #1b1b1b; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none; } }

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
          filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
          animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none; } }

.offcanvas-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem; }

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  -ms-flex-positive: 1;
      flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.offcanvas.show {
  -webkit-transform: none;
          transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #4f46e5; }

.link-primary:hover, .link-primary:focus {
  color: #3f38b7; }

.link-secondary {
  color: #000; }

.link-secondary:hover, .link-secondary:focus {
  color: black; }

.link-success {
  color: #198754; }

.link-success:hover, .link-success:focus {
  color: #146c43; }

.link-info {
  color: #0dcaf0; }

.link-info:hover, .link-info:focus {
  color: #3dd5f3; }

.link-warning {
  color: #ffc107; }

.link-warning:hover, .link-warning:focus {
  color: #ffcd39; }

.link-danger {
  color: #dc3545; }

.link-danger:hover, .link-danger:focus {
  color: #b02a37; }

.link-light {
  color: #f8f9fa; }

.link-light:hover, .link-light:focus {
  color: #f9fafb; }

.link-dark {
  color: #1b1b1b; }

.link-dark:hover, .link-dark:focus {
  color: #161616; }

.ratio {
  position: relative;
  width: 100%; }

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""; }

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important; }

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #4f46e5 !important; }

.border-secondary {
  border-color: #000 !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #1b1b1b !important; }

.border-white {
  border-color: #fff !important; }

.border-0 {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.gap-6 {
  gap: 4.5rem !important; }

.gap-7 {
  gap: 6rem !important; }

.gap-8 {
  gap: 7.5rem !important; }

.gap-9 {
  gap: 9rem !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.justify-content-evenly {
  -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

.order-first {
  -ms-flex-order: -1 !important;
      order: -1 !important; }

.order-0 {
  -ms-flex-order: 0 !important;
      order: 0 !important; }

.order-1 {
  -ms-flex-order: 1 !important;
      order: 1 !important; }

.order-2 {
  -ms-flex-order: 2 !important;
      order: 2 !important; }

.order-3 {
  -ms-flex-order: 3 !important;
      order: 3 !important; }

.order-4 {
  -ms-flex-order: 4 !important;
      order: 4 !important; }

.order-5 {
  -ms-flex-order: 5 !important;
      order: 5 !important; }

.order-last {
  -ms-flex-order: 6 !important;
      order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.m-8 {
  margin: 7.5rem !important; }

.m-9 {
  margin: 9rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important; }

.mx-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important; }

.my-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-6 {
  margin-top: 4.5rem !important; }

.mt-7 {
  margin-top: 6rem !important; }

.mt-8 {
  margin-top: 7.5rem !important; }

.mt-9 {
  margin-top: 9rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-6 {
  margin-right: 4.5rem !important; }

.me-7 {
  margin-right: 6rem !important; }

.me-8 {
  margin-right: 7.5rem !important; }

.me-9 {
  margin-right: 9rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-6 {
  margin-bottom: 4.5rem !important; }

.mb-7 {
  margin-bottom: 6rem !important; }

.mb-8 {
  margin-bottom: 7.5rem !important; }

.mb-9 {
  margin-bottom: 9rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-6 {
  margin-left: 4.5rem !important; }

.ms-7 {
  margin-left: 6rem !important; }

.ms-8 {
  margin-left: 7.5rem !important; }

.ms-9 {
  margin-left: 9rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.m-n6 {
  margin: -4.5rem !important; }

.m-n7 {
  margin: -6rem !important; }

.m-n8 {
  margin: -7.5rem !important; }

.m-n9 {
  margin: -9rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important; }

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important; }

.mx-n8 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important; }

.mx-n9 {
  margin-right: -9rem !important;
  margin-left: -9rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important; }

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.my-n8 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important; }

.my-n9 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mt-n6 {
  margin-top: -4.5rem !important; }

.mt-n7 {
  margin-top: -6rem !important; }

.mt-n8 {
  margin-top: -7.5rem !important; }

.mt-n9 {
  margin-top: -9rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -3rem !important; }

.me-n6 {
  margin-right: -4.5rem !important; }

.me-n7 {
  margin-right: -6rem !important; }

.me-n8 {
  margin-right: -7.5rem !important; }

.me-n9 {
  margin-right: -9rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.mb-n6 {
  margin-bottom: -4.5rem !important; }

.mb-n7 {
  margin-bottom: -6rem !important; }

.mb-n8 {
  margin-bottom: -7.5rem !important; }

.mb-n9 {
  margin-bottom: -9rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -3rem !important; }

.ms-n6 {
  margin-left: -4.5rem !important; }

.ms-n7 {
  margin-left: -6rem !important; }

.ms-n8 {
  margin-left: -7.5rem !important; }

.ms-n9 {
  margin-left: -9rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.p-8 {
  padding: 7.5rem !important; }

.p-9 {
  padding: 9rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important; }

.px-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; }

.py-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pt-6 {
  padding-top: 4.5rem !important; }

.pt-7 {
  padding-top: 6rem !important; }

.pt-8 {
  padding-top: 7.5rem !important; }

.pt-9 {
  padding-top: 9rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pe-6 {
  padding-right: 4.5rem !important; }

.pe-7 {
  padding-right: 6rem !important; }

.pe-8 {
  padding-right: 7.5rem !important; }

.pe-9 {
  padding-right: 9rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pb-6 {
  padding-bottom: 4.5rem !important; }

.pb-7 {
  padding-bottom: 6rem !important; }

.pb-8 {
  padding-bottom: 7.5rem !important; }

.pb-9 {
  padding-bottom: 9rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.ps-6 {
  padding-left: 4.5rem !important; }

.ps-7 {
  padding-left: 6rem !important; }

.ps-8 {
  padding-left: 7.5rem !important; }

.ps-9 {
  padding-left: 9rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: 1.5rem !important; }

.fs-2 {
  font-size: 1.375rem !important; }

.fs-3 {
  font-size: 1.25rem !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 1rem !important; }

.fs-6 {
  font-size: 0.875rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #4f46e5 !important; }

.text-secondary {
  color: #000 !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #1b1b1b !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #1b1b1b !important; }

.text-muted {
  color: #495057 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #4f46e5 !important; }

.bg-secondary {
  background-color: #000 !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #1b1b1b !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0 !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-end {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-start {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .gap-sm-6 {
    gap: 4.5rem !important; }
  .gap-sm-7 {
    gap: 6rem !important; }
  .gap-sm-8 {
    gap: 7.5rem !important; }
  .gap-sm-9 {
    gap: 9rem !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .justify-content-sm-evenly {
    -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .order-sm-first {
    -ms-flex-order: -1 !important;
        order: -1 !important; }
  .order-sm-0 {
    -ms-flex-order: 0 !important;
        order: 0 !important; }
  .order-sm-1 {
    -ms-flex-order: 1 !important;
        order: 1 !important; }
  .order-sm-2 {
    -ms-flex-order: 2 !important;
        order: 2 !important; }
  .order-sm-3 {
    -ms-flex-order: 3 !important;
        order: 3 !important; }
  .order-sm-4 {
    -ms-flex-order: 4 !important;
        order: 4 !important; }
  .order-sm-5 {
    -ms-flex-order: 5 !important;
        order: 5 !important; }
  .order-sm-last {
    -ms-flex-order: 6 !important;
        order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-6 {
    margin: 4.5rem !important; }
  .m-sm-7 {
    margin: 6rem !important; }
  .m-sm-8 {
    margin: 7.5rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-6 {
    margin-top: 4.5rem !important; }
  .mt-sm-7 {
    margin-top: 6rem !important; }
  .mt-sm-8 {
    margin-top: 7.5rem !important; }
  .mt-sm-9 {
    margin-top: 9rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-6 {
    margin-right: 4.5rem !important; }
  .me-sm-7 {
    margin-right: 6rem !important; }
  .me-sm-8 {
    margin-right: 7.5rem !important; }
  .me-sm-9 {
    margin-right: 9rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-7 {
    margin-bottom: 6rem !important; }
  .mb-sm-8 {
    margin-bottom: 7.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 9rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-6 {
    margin-left: 4.5rem !important; }
  .ms-sm-7 {
    margin-left: 6rem !important; }
  .ms-sm-8 {
    margin-left: 7.5rem !important; }
  .ms-sm-9 {
    margin-left: 9rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .m-sm-n6 {
    margin: -4.5rem !important; }
  .m-sm-n7 {
    margin: -6rem !important; }
  .m-sm-n8 {
    margin: -7.5rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-sm-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-sm-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-sm-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mt-sm-n6 {
    margin-top: -4.5rem !important; }
  .mt-sm-n7 {
    margin-top: -6rem !important; }
  .mt-sm-n8 {
    margin-top: -7.5rem !important; }
  .mt-sm-n9 {
    margin-top: -9rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -3rem !important; }
  .me-sm-n6 {
    margin-right: -4.5rem !important; }
  .me-sm-n7 {
    margin-right: -6rem !important; }
  .me-sm-n8 {
    margin-right: -7.5rem !important; }
  .me-sm-n9 {
    margin-right: -9rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-sm-n7 {
    margin-bottom: -6rem !important; }
  .mb-sm-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -9rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -3rem !important; }
  .ms-sm-n6 {
    margin-left: -4.5rem !important; }
  .ms-sm-n7 {
    margin-left: -6rem !important; }
  .ms-sm-n8 {
    margin-left: -7.5rem !important; }
  .ms-sm-n9 {
    margin-left: -9rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .p-sm-6 {
    padding: 4.5rem !important; }
  .p-sm-7 {
    padding: 6rem !important; }
  .p-sm-8 {
    padding: 7.5rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pt-sm-6 {
    padding-top: 4.5rem !important; }
  .pt-sm-7 {
    padding-top: 6rem !important; }
  .pt-sm-8 {
    padding-top: 7.5rem !important; }
  .pt-sm-9 {
    padding-top: 9rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pe-sm-6 {
    padding-right: 4.5rem !important; }
  .pe-sm-7 {
    padding-right: 6rem !important; }
  .pe-sm-8 {
    padding-right: 7.5rem !important; }
  .pe-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important; }
  .pb-sm-7 {
    padding-bottom: 6rem !important; }
  .pb-sm-8 {
    padding-bottom: 7.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 9rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .ps-sm-6 {
    padding-left: 4.5rem !important; }
  .ps-sm-7 {
    padding-left: 6rem !important; }
  .ps-sm-8 {
    padding-left: 7.5rem !important; }
  .ps-sm-9 {
    padding-left: 9rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .gap-md-6 {
    gap: 4.5rem !important; }
  .gap-md-7 {
    gap: 6rem !important; }
  .gap-md-8 {
    gap: 7.5rem !important; }
  .gap-md-9 {
    gap: 9rem !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .justify-content-md-evenly {
    -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .order-md-first {
    -ms-flex-order: -1 !important;
        order: -1 !important; }
  .order-md-0 {
    -ms-flex-order: 0 !important;
        order: 0 !important; }
  .order-md-1 {
    -ms-flex-order: 1 !important;
        order: 1 !important; }
  .order-md-2 {
    -ms-flex-order: 2 !important;
        order: 2 !important; }
  .order-md-3 {
    -ms-flex-order: 3 !important;
        order: 3 !important; }
  .order-md-4 {
    -ms-flex-order: 4 !important;
        order: 4 !important; }
  .order-md-5 {
    -ms-flex-order: 5 !important;
        order: 5 !important; }
  .order-md-last {
    -ms-flex-order: 6 !important;
        order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-6 {
    margin: 4.5rem !important; }
  .m-md-7 {
    margin: 6rem !important; }
  .m-md-8 {
    margin: 7.5rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-6 {
    margin-top: 4.5rem !important; }
  .mt-md-7 {
    margin-top: 6rem !important; }
  .mt-md-8 {
    margin-top: 7.5rem !important; }
  .mt-md-9 {
    margin-top: 9rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-6 {
    margin-right: 4.5rem !important; }
  .me-md-7 {
    margin-right: 6rem !important; }
  .me-md-8 {
    margin-right: 7.5rem !important; }
  .me-md-9 {
    margin-right: 9rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-6 {
    margin-bottom: 4.5rem !important; }
  .mb-md-7 {
    margin-bottom: 6rem !important; }
  .mb-md-8 {
    margin-bottom: 7.5rem !important; }
  .mb-md-9 {
    margin-bottom: 9rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-6 {
    margin-left: 4.5rem !important; }
  .ms-md-7 {
    margin-left: 6rem !important; }
  .ms-md-8 {
    margin-left: 7.5rem !important; }
  .ms-md-9 {
    margin-left: 9rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .m-md-n6 {
    margin: -4.5rem !important; }
  .m-md-n7 {
    margin: -6rem !important; }
  .m-md-n8 {
    margin: -7.5rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-md-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-md-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-md-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-md-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mt-md-n6 {
    margin-top: -4.5rem !important; }
  .mt-md-n7 {
    margin-top: -6rem !important; }
  .mt-md-n8 {
    margin-top: -7.5rem !important; }
  .mt-md-n9 {
    margin-top: -9rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -3rem !important; }
  .me-md-n6 {
    margin-right: -4.5rem !important; }
  .me-md-n7 {
    margin-right: -6rem !important; }
  .me-md-n8 {
    margin-right: -7.5rem !important; }
  .me-md-n9 {
    margin-right: -9rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-md-n7 {
    margin-bottom: -6rem !important; }
  .mb-md-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -9rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -3rem !important; }
  .ms-md-n6 {
    margin-left: -4.5rem !important; }
  .ms-md-n7 {
    margin-left: -6rem !important; }
  .ms-md-n8 {
    margin-left: -7.5rem !important; }
  .ms-md-n9 {
    margin-left: -9rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .p-md-6 {
    padding: 4.5rem !important; }
  .p-md-7 {
    padding: 6rem !important; }
  .p-md-8 {
    padding: 7.5rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pt-md-6 {
    padding-top: 4.5rem !important; }
  .pt-md-7 {
    padding-top: 6rem !important; }
  .pt-md-8 {
    padding-top: 7.5rem !important; }
  .pt-md-9 {
    padding-top: 9rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pe-md-6 {
    padding-right: 4.5rem !important; }
  .pe-md-7 {
    padding-right: 6rem !important; }
  .pe-md-8 {
    padding-right: 7.5rem !important; }
  .pe-md-9 {
    padding-right: 9rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pb-md-6 {
    padding-bottom: 4.5rem !important; }
  .pb-md-7 {
    padding-bottom: 6rem !important; }
  .pb-md-8 {
    padding-bottom: 7.5rem !important; }
  .pb-md-9 {
    padding-bottom: 9rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .ps-md-6 {
    padding-left: 4.5rem !important; }
  .ps-md-7 {
    padding-left: 6rem !important; }
  .ps-md-8 {
    padding-left: 7.5rem !important; }
  .ps-md-9 {
    padding-left: 9rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .gap-lg-6 {
    gap: 4.5rem !important; }
  .gap-lg-7 {
    gap: 6rem !important; }
  .gap-lg-8 {
    gap: 7.5rem !important; }
  .gap-lg-9 {
    gap: 9rem !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .justify-content-lg-evenly {
    -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .order-lg-first {
    -ms-flex-order: -1 !important;
        order: -1 !important; }
  .order-lg-0 {
    -ms-flex-order: 0 !important;
        order: 0 !important; }
  .order-lg-1 {
    -ms-flex-order: 1 !important;
        order: 1 !important; }
  .order-lg-2 {
    -ms-flex-order: 2 !important;
        order: 2 !important; }
  .order-lg-3 {
    -ms-flex-order: 3 !important;
        order: 3 !important; }
  .order-lg-4 {
    -ms-flex-order: 4 !important;
        order: 4 !important; }
  .order-lg-5 {
    -ms-flex-order: 5 !important;
        order: 5 !important; }
  .order-lg-last {
    -ms-flex-order: 6 !important;
        order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-6 {
    margin: 4.5rem !important; }
  .m-lg-7 {
    margin: 6rem !important; }
  .m-lg-8 {
    margin: 7.5rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-6 {
    margin-top: 4.5rem !important; }
  .mt-lg-7 {
    margin-top: 6rem !important; }
  .mt-lg-8 {
    margin-top: 7.5rem !important; }
  .mt-lg-9 {
    margin-top: 9rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-6 {
    margin-right: 4.5rem !important; }
  .me-lg-7 {
    margin-right: 6rem !important; }
  .me-lg-8 {
    margin-right: 7.5rem !important; }
  .me-lg-9 {
    margin-right: 9rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-7 {
    margin-bottom: 6rem !important; }
  .mb-lg-8 {
    margin-bottom: 7.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 9rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-6 {
    margin-left: 4.5rem !important; }
  .ms-lg-7 {
    margin-left: 6rem !important; }
  .ms-lg-8 {
    margin-left: 7.5rem !important; }
  .ms-lg-9 {
    margin-left: 9rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .m-lg-n6 {
    margin: -4.5rem !important; }
  .m-lg-n7 {
    margin: -6rem !important; }
  .m-lg-n8 {
    margin: -7.5rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-lg-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-lg-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-lg-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mt-lg-n6 {
    margin-top: -4.5rem !important; }
  .mt-lg-n7 {
    margin-top: -6rem !important; }
  .mt-lg-n8 {
    margin-top: -7.5rem !important; }
  .mt-lg-n9 {
    margin-top: -9rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -3rem !important; }
  .me-lg-n6 {
    margin-right: -4.5rem !important; }
  .me-lg-n7 {
    margin-right: -6rem !important; }
  .me-lg-n8 {
    margin-right: -7.5rem !important; }
  .me-lg-n9 {
    margin-right: -9rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-lg-n7 {
    margin-bottom: -6rem !important; }
  .mb-lg-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -9rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -3rem !important; }
  .ms-lg-n6 {
    margin-left: -4.5rem !important; }
  .ms-lg-n7 {
    margin-left: -6rem !important; }
  .ms-lg-n8 {
    margin-left: -7.5rem !important; }
  .ms-lg-n9 {
    margin-left: -9rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .p-lg-6 {
    padding: 4.5rem !important; }
  .p-lg-7 {
    padding: 6rem !important; }
  .p-lg-8 {
    padding: 7.5rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pt-lg-6 {
    padding-top: 4.5rem !important; }
  .pt-lg-7 {
    padding-top: 6rem !important; }
  .pt-lg-8 {
    padding-top: 7.5rem !important; }
  .pt-lg-9 {
    padding-top: 9rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pe-lg-6 {
    padding-right: 4.5rem !important; }
  .pe-lg-7 {
    padding-right: 6rem !important; }
  .pe-lg-8 {
    padding-right: 7.5rem !important; }
  .pe-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important; }
  .pb-lg-7 {
    padding-bottom: 6rem !important; }
  .pb-lg-8 {
    padding-bottom: 7.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 9rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .ps-lg-6 {
    padding-left: 4.5rem !important; }
  .ps-lg-7 {
    padding-left: 6rem !important; }
  .ps-lg-8 {
    padding-left: 7.5rem !important; }
  .ps-lg-9 {
    padding-left: 9rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .gap-xl-6 {
    gap: 4.5rem !important; }
  .gap-xl-7 {
    gap: 6rem !important; }
  .gap-xl-8 {
    gap: 7.5rem !important; }
  .gap-xl-9 {
    gap: 9rem !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .justify-content-xl-evenly {
    -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .order-xl-first {
    -ms-flex-order: -1 !important;
        order: -1 !important; }
  .order-xl-0 {
    -ms-flex-order: 0 !important;
        order: 0 !important; }
  .order-xl-1 {
    -ms-flex-order: 1 !important;
        order: 1 !important; }
  .order-xl-2 {
    -ms-flex-order: 2 !important;
        order: 2 !important; }
  .order-xl-3 {
    -ms-flex-order: 3 !important;
        order: 3 !important; }
  .order-xl-4 {
    -ms-flex-order: 4 !important;
        order: 4 !important; }
  .order-xl-5 {
    -ms-flex-order: 5 !important;
        order: 5 !important; }
  .order-xl-last {
    -ms-flex-order: 6 !important;
        order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-6 {
    margin: 4.5rem !important; }
  .m-xl-7 {
    margin: 6rem !important; }
  .m-xl-8 {
    margin: 7.5rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-6 {
    margin-top: 4.5rem !important; }
  .mt-xl-7 {
    margin-top: 6rem !important; }
  .mt-xl-8 {
    margin-top: 7.5rem !important; }
  .mt-xl-9 {
    margin-top: 9rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-6 {
    margin-right: 4.5rem !important; }
  .me-xl-7 {
    margin-right: 6rem !important; }
  .me-xl-8 {
    margin-right: 7.5rem !important; }
  .me-xl-9 {
    margin-right: 9rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-7 {
    margin-bottom: 6rem !important; }
  .mb-xl-8 {
    margin-bottom: 7.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 9rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-6 {
    margin-left: 4.5rem !important; }
  .ms-xl-7 {
    margin-left: 6rem !important; }
  .ms-xl-8 {
    margin-left: 7.5rem !important; }
  .ms-xl-9 {
    margin-left: 9rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .m-xl-n6 {
    margin: -4.5rem !important; }
  .m-xl-n7 {
    margin: -6rem !important; }
  .m-xl-n8 {
    margin: -7.5rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mt-xl-n6 {
    margin-top: -4.5rem !important; }
  .mt-xl-n7 {
    margin-top: -6rem !important; }
  .mt-xl-n8 {
    margin-top: -7.5rem !important; }
  .mt-xl-n9 {
    margin-top: -9rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -3rem !important; }
  .me-xl-n6 {
    margin-right: -4.5rem !important; }
  .me-xl-n7 {
    margin-right: -6rem !important; }
  .me-xl-n8 {
    margin-right: -7.5rem !important; }
  .me-xl-n9 {
    margin-right: -9rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-xl-n7 {
    margin-bottom: -6rem !important; }
  .mb-xl-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -9rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -3rem !important; }
  .ms-xl-n6 {
    margin-left: -4.5rem !important; }
  .ms-xl-n7 {
    margin-left: -6rem !important; }
  .ms-xl-n8 {
    margin-left: -7.5rem !important; }
  .ms-xl-n9 {
    margin-left: -9rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .p-xl-6 {
    padding: 4.5rem !important; }
  .p-xl-7 {
    padding: 6rem !important; }
  .p-xl-8 {
    padding: 7.5rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pt-xl-6 {
    padding-top: 4.5rem !important; }
  .pt-xl-7 {
    padding-top: 6rem !important; }
  .pt-xl-8 {
    padding-top: 7.5rem !important; }
  .pt-xl-9 {
    padding-top: 9rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pe-xl-6 {
    padding-right: 4.5rem !important; }
  .pe-xl-7 {
    padding-right: 6rem !important; }
  .pe-xl-8 {
    padding-right: 7.5rem !important; }
  .pe-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important; }
  .pb-xl-7 {
    padding-bottom: 6rem !important; }
  .pb-xl-8 {
    padding-bottom: 7.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 9rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .ps-xl-6 {
    padding-left: 4.5rem !important; }
  .ps-xl-7 {
    padding-left: 6rem !important; }
  .ps-xl-8 {
    padding-left: 7.5rem !important; }
  .ps-xl-9 {
    padding-left: 9rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xxl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .gap-xxl-6 {
    gap: 4.5rem !important; }
  .gap-xxl-7 {
    gap: 6rem !important; }
  .gap-xxl-8 {
    gap: 7.5rem !important; }
  .gap-xxl-9 {
    gap: 9rem !important; }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    -ms-flex-pack: space-evenly !important;
        justify-content: space-evenly !important; }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; }
  .order-xxl-first {
    -ms-flex-order: -1 !important;
        order: -1 !important; }
  .order-xxl-0 {
    -ms-flex-order: 0 !important;
        order: 0 !important; }
  .order-xxl-1 {
    -ms-flex-order: 1 !important;
        order: 1 !important; }
  .order-xxl-2 {
    -ms-flex-order: 2 !important;
        order: 2 !important; }
  .order-xxl-3 {
    -ms-flex-order: 3 !important;
        order: 3 !important; }
  .order-xxl-4 {
    -ms-flex-order: 4 !important;
        order: 4 !important; }
  .order-xxl-5 {
    -ms-flex-order: 5 !important;
        order: 5 !important; }
  .order-xxl-last {
    -ms-flex-order: 6 !important;
        order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-6 {
    margin: 4.5rem !important; }
  .m-xxl-7 {
    margin: 6rem !important; }
  .m-xxl-8 {
    margin: 7.5rem !important; }
  .m-xxl-9 {
    margin: 9rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important; }
  .mx-xxl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important; }
  .my-xxl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-6 {
    margin-top: 4.5rem !important; }
  .mt-xxl-7 {
    margin-top: 6rem !important; }
  .mt-xxl-8 {
    margin-top: 7.5rem !important; }
  .mt-xxl-9 {
    margin-top: 9rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-6 {
    margin-right: 4.5rem !important; }
  .me-xxl-7 {
    margin-right: 6rem !important; }
  .me-xxl-8 {
    margin-right: 7.5rem !important; }
  .me-xxl-9 {
    margin-right: 9rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-7 {
    margin-bottom: 6rem !important; }
  .mb-xxl-8 {
    margin-bottom: 7.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 9rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-6 {
    margin-left: 4.5rem !important; }
  .ms-xxl-7 {
    margin-left: 6rem !important; }
  .ms-xxl-8 {
    margin-left: 7.5rem !important; }
  .ms-xxl-9 {
    margin-left: 9rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .m-xxl-n6 {
    margin: -4.5rem !important; }
  .m-xxl-n7 {
    margin: -6rem !important; }
  .m-xxl-n8 {
    margin: -7.5rem !important; }
  .m-xxl-n9 {
    margin: -9rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xxl-n8 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xxl-n8 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important; }
  .my-xxl-n9 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -3rem !important; }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important; }
  .mt-xxl-n7 {
    margin-top: -6rem !important; }
  .mt-xxl-n8 {
    margin-top: -7.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -9rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -3rem !important; }
  .me-xxl-n6 {
    margin-right: -4.5rem !important; }
  .me-xxl-n7 {
    margin-right: -6rem !important; }
  .me-xxl-n8 {
    margin-right: -7.5rem !important; }
  .me-xxl-n9 {
    margin-right: -9rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -7.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -9rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -3rem !important; }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important; }
  .ms-xxl-n7 {
    margin-left: -6rem !important; }
  .ms-xxl-n8 {
    margin-left: -7.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -9rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .p-xxl-6 {
    padding: 4.5rem !important; }
  .p-xxl-7 {
    padding: 6rem !important; }
  .p-xxl-8 {
    padding: 7.5rem !important; }
  .p-xxl-9 {
    padding: 9rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important; }
  .px-xxl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important; }
  .py-xxl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pt-xxl-6 {
    padding-top: 4.5rem !important; }
  .pt-xxl-7 {
    padding-top: 6rem !important; }
  .pt-xxl-8 {
    padding-top: 7.5rem !important; }
  .pt-xxl-9 {
    padding-top: 9rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pe-xxl-6 {
    padding-right: 4.5rem !important; }
  .pe-xxl-7 {
    padding-right: 6rem !important; }
  .pe-xxl-8 {
    padding-right: 7.5rem !important; }
  .pe-xxl-9 {
    padding-right: 9rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important; }
  .pb-xxl-7 {
    padding-bottom: 6rem !important; }
  .pb-xxl-8 {
    padding-bottom: 7.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 9rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .ps-xxl-6 {
    padding-left: 4.5rem !important; }
  .ps-xxl-7 {
    padding-left: 6rem !important; }
  .ps-xxl-8 {
    padding-left: 7.5rem !important; }
  .ps-xxl-9 {
    padding-left: 9rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*----------------------------------------------------------------------
    * BootNews - Magazine and Blog bootstrap template
    * Copyright 2021 Bootstrap.News
    * Licensed under Bootstrap.News (https://bootstrap.news/license/)
------------------------------------------------------------------------*/
/*------------------------------------
	General
------------------------------------*/
* {
  box-sizing: border-box;
  outline: none;
  box-sizing: border-box; }

body {
  font-family: "Roboto", sans-serif; }

.wrapper {
  font-size: 0.9375rem; }

main {
  position: relative; }

a:hover {
  text-decoration: none; }

a:focus {
  text-decoration: none; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.5rem;
  font-weight: 500; }

.heading-letter-spacing {
  letter-spacing: 1px; }

figure {
  margin-bottom: 0; }

object {
  margin-bottom: -0.3125rem; }

.register-mobile {
  text-align: center; }

.register-mobile li {
  font-weight: 700;
  display: inline; }

.cursors {
  cursor: pointer; }

pre {
  overflow: auto;
  background-color: #f8f9fa;
  padding: 4px 10px;
  border: none;
  border-radius: 0;
  margin-top: 24px; }

ol ul {
  margin-left: 1rem;
  margin-bottom: 1rem; }

.tags-list, .comment-list {
  padding-left: 0; }

:focus {
  outline: 0; }

a:focus {
  outline: 0; }

button:focus {
  outline: 0; }

.hr-after.mx-auto {
  opacity: 1;
  height: 2px; }

/*------------------------------------
	Color
------------------------------------*/
a {
  color: #4f46e5; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1b1b1b; }

h1 > a, .h1 > a, h2 > a, .h2 > a, h3 > a, .h3 > a, h4 > a, .h4 > a, h5 > a, .h5 > a, h6 > a, .h6 > a, .h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #1b1b1b;
  -webkit-font-smoothing: antialiased; }

h1 > .btn a, .h1 > .btn a, h2 > .btn a, .h2 > .btn a, h3 > .btn a, .h3 > .btn a, h4 > .btn a, .h4 > .btn a, h5 > .btn a, .h5 > .btn a, h6 > .btn a, .h6 > .btn a, .h1 > .btn a, .h2 > .btn a, .h3 > .btn a, .h4 > .btn a, .h5 > .btn a, .h6 > .btn a {
  color: #fff; }

h1 > .btn a:hover, .h1 > .btn a:hover, h2 > .btn a:hover, .h2 > .btn a:hover, h3 > .btn a:hover, .h3 > .btn a:hover, h4 > .btn a:hover, .h4 > .btn a:hover, h5 > .btn a:hover, .h5 > .btn a:hover, h6 > .btn a:hover, .h6 > .btn a:hover, .h1 > .btn a:hover, .h2 > .btn a:hover, .h3 > .btn a:hover, .h4 > .btn a:hover, .h5 > .btn a:hover, .h6 > .btn a:hover {
  color: #fff; }

p {
  color: #495057; }

/*------------------------------------
  Extra color
------------------------------------*/
.bg-blue {
  background-color: #0d6efd !important; }

.text-blue {
  color: #0d6efd !important; }

.border-blue {
  border-color: #0d6efd !important; }

.bg-purple {
  background-color: #4f46e5 !important; }

.text-purple {
  color: #4f46e5 !important; }

.border-purple {
  border-color: #4f46e5 !important; }

.bg-pink {
  background-color: #d63384 !important; }

.text-pink {
  color: #d63384 !important; }

.border-pink {
  border-color: #d63384 !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

.text-yellow {
  color: #ffc107 !important; }

.border-yellow {
  border-color: #ffc107 !important; }

.bg-black {
  background-color: #000 !important; }

.text-black {
  color: #000 !important; }

.border-black {
  border-color: #000 !important; }

.bg-themes {
  background: #fff !important; }

.bg-light-dark, .bg-light-black {
  background: #f8f9fa !important; }

.black a {
  color: #000; }

.white a {
  color: #fff; }

/*------------------------------------
	Animate.css
------------------------------------*/
.animations {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 1; }
  0% {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
    opacity: 0; } }

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1; }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0; } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@keyframes slideInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

/*------------------------------------
	Google Fonts
------------------------------------*/
.font-family h1, .font-family .h1, .font-family h2, .font-family .h2, .font-family h3, .font-family .h3, .font-family h4, .font-family .h4, .font-family h5, .font-family .h5, .font-family h6, .font-family .h6, .font-family .h1, .font-family .h2, .font-family .h3, .font-family .h4, .font-family .h5, .font-family .h6 {
  font-family: "Roboto", Helvetica, sans-serif; }

.font-family .navbar-nav, .font-family .breaking-box, .font-family .u-breadcrumb, .font-family .title-comments .authors, .font-family .prev-next-post a {
  font-family: "Roboto", Helvetica, sans-serif; }

.post-content {
  font-family: Helvetica, sans-serif; }

/*------------------------------------
	Card full
------------------------------------*/
.card-full {
  background-color: transparent !important;
  border: 0; }

.card-full .card-title:last-child {
  margin-bottom: 0; }

.card-full .card-body {
  padding: 0;
  padding-top: 1rem; }

.card-full .no-gutters .card-body {
  padding: 1.25rem; }

.card-img {
  min-height: 3rem; }

.card-list li {
  padding: 0.5rem;
  margin-bottom: 0.5rem; }

.card-list li a::before {
  content: "";
  width: .75rem;
  height: .75rem;
  margin-bottom: -1px;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-size: 1rem;
  margin-right: 0.5rem;
  margin-left: -0.5rem; }

.card-list li:hover a {
  color: #4f46e5; }

.sub-card {
  margin-top: 1rem; }

/*------------------------------------
	Main Nav
------------------------------------*/
.main-logo img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 14.875rem; }

.logo-six {
  max-height: 2.5rem; }

.mobile-logo {
  min-height: 40px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-height: 2.5rem; }

.top-menu, .footer-copyright {
  font-size: 0.875rem; }

.full-nav {
  z-index: 20; }

.main-nav .nav-link {
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
  -webkit-font-smoothing: antialiased;
  padding: 1rem; }

.navbar-uppercase > li > a {
  text-transform: uppercase;
  font-weight: 700; }

.main-nav .dropdown-menu {
  border-width: 3px 0 0;
  border-color: #4f46e5; }

.search-menu .form-src {
  position: relative;
  background: #fff; }

.search-menu input[type=text] {
  position: absolute;
  transition: width .5s ease-in-out;
  width: 30px;
  border: 1px transparent solid;
  background: transparent; }

.search-menu input[type=text]::-webkit-input-placeholder {
  color: transparent; }

.search-menu input[type=text]::-moz-placeholder {
  color: transparent; }

.search-menu input[type=text]:-ms-input-placeholder {
  color: transparent; }

.search-menu input[type=text]::-ms-input-placeholder {
  color: transparent; }

.search-menu input[type=text]::placeholder {
  color: transparent; }

.search-menu input[type=text]:focus {
  width: 200px;
  background: #fff;
  border: #dee2e6 1px solid;
  border-radius: 2rem !important; }

.search-menu input[type=text]:focus::-webkit-input-placeholder {
  color: #1b1b1b; }

.search-menu input[type=text]:focus::-moz-placeholder {
  color: #1b1b1b; }

.search-menu input[type=text]:focus:-ms-input-placeholder {
  color: #1b1b1b; }

.search-menu input[type=text]:focus::-ms-input-placeholder {
  color: #1b1b1b; }

.search-menu input[type=text]:focus::placeholder {
  color: #1b1b1b; }

.search-menu .icones {
  padding-top: .75rem;
  padding-bottom: .75rem; }

@media (min-width: 992px) {
  .dropdown-menu {
    left: auto; }
  .dropdown-menu .dropdown-menu {
    left: 100%; }
  .showbacktop .logo-showbacktop {
    display: none; }
  .showbacktop .logo-showbacktop img {
    display: none; }
  .showbacktop.is-fixed {
    /* when the user scrolls down, we hide the header right above the viewport */
    width: 100%;
    top: -54px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    background-color: rgba(255, 255, 255, 0.95) !important; }
  .showbacktop.is-fixed .logo-showbacktop img {
    display: none; }
  .showbacktop.is-visible {
    position: fixed;
    background: rgba(255, 255, 255, 0.95) !important;
    /* if the user changes the scrolling direction, we show the header */
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  .showbacktop.is-visible .logo-showbacktop {
    display: block;
    height: 2.3rem; }
  .showbacktop.is-visible .logo-showbacktop img {
    max-width: 10rem;
    max-height: 2.3rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    display: block; }
  .boxed .showbacktop.is-visible, .framed .showbacktop.is-visible {
    width: 90%; }
  .showbacktop.bg-black.is-fixed, .showbacktop.bg-secondary.is-fixed, .showbacktop.bg-dark.is-fixed {
    background: rgba(0, 0, 0, 0.95) !important;
    border-color: #495057 !important; }
  .showbacktop.bg-black.is-visible, .showbacktop.bg-secondary.is-visible, .showbacktop.bg-dark.is-visible {
    background: rgba(0, 0, 0, 0.95) !important;
    border-color: #495057;
    top: -58px; }
  .showbacktop.bg-black.is-visible.six-menu, .showbacktop.bg-secondary.is-visible.six-menu, .showbacktop.bg-dark.is-visible.six-menu {
    top: -89px; }
  .showbacktop.bg-white.is-visible.six-menu {
    top: -89px; }
  .admin-bar .showbacktop.is-fixed {
    top: -22px; }
  .admin-bar .showbacktop.is-fixed.six-menu {
    top: -37px; }
  .admin-bar .sticky-top {
    top: 32px; } }

.main-menu.navbar-dark a {
  color: #f8f9fa !important; }

.main-menu.navbar-dark a:hover {
  color: #fff !important; }

.main-menu.navbar-dark a.badge {
  color: #f8f9fa !important;
  background-color: #4f46e5; }

.main-menu.navbar-dark h1, .main-menu.navbar-dark .h1, .main-menu.navbar-dark h2, .main-menu.navbar-dark .h2, .main-menu.navbar-dark h3, .main-menu.navbar-dark .h3, .main-menu.navbar-dark h4, .main-menu.navbar-dark .h4, .main-menu.navbar-dark h5, .main-menu.navbar-dark .h5, .main-menu.navbar-dark h6, .main-menu.navbar-dark .h6, .main-menu.navbar-dark .h1, .main-menu.navbar-dark .h2, .main-menu.navbar-dark .h3, .main-menu.navbar-dark .h4, .main-menu.navbar-dark .h5, .main-menu.navbar-dark .h6 {
  color: #f8f9fa !important; }

.main-menu.navbar-dark h1 > a, .main-menu.navbar-dark .h1 > a, .main-menu.navbar-dark h2 > a, .main-menu.navbar-dark .h2 > a, .main-menu.navbar-dark h3 > a, .main-menu.navbar-dark .h3 > a, .main-menu.navbar-dark h4 > a, .main-menu.navbar-dark .h4 > a, .main-menu.navbar-dark h5 > a, .main-menu.navbar-dark .h5 > a, .main-menu.navbar-dark h6 > a, .main-menu.navbar-dark .h6 > a, .main-menu.navbar-dark .h1 > a, .main-menu.navbar-dark .h2 > a, .main-menu.navbar-dark .h3 > a, .main-menu.navbar-dark .h4 > a, .main-menu.navbar-dark .h5 > a, .main-menu.navbar-dark .h6 > a {
  color: #f8f9fa !important; }

.main-menu.navbar-dark h1:hover, .main-menu.navbar-dark .h1:hover, .main-menu.navbar-dark h2:hover, .main-menu.navbar-dark .h2:hover, .main-menu.navbar-dark h3:hover, .main-menu.navbar-dark .h3:hover, .main-menu.navbar-dark h4:hover, .main-menu.navbar-dark .h4:hover, .main-menu.navbar-dark h5:hover, .main-menu.navbar-dark .h5:hover, .main-menu.navbar-dark h6:hover, .main-menu.navbar-dark .h6:hover, .main-menu.navbar-dark .h1:hover, .main-menu.navbar-dark .h2:hover, .main-menu.navbar-dark .h3:hover, .main-menu.navbar-dark .h4:hover, .main-menu.navbar-dark .h5:hover, .main-menu.navbar-dark .h6:hover {
  color: #fff !important; }

.main-menu.navbar-dark .hover-a:hover h1 > a, .main-menu.navbar-dark .hover-a:hover .h1 > a, .main-menu.navbar-dark .hover-a:hover h2 > a, .main-menu.navbar-dark .hover-a:hover .h2 > a, .main-menu.navbar-dark .hover-a:hover h3 > a, .main-menu.navbar-dark .hover-a:hover .h3 > a, .main-menu.navbar-dark .hover-a:hover h4 > a, .main-menu.navbar-dark .hover-a:hover .h4 > a, .main-menu.navbar-dark .hover-a:hover h5 > a, .main-menu.navbar-dark .hover-a:hover .h5 > a, .main-menu.navbar-dark .hover-a:hover h6 > a, .main-menu.navbar-dark .hover-a:hover .h6 > a, .main-menu.navbar-dark .hover-a:hover .h1 > a, .main-menu.navbar-dark .hover-a:hover .h2 > a, .main-menu.navbar-dark .hover-a:hover .h3 > a, .main-menu.navbar-dark .hover-a:hover .h4 > a, .main-menu.navbar-dark .hover-a:hover .h5 > a, .main-menu.navbar-dark .hover-a:hover .h6 > a {
  color: #4f46e5 !important; }

.main-menu.navbar-dark .dropdown-menu {
  background-color: #1b1b1b; }

.main-menu.navbar-dark .dropdown-menu .dropdown-submenu {
  position: relative; }

.main-menu.navbar-dark .dropdown-menu .dropdown-submenu .dropdown-menu {
  top: -12px; }

.main-menu.navbar-dark .navbar-nav .nav-link {
  padding-left: 15px; }

.main-menu.navbar-dark .navbar-nav > .nav-item.active, .main-menu.navbar-dark .navbar-nav > .nav-item:hover {
  background: #4f46e5;
  color: #f8f9fa; }

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu {
  border-width: 4px 0 0;
  color: #f8f9fa;
  background-color: #000; }

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  color: #f8f9fa; }

.main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus, .main-menu.navbar-dark .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #4f46e5 !important;
  background: #1b1b1b; }

.main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link {
  border-color: #1b1b1b; }

.main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link.active, .main-menu.navbar-dark .navbar-nav .vertical-tabs .nav-tabs .nav-link:hover {
  border-color: #000;
  background-color: #000 !important; }

.six-menu {
  padding-top: 0px;
  padding-bottom: 0px; }

.six-menu .navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px; }

.six-menu .navbar-nav .nav-tabs .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.six-menu.is-visible .navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px; }

.six-menu.is-visible .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem; }

@media (min-width: 1200px) {
  .boxed .showbacktop.is-visible, .framed .showbacktop.is-visible {
    width: 1200px; } }

@media (min-width: 992px) {
  .main-menu.navbar-dark .main-nav > li > a.active {
    color: #fff !important;
    background-color: #4f46e5 !important; }
  .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #4f46e5 !important; }
  .main-menu.navbar-dark .hover-mode .main-nav > li > a.active {
    color: #fff !important;
    background-color: #4f46e5 !important; }
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #fff !important;
    background-color: #4f46e5 !important; }
  .navbar-nav.mx-auto li .dropdown-menu {
    left: inherit; } }

@media (max-width: 991.98px) {
  .main-nav {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .main-nav .nav-link {
    padding: .5rem; }
  .main-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1rem; }
  .six-menu {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .six-menu .main-nav .nav-link {
    padding: 1rem .5rem .5rem; }
  /*No sticky mobile*/
  .no-md-sticky {
    position: relative; }
  #wpadminbar {
    position: fixed !important; }
  .mobile-sticky.is-sticky {
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%; } }

/*------------------------------------
	Mega menu
------------------------------------*/
.navbar .dropdown-menu.mega {
  left: auto;
  right: 0; }

.navbar .dropdown-menu.mega .block-area {
  margin: 1.5rem .5rem; }

.navbar .dropdown-menu.mega.show {
  display: block; }

.mega-dropdown {
  position: static; }

.mega .title-nav {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.navbar-expand-lg .navbar-nav .mega-link > li > a.dropdown-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.mega-link .dropdown-item.active {
  background-color: #f8f9fa; }

.mega-link .dropdown-item:active {
  background-color: #f8f9fa; }

.before-caret a:before, .before-caret-dark a:before {
  content: "";
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-bottom: -2px;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-right: 0.8rem;
  font-size: 0.8rem; }

.before-caret a:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.before-caret-dark a:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.mega ul.menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-left: 0;
  list-style: none; }

.mega ul.menu > li {
  border-right: 1px solid #dee2e6; }

.mega ul.menu > li:last-child {
  border-right: none; }

.mega ul.menu > li > a {
  color: #161616;
  line-height: 2.5rem;
  font-weight: 500;
  font-size: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
  background: transparent; }

.mega ul.menu > li > a::after {
  content: none !important; }

.mega ul.menu > li:hover > a {
  color: #161616;
  background-color: transparent; }

.mega ul.menu ul {
  padding-left: 0;
  list-style: none; }

.mega ul.menu ul li a {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.mega ul.menu ul li a::after {
  content: none !important; }

/*------------------------------------
	Dropdown submenu
------------------------------------*/
.navbar-nav .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  width: .9rem;
  height: .9rem;
  background-repeat: no-repeat;
  background-size: .8rem .8rem;
  vertical-align: middle;
  margin-left: .4rem;
  border-top: inherit;
  border-right: inherit;
  border-bottom: 0;
  border-left: inherit; }

.navbar-nav .dropdown-menu {
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1); }

.navbar-nav .dropdown-menu .dropdown-submenu, .navbar-nav .dropdown-menu .menu-item-has-children {
  position: relative; }

.navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
  top: -11px;
  left: 100%;
  margin-left: 0;
  margin-right: 0; }

.navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-nav .dropdown-menu .dropdown-submenu > a::after, .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after, .navbar-nav .dropdown-menu .menu-item-has-children > a::after {
  top: .75rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-size: .7rem .7rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
  position: absolute;
  right: 1.25rem; }

.navbar-nav .dropdown-menu .dropdown-submenu .dropdown-submenu .dropdown-menu, .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-submenu .dropdown-menu {
  top: -11px; }

.navbar-nav .dropdown-item:hover {
  color: #4f46e5; }

.navbar-nav .nav-tabs .nav-link {
  color: #1b1b1b; }

.navbar-dark .navbar-nav .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-dark .navbar-nav .dropdown-menu .dropdown-submenu > a::after, .navbar-dark .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after, .navbar-dark .navbar-nav .dropdown-menu .menu-item-has-children > a::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.dropdown-menu {
  margin-top: 0; }

.mini {
  font-size: 0.6rem; }

.dropdown-reverse {
  left: auto !important;
  right: 100% !important; }

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-expand-lg .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1.5rem; }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-lg .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after {
    -webkit-transform: none;
            transform: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
    right: 0; }
  .navbar-expand-lg .navbar-nav .dropdown-toggle::after {
    float: right;
    margin-right: 0;
    margin-left: auto; }
  .navbar-expand-lg .navbar-nav .dropdown-menu.show {
    border-left-width: 0.2rem;
    border-left-style: solid;
    border-left-color: #4f46e5;
    border-top: 0; } }

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu, .navbar-expand-md .navbar-nav .dropdown-menu .menu-item-has-children .dropdown-menu {
    margin-left: 1.5rem; }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-submenu a.dropdown-toggle::after, .navbar-expand-md .navbar-nav .dropdown-menu .menu-item-has-children a.dropdown-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e");
    right: 0; }
  .navbar-expand-md .navbar-nav .dropdown-toggle::after {
    float: right;
    margin-right: 2rem;
    margin-left: auto; }
  .navbar-expand-md .navbar-nav .dropdown-menu.show {
    border-left-width: 0.2rem;
    border-left-style: solid;
    border-left-color: #4f46e5;
    border-top: 0; } }

@media (min-width: 576px) {
  .border-right-sm {
    border-right: 1px solid #dee2e6; } }

@media (min-width: 768px) {
  .navbar-expand-md .navbar-right > li:last-child > a {
    padding-right: 0; } }

@media (min-width: 992px) {
  .hover-mode .main-nav .dropdown-menu {
    transition: all .2s ease-in-out;
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    display: block;
    opacity: 0;
    visibility: hidden; }
  .hover-mode .main-nav .dropdown-menu.show {
    opacity: 0;
    visibility: hidden; }
  .hover-mode .main-nav .dropdown-menu.mega.show {
    opacity: 0;
    visibility: hidden; }
  .hover-mode .main-nav li:hover > a {
    color: #4f46e5;
    background-color: #f8f9fa; }
  .hover-mode .main-nav li:hover > .dropdown-menu {
    -webkit-transform: translateY(0rem);
    transform: translateY(0rem);
    opacity: 1;
    visibility: visible; }
  .hover-mode .main-nav li:hover > .dropdown-menu.show {
    opacity: 1;
    visibility: visible; }
  .hover-mode .main-nav li:hover > .dropdown-menu.mega.show {
    opacity: 1;
    visibility: visible; }
  .hover-mode .main-nav > li:hover > a {
    background-color: transparent; }
  .navbar-dark .hover-mode li:hover > a {
    color: #4f46e5;
    background-color: #1b1b1b; }
  .navbar-light .hover-mode .main-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9); }
  .navbar-light .hover-mode .main-nav li.show:hover > .nav-link {
    color: #4f46e5; }
  .navbar-expand-lg .navbar-right > li:last-child > a {
    padding-right: 0; }
  .navbar-expand-lg.navbar-dark .navbar-right > li:last-child > a {
    padding-right: 1rem; }
  .navbar-expand-lg .main-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem; }
  .first-left-lg-0 > li:first-child > .nav-link {
    padding-right: 1rem;
    padding-left: 0; }
  .no-first-left .first-left-lg-0 > li:first-child > .nav-link {
    padding-left: 1rem; } }

.ticker {
  width: 100%;
  margin: 10px auto; }

.ticker div {
  display: inline-block;
  word-wrap: break-word; }

.social-fly {
  top: 35%;
  padding-top: 4.5rem;
  position: fixed;
  left: 1rem; }

.social-fly ul li {
  margin-bottom: 0.5rem; }

@media (min-width: 1200px) {
  .social-fly {
    left: 2rem; } }

.navbar-light .nav-item:hover > .nav-link {
  color: #4f46e5; }

.dropright .no-caret::after {
  border: none;
  margin: 0; }

.dropleft .no-caret::after {
  border: none;
  margin: 0; }

.dropup .no-caret::after {
  border: none;
  margin: 0; }

/*------------------------------------
	Vertical tabs
------------------------------------*/
.vertical-tabs {
  background: #f8f9fa; }

.vertical-tabs .nav.nav-tabs {
  float: left;
  display: block;
  border-bottom: 0;
  margin-right: 1.5rem;
  border-right: #dee2e6 1px solid; }

.vertical-tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.vertical-tabs .tab-content > .active {
  display: block;
  min-height: 10.3125rem; }

.vertical-tabs ul li {
  margin-right: -1px; }

.navbar-nav .vertical-tabs .nav-tabs .nav-link.active {
  color: #4f46e5;
  border-color: #dee2e6 #fff #dee2e6 #fff;
  background-color: #fff; }

.navbar-dark .vertical-tabs {
  background: #1b1b1b;
  border-color: #1b1b1b; }

/*------------------------------------
	Sidebar tabs
------------------------------------*/
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #4f46e5;
  border-color: #4f46e5; }

.nav-tabs .nav-link {
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .nav-link.active:hover {
  border-color: #4f46e5; }

.nav-tabs .nav-link.active:focus {
  border-color: #4f46e5; }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dee2e6 #dee2e6 #fff; }

/*------------------------------------
	Hamburger
------------------------------------*/
.hamburger-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer; }

.hamburger-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: 0.25s ease-in-out; }

.hamburger-icon span:nth-child(1) {
  top: 0; }

.hamburger-icon span:nth-child(2) {
  top: 0.6rem; }

.hamburger-icon span:nth-child(3) {
  top: 0.6rem; }

.hamburger-icon span:nth-child(4) {
  top: 1.2rem; }

.hamburger-icon.open span:nth-child(1) {
  top: 0.5rem;
  width: 0;
  left: 50%; }

.hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.hamburger-icon.open span:nth-child(4) {
  top: 1rem;
  width: 0;
  left: 50%; }

.hamburger-dark span {
  background: #000; }

.navbar-dark .navbar-toggler {
  border: none; }

.navbar-dark .hamburger-icon span {
  background: #fff; }

.navbar-light .hamburger-icon span {
  background: #1b1b1b; }

/*------------------------------------
   Breadcrumb
------------------------------------*/
.u-breadcrumb {
  color: #6c757d;
  background: transparent;
  font-size: 90%; }

.u-breadcrumb a {
  color: #6c757d; }

.u-breadcrumb .breadcrumb-item {
  font-family: "Roboto", sans-serif; }

.u-breadcrumb .breadcrumb-item a {
  color: #6c757d; }

.u-breadcrumb .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "»"; }

.u-breadcrumb .breadcrumb-item:first-child::before {
  display: none; }

/*------------------------------------
 Lazyload images
------------------------------------*/
.image-wrapper {
  /*background color if image is loading slow*/
  background-color: #f8f9fa;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: linear-gradient(to left, #f8f9fa 0%, #ced4da 15%, #f8f9fa 40%, #f8f9fa 100%);
  background-size: 400% 700% !important;
  background-position: center center !important;
  -webkit-animation: AnimationSea 1.5s infinite linear forwards;
  animation: AnimationSea 1.5s infinite linear forwards; }

@-webkit-keyframes AnimationSea {
  from {
    background-position: 100% 0; }
  to {
    background-position: 20% 0; } }

@keyframes AnimationSea {
  from {
    background-position: 100% 0; }
  to {
    background-position: 20% 0; } }

.image-single-wrapper {
  width: 100%;
  min-height: 18rem; }

.lazy {
  width: 100%;
  transition: 500ms ease-in-out;
  opacity: 0; }

.lazy.loaded {
  opacity: 1; }

.hover-a:hover .image-wrapper {
  opacity: 0.9; }

.ajax-tabs {
  min-height: 20rem; }

.loaders {
  opacity: .7;
  font-size: 2.5rem;
  text-align: center;
  margin-top: 100px; }

/*------------------------------------
	Ratio Thumbnail images
	Tutorial calculate the ratio space example size 400x250px is 250/400*100 = 62.5%
------------------------------------*/
.ratio_category, .ratio_demo1, .ratio_demo2, .ratio_single, .ratio_203-114, .ratio_110-77, .ratio_115-80, .ratio_165-92, .ratio_180-123, .ratio_230-129, .ratio_251-141, .ratio_327-278, .ratio_376-376, .ratio_372-208, .ratio_400-250, .ratio_800-450, .ratio_763-422, .ratio_540-470, .ratio_540-460, .ratio_540-454, .ratio_540-304, .ratio_466-261, .ratio_360-202, .ratio_220-150, .ratio_230-230, .ratio_337-337, .ratio_99-68, .ratio_160-112, .ratio_640-640, .ratio_1140-535, .ratio_900-500, .ratio_950-500, .ratio_1200-900 {
  height: 0; }

.ratio_category, .ratio_demo1 {
  padding-bottom: 25%; }

.ratio_demo2 {
  padding-bottom: 55%; }

.ratio_1108-520 {
  padding-bottom: 46.9314079%; }

.ratio_1140-535 {
  padding-bottom: 46.9314079%; }

.ratio_900-500, .ratio_763-422 {
  padding-bottom: 55.3555556%; }

.ratio_466-261, .ratio_165-92, .ratio_230-129, .ratio_360-202, .ratio_203-114, .ratio_251-141, .ratio_372-208 {
  padding-bottom: 56.0085837%; }

.ratio_800-450 {
  padding-bottom: 56.25%; }

.ratio_540-304 {
  padding-bottom: 56.2962963%; }

.ratio_400-250 {
  padding-bottom: 62.5%; }

.ratio_single {
  padding-bottom: 66.6666667%; }

.ratio_220-150, .ratio_99-68, .ratio_180-123 {
  padding-bottom: 68.1818182%; }

.ratio_160-112, .ratio_115-80, .ratio_110-77 {
  padding-bottom: 69.5%; }

.ratio_1200-900 {
  padding-bottom: 75%; }

.ratio_540-454 {
  padding-bottom: 84%; }

.ratio_327-278 {
  padding-bottom: 85.012905%; }

.ratio_540-460 {
  padding-bottom: 85.1851852%; }

.ratio_540-470 {
  padding-bottom: 87.037037%; }

.ratio_640-640, .ratio_230-230, .ratio_337-337, .ratio_376-376 {
  padding-bottom: 99.999%; }

/*------------------------------------
  Boxed and Framed Layout
------------------------------------*/
.boxed .wrapper, .framed .wrapper {
  position: relative;
  z-index: 1;
  margin: 0 auto; }

.full-width {
  background-color: #fff; }

.boxed.bg-repeat .wrapper, .boxed.bg-repeat2 .wrapper, .boxed.bg-repeat3 .wrapper, .framed.bg-repeat .wrapper, .framed.bg-repeat2 .wrapper, .framed.bg-repeat3 .wrapper {
  box-shadow: 0 0 3px #dee2e6; }

.boxed {
  background-color: #f8f9fa; }

.boxed .wrapper {
  background-color: #fff; }

.framed {
  background-color: #f8f9fa; }

.framed main, .framed .second-header, .framed .bg-nav-framed {
  background-color: rgba(255, 255, 255, 0.7); }

@media (max-width: 575.98px) {
  .boxed .wrapper, .framed .wrapper {
    width: 100%; } }

@media (min-width: 768px) {
  .boxed .wrapper, .framed .wrapper {
    width: 90%; }
  .framed {
    padding: 15px 0; } }

@media (min-width: 1200px) {
  .boxed .wrapper, .framed .wrapper {
    width: 1200px; }
  .boxed .navbar, .framed .navbar {
    padding: 0.5rem 0rem; } }

.top-section .block-area {
  margin-top: .5rem;
  margin-bottom: 5rem; }

.social-top-start li {
  display: inline-block; }

.grid-six .image {
  width: 100%; }

.grid-six .caption {
  position: relative;
  width: 100%;
  top: 0;
  right: 0;
  padding: 2rem; }

.card-banner {
  margin-bottom: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background-size: cover;
  border-radius: 5px; }

.overlay-cover {
  width: 100%;
  border: #fff 1px solid; }

.overlay {
  z-index: 10;
  padding: 1.25rem;
  color: #fff;
  transition: .25s; }

.banner-shadow {
  border: #fff 1px solid;
  height: 100%;
  width: 100%; }

.card-banner:hover .overlay {
  background-color: rgba(0, 0, 0, 0.08); }

.banner-inner {
  padding: 7px 30px;
  background: #fff;
  display: inline-block;
  margin: 0; }

.banner-inner .title {
  margin-bottom: 0; }

@media (min-width: 992px) {
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a, .main-menu.navbar-dark .hover-mode .main-nav > li > a.active {
    background-color: #4f46e5 !important; }
  .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    color: #4f46e5 !important; }
  .main-menu.navbar-dark .hover-mode .main-nav > li:hover > a {
    color: #fff !important; }
  .logo-full .main-logo img {
    max-width: 25rem; }
  .search-top-end, .social-top-start {
    margin-top: 40px;
    position: absolute; } }

@media (min-width: 768px) {
  .grid-six .image {
    width: 70%; }
  .grid-six .caption {
    position: absolute;
    width: 50%;
    top: 50%;
    right: 0;
    padding: 2rem;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

@media (max-width: 400px) {
  .top-space {
    top: 20%; } }

/*------------------------------------
  Sidebar
------------------------------------*/
.start-sidebar {
  padding-right: 30px; }

.end-sidebar {
  padding-left: 30px; }

.start-sidebar {
  padding-right: 30px; }

.start-sidebar::after {
  margin-top: 5rem;
  position: relative;
  content: "";
  padding: 2rem;
  display: block; }

.end-sidebar {
  padding-left: 30px; }

.end-sidebar::after {
  margin-top: 5rem;
  position: relative;
  content: "";
  padding: 2rem;
  display: block; }

@media (min-width: 576px) {
  .start-sidebar-sm {
    padding-right: 30px; }
  .start-sidebar-sm::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; }
  .end-sidebar-sm {
    padding-left: 30px; }
  .end-sidebar-sm::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; } }

@media (min-width: 768px) {
  .start-sidebar-md {
    padding-right: 30px; }
  .start-sidebar-md::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; }
  .end-sidebar-md {
    padding-left: 30px; }
  .end-sidebar-md::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; } }

@media (min-width: 992px) {
  .start-sidebar-lg {
    padding-right: 30px; }
  .start-sidebar-lg::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; }
  .end-sidebar-lg {
    padding-left: 30px; }
  .end-sidebar-lg::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; } }

@media (min-width: 1200px) {
  .start-sidebar-xl {
    padding-right: 30px; }
  .start-sidebar-xl::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; }
  .end-sidebar-xl {
    padding-left: 30px; }
  .end-sidebar-xl::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; } }

@media (min-width: 1400px) {
  .start-sidebar-xxl {
    padding-right: 30px; }
  .start-sidebar-xxl::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; }
  .end-sidebar-xxl {
    padding-left: 30px; }
  .end-sidebar-xxl::after {
    margin-top: 5rem;
    position: relative;
    content: "";
    padding: 2rem;
    display: block; } }

.side-link li a, .sidebar-link li a {
  color: #1b1b1b; }

.side-link li a.caret-collapse, .sidebar-link li a.caret-collapse {
  color: #4f46e5; }

.side-link li a.caret-collapse.collapsed, .sidebar-link li a.caret-collapse.collapsed {
  color: #1b1b1b; }

.side-link li a.caret-collapse.collapsed:hover, .sidebar-link li a.caret-collapse.collapsed:hover {
  color: #4f46e5; }

.side-link li a:hover, .side-link li a.active, .sidebar-link li a:hover, .sidebar-link li a.active {
  background-color: transparent;
  color: #4f46e5; }

.side-link li ul li a, .sidebar-link li ul li a {
  color: #1b1b1b; }

.side-link li ul li a:hover, .sidebar-link li ul li a:hover {
  color: #4f46e5; }

.sidebar-link li a {
  display: block;
  font-size: 1rem;
  padding: 0.5rem 0; }

/*------------------------------------
  Footer
------------------------------------*/
.footer-logo {
  max-width: 80%; }

.footer-page-list li {
  display: inline;
  margin-right: 1rem; }

.copyright-text {
  margin-bottom: 0; }

.bg-footer {
  background-image: url(../img/background/bg-footer.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.bg-footer::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: rgba(27, 27, 27, 0.96); }

@media (max-width: 575.98px) {
  .footer-copyright .navbar {
    -ms-flex-flow: column;
    flex-flow: column; }
  .footer-copyright .navbar .navbar-nav {
    margin-left: inherit !important; }
  .footer-copyright .navbar .navbar-nav.footer-nav {
    width: 100%;
    -ms-flex-direction: inherit;
        flex-direction: inherit;
    margin-bottom: 1rem; }
  .footer-copyright .navbar .navbar-nav.footer-nav li a {
    width: 100%;
    padding: 15px 0;
    border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
  .footer-copyright .navbar .navbar-nav.footer-nav li a:hover {
    color: #fff; }
  .footer-copyright .navbar .navbar-nav.footer-nav-right {
    margin-bottom: 5px; }
  .footer-light .footer-copyright .navbar .navbar-nav.footer-nav li a {
    border-color: #ced4da !important; } }

.footer-dark, .layout-dark {
  color: #dee2e6; }

.footer-dark p, .footer-dark h1, .footer-dark .h1, .footer-dark h2, .footer-dark .h2, .footer-dark h3, .footer-dark .h3, .footer-dark h4, .footer-dark .h4, .footer-dark h5, .footer-dark .h5, .layout-dark p, .layout-dark h1, .layout-dark .h1, .layout-dark h2, .layout-dark .h2, .layout-dark h3, .layout-dark .h3, .layout-dark h4, .layout-dark .h4, .layout-dark h5, .layout-dark .h5 {
  color: #dee2e6; }

.footer-dark a, .layout-dark a {
  color: #dee2e6;
  border-color: #dee2e6; }

.footer-dark a:hover, .layout-dark a:hover {
  color: #4f46e5; }

.footer-dark a.btn, .layout-dark a.btn {
  color: #dee2e6;
  border-color: #dee2e6; }

.footer-dark a.btn:hover, .layout-dark a.btn:hover {
  color: #4f46e5; }

.footer-dark li a, .layout-dark li a {
  color: #dee2e6; }

.footer-dark li:hover a, .layout-dark li:hover a {
  color: #4f46e5; }

.footer-light {
  color: #6c757d; }

.footer-light p {
  color: #6c757d; }

.footer-light a {
  color: #6c757d;
  border-color: #6c757d; }

.footer-light a:hover {
  color: #000; }

.footer-light a.btn {
  color: #6c757d;
  border-color: #6c757d; }

.footer-light a.btn:hover {
  color: #4f46e5; }

.footer-light li a {
  color: #6c757d; }

.footer-light li:hover a {
  color: #000; }

.footer-light .border-smooth {
  border-color: #ced4da !important; }

/*------------------------------------
	Big Grid
------------------------------------*/
.featured-one .height-ratio {
  padding-bottom: 40.888%;
  height: 0; }

.featured-1 .height-ratio {
  padding-bottom: 84.888%;
  height: 0; }

.featured-1 .newsbox .height-ratio {
  padding-bottom: 84.777%; }

.featured-1 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-1 .newsbox > article:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem; }

.featured-1 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-1 .newsbox > article:nth-child(4) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem; }

.featured-2 .height-ratio {
  padding-bottom: 85.2%;
  height: 0; }

.featured-2 .newsbox .height-ratio {
  padding-bottom: 84.77777%; }

.featured-2 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-2 .newsbox > article:nth-child(1) .height-ratio {
  padding-bottom: 42.4%; }

.featured-2 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-2 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-3 .height-ratio {
  padding-bottom: 55.3555556%;
  height: 0; }

.featured-3 .newsbox .height-ratio {
  padding-bottom: 56.0085837%; }

.featured-3 .newsbox > article:nth-child(1) {
  padding-bottom: 0.125rem; }

.featured-3 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem; }

.featured-4 .height-ratio {
  padding-bottom: 84.55555%;
  height: 0; }

.featured-4 .newsbox .height-ratio {
  padding-bottom: 84.77777%; }

.featured-4 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-4 .newsbox > article:nth-child(2) {
  padding-top: 0;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-4 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-4 .newsbox > article:nth-child(3) .height-ratio {
  padding-bottom: 41.89%; }

.featured-5 .newsbox-start .height-ratio {
  padding-bottom: 84.77777%;
  height: 0; }

.featured-5 .newsbox-start > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-5 .newsbox-start > article:nth-child(1) .height-ratio {
  padding-bottom: 42.15%; }

.featured-5 .newsbox-start > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-5 .newsbox-start > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-5 .newsbox-end .height-ratio {
  padding-bottom: 84.777%;
  height: 0; }

.featured-5 .newsbox-end > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-5 .newsbox-end > article:nth-child(2) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem; }

.featured-5 .newsbox-end > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-5 .newsbox-end > article:nth-child(4) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem; }

.featured-6 .newsbox .height-ratio {
  padding-bottom: 99.999%;
  height: 0; }

.featured-6 .newsbox > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 0;
  padding-left: 0; }

.featured-6 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem; }

.featured-6 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-right: 0;
  padding-left: 0.125rem; }

.featured-7 .height-ratio {
  padding-bottom: 64.22%;
  height: 0; }

.featured-7 .newsbox .height-ratio {
  padding-bottom: 43%; }

.featured-7 .newsbox > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem; }

.featured-7 .newsbox > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-7 .newsbox > article:nth-child(3) {
  padding-top: 0.125rem; }

.featured-8 .newsbox-start .height-ratio {
  padding-bottom: 85%;
  height: 0; }

.featured-8 .newsbox-start > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem; }

.featured-8 .newsbox-start > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
  padding-right: 0; }

.featured-8 .newsbox-center .height-ratio {
  padding-bottom: 85.6%;
  height: 0; }

.featured-8 .newsbox-center > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0; }

.featured-8 .newsbox-end .height-ratio {
  padding-bottom: 85%;
  height: 0; }

.featured-8 .newsbox-end > article:nth-child(1) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0;
  padding-right: 0.125rem; }

.featured-8 .newsbox-end > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem;
  padding-right: 0; }

.featured-9 .newsbox-start .height-ratio, .featured-9 .newsbox-end .height-ratio {
  padding-bottom: 84.77777%;
  height: 0; }

.featured-9 .newsbox-start > article:nth-child(1), .featured-9 .newsbox-end > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-9 .newsbox-start > article:nth-child(1) .height-ratio, .featured-9 .newsbox-end > article:nth-child(1) .height-ratio {
  padding-bottom: 41.855555%; }

.featured-9 .newsbox-start > article:nth-child(2), .featured-9 .newsbox-end > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-9 .newsbox-start > article:nth-child(3), .featured-9 .newsbox-end > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-10 .newsbox-start .height-ratio {
  padding-bottom: 84.77777%;
  height: 0; }

.featured-10 .newsbox-start > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-10 .newsbox-start > article:nth-child(1) .height-ratio {
  padding-bottom: 41.855555%; }

.featured-10 .newsbox-start > article:nth-child(2) {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-10 .newsbox-start > article:nth-child(3) {
  padding-top: 0.125rem;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-10 .newsbox-end .height-ratio {
  padding-bottom: 84.77777%;
  height: 0; }

.featured-10 .newsbox-end > article:nth-child(1) {
  padding-top: 0;
  padding-bottom: 0.125rem;
  padding-right: 0.125rem; }

.featured-10 .newsbox-end > article:nth-child(2) {
  padding-top: 0;
  padding-left: 0.125rem;
  padding-bottom: 0.125rem; }

.featured-10 .newsbox-end > article:nth-child(3) {
  padding-top: 0.125rem;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.featured-10 .newsbox-end > article:nth-child(3) .height-ratio {
  padding-bottom: 41.89%; }

@media (max-width: 767.98px) {
  .featured-2 .height-ratio {
    padding-bottom: 50%; }
  .featured-2 .newsbox .height-ratio {
    padding-bottom: 84.77777%; }
  .featured-2 .newsbox > article:nth-child(1) .height-ratio {
    padding-bottom: 41.855555%; }
  .featured-4 .height-ratio {
    padding-bottom: 50%; }
  .featured-4 .newsbox .height-ratio {
    padding-bottom: 84.77777%; }
  .featured-6 .newsbox > article:nth-child(1) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .featured-3 .newsbox > article:nth-child(1) {
    padding-top: 0;
    padding-right: 0.125rem; }
  .featured-3 .newsbox > article:nth-child(2) {
    padding-top: 0;
    padding-left: 0.125rem; }
  .featured-7 .newsbox > article:nth-child(1) {
    padding-right: 0.125rem;
    padding-bottom: 0.125rem; }
  .featured-7 .newsbox > article:nth-child(2) {
    padding-top: 0;
    padding-left: 0.125rem;
    padding-bottom: 0.125rem; }
  .featured-7 .newsbox > article:nth-child(3) {
    padding-right: 0.125rem;
    padding-bottom: 0.125rem; } }

@media (min-width: 768px) {
  .featured-6 .newsbox > article:nth-child(1) {
    padding-right: .1875rem; }
  .featured-6 .newsbox > article:nth-child(2) {
    padding-left: .09375rem;
    padding-right: .09375rem; }
  .featured-6 .newsbox > article:nth-child(3) {
    padding-left: .1875rem; }
  .featured-7 .height-ratio {
    padding-bottom: 64.22%; }
  .featured-7 .newsbox .height-ratio {
    padding-bottom: 43%; }
  .featured-7 .newsbox > article:nth-child(1) {
    padding-right: 15px;
    padding-bottom: 0.125rem; }
  .featured-7 .newsbox > article:nth-child(2) {
    padding-top: 0.125rem;
    padding-right: 15px;
    padding-left: .75rem;
    padding-bottom: 0.125rem; }
  .featured-7 .newsbox > article:nth-child(3) {
    padding-top: 0.125rem;
    padding-right: 15px;
    padding-bottom: 0.125rem; }
  .featured-8 .newsbox-start > article:nth-child(2) {
    padding-left: 0;
    padding-right: 0.125rem; }
  .featured-8 .newsbox-center > article:nth-child(1) {
    padding-left: 0.125rem;
    padding-right: 0.125rem; }
  .featured-8 .newsbox-end > article:nth-child(1), .featured-8 .newsbox-end > article:nth-child(2) {
    padding-left: 0.125rem;
    padding-right: 0; } }

@media (min-width: 992px) {
  .featured-3 .newsbox > article:nth-child(1) {
    padding-top: 0;
    padding-right: 15px; }
  .featured-3 .newsbox > article:nth-child(2) {
    padding-top: 0.125rem;
    padding-left: .75rem;
    padding-right: 15px; } }

/*------------------------------------
	Block title
------------------------------------*/
.block-title-1 h1, .block-title-1 .h1, .block-title-1 h2, .block-title-1 .h2, .block-title-1 h3, .block-title-1 .h3, .block-title-1 h4, .block-title-1 .h4, .block-title-1 h5, .block-title-1 .h5, .block-title-1 h6, .block-title-1 .h6, .block-title-6 h1 span, .block-title-6 .h1 span, .block-title-6 h2 span, .block-title-6 .h2 span, .block-title-6 h3 span, .block-title-6 .h3 span, .block-title-6 h4 span, .block-title-6 .h4 span, .block-title-6 h5 span, .block-title-6 .h5 span, .block-title-6 h6 span, .block-title-6 .h6 span, .block-title-14 h1 span, .block-title-14 .h1 span, .block-title-14 h2 span, .block-title-14 .h2 span, .block-title-14 h3 span, .block-title-14 .h3 span, .block-title-14 h4 span, .block-title-14 .h4 span, .block-title-14 h5 span, .block-title-14 .h5 span, .block-title-14 h6 span, .block-title-14 .h6 span, .center-backline span, .center-backline-lg span, .six-block-title span {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.center-backline:before, .center-backline-lg:before {
  content: "";
  position: absolute;
  height: 2px;
  top: 45%;
  background-color: #4f46e5;
  left: 0;
  right: 0; }

.center-backline span, .center-backline-lg span {
  max-width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden; }

.block-title-1 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-1 h1, .block-title-1 .h1, .block-title-1 h2, .block-title-1 .h2, .block-title-1 h3, .block-title-1 .h3, .block-title-1 h4, .block-title-1 .h4, .block-title-1 h5, .block-title-1 .h5, .block-title-1 h6, .block-title-1 .h6 {
  display: inline; }

.block-title-2 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-2 h1, .block-title-2 .h1, .block-title-2 h2, .block-title-2 .h2, .block-title-2 h3, .block-title-2 .h3, .block-title-2 h4, .block-title-2 .h4, .block-title-2 h5, .block-title-2 .h5, .block-title-2 h6, .block-title-2 .h6 {
  display: inline; }

.block-title-3 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-3 h1, .block-title-3 .h1, .block-title-3 h2, .block-title-3 .h2, .block-title-3 h3, .block-title-3 .h3, .block-title-3 h4, .block-title-3 .h4, .block-title-3 h5, .block-title-3 .h5, .block-title-3 h6, .block-title-3 .h6 {
  display: inline; }

.block-title-4 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-4 h1, .block-title-4 .h1, .block-title-4 h2, .block-title-4 .h2, .block-title-4 h3, .block-title-4 .h3, .block-title-4 h4, .block-title-4 .h4, .block-title-4 h5, .block-title-4 .h5, .block-title-4 h6, .block-title-4 .h6 {
  display: inline; }

.block-title-5 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.3rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-5 h1, .block-title-5 .h1, .block-title-5 h2, .block-title-5 .h2, .block-title-5 h3, .block-title-5 .h3, .block-title-5 h4, .block-title-5 .h4, .block-title-5 h5, .block-title-5 .h5, .block-title-5 h6, .block-title-5 .h6 {
  display: inline; }

.block-title-5 h1 span, .block-title-5 .h1 span, .block-title-5 h2 span, .block-title-5 .h2 span, .block-title-5 h3 span, .block-title-5 .h3 span, .block-title-5 h4 span, .block-title-5 .h4 span, .block-title-5 h5 span, .block-title-5 .h5 span, .block-title-5 h6 span, .block-title-5 .h6 span {
  padding-bottom: 0.45rem;
  border-bottom: #dee2e6 2px solid; }

.block-title-6 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-6 h1, .block-title-6 .h1, .block-title-6 h2, .block-title-6 .h2, .block-title-6 h3, .block-title-6 .h3, .block-title-6 h4, .block-title-6 .h4, .block-title-6 h5, .block-title-6 .h5, .block-title-6 h6, .block-title-6 .h6 {
  border-bottom: #dee2e6 2px solid; }

.block-title-6 h1 span, .block-title-6 .h1 span, .block-title-6 h2 span, .block-title-6 .h2 span, .block-title-6 h3 span, .block-title-6 .h3 span, .block-title-6 h4 span, .block-title-6 .h4 span, .block-title-6 h5 span, .block-title-6 .h5 span, .block-title-6 h6 span, .block-title-6 .h6 span {
  margin-bottom: -2px;
  display: inline-block;
  position: relative; }

.block-title-7 {
  position: relative;
  margin-bottom: 0; }

.block-title-8 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-8 h1 span, .block-title-8 .h1 span, .block-title-8 h2 span, .block-title-8 .h2 span, .block-title-8 h3 span, .block-title-8 .h3 span, .block-title-8 h4 span, .block-title-8 .h4 span, .block-title-8 h5 span, .block-title-8 .h5 span, .block-title-8 h6 span, .block-title-8 .h6 span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  display: block; }

.block-title-9 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-9 h1 span, .block-title-9 .h1 span, .block-title-9 h2 span, .block-title-9 .h2 span, .block-title-9 h3 span, .block-title-9 .h3 span, .block-title-9 h4 span, .block-title-9 .h4 span, .block-title-9 h5 span, .block-title-9 .h5 span, .block-title-9 h6 span, .block-title-9 .h6 span {
  padding-left: 1rem;
  border-left: #dee2e6 5px solid;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  position: relative; }

.block-title-10 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-10 h1 span, .block-title-10 .h1 span, .block-title-10 h2 span, .block-title-10 .h2 span, .block-title-10 h3 span, .block-title-10 .h3 span, .block-title-10 h4 span, .block-title-10 .h4 span, .block-title-10 h5 span, .block-title-10 .h5 span, .block-title-10 h6 span, .block-title-10 .h6 span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  position: relative; }

.block-title-11 {
  position: relative;
  margin-bottom: 0; }

.block-title-11 h1 span, .block-title-11 .h1 span, .block-title-11 h2 span, .block-title-11 .h2 span, .block-title-11 h3 span, .block-title-11 .h3 span, .block-title-11 h4 span, .block-title-11 .h4 span, .block-title-11 h5 span, .block-title-11 .h5 span, .block-title-11 h6 span, .block-title-11 .h6 span {
  padding-left: 0; }

.block-title-12 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

.block-title-12 h1, .block-title-12 .h1, .block-title-12 h2, .block-title-12 .h2, .block-title-12 h3, .block-title-12 .h3, .block-title-12 h4, .block-title-12 .h4, .block-title-12 h5, .block-title-12 .h5, .block-title-12 h6, .block-title-12 .h6 {
  float: left;
  margin-right: 1rem; }

.block-title-13 {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

.block-title-13 h1, .block-title-13 .h1, .block-title-13 h2, .block-title-13 .h2, .block-title-13 h3, .block-title-13 .h3, .block-title-13 h4, .block-title-13 .h4, .block-title-13 h5, .block-title-13 .h5, .block-title-13 h6, .block-title-13 .h6 {
  float: left;
  margin-right: 1rem; }

.block-title-14 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-14 h1 span, .block-title-14 .h1 span, .block-title-14 h2 span, .block-title-14 .h2 span, .block-title-14 h3 span, .block-title-14 .h3 span, .block-title-14 h4 span, .block-title-14 .h4 span, .block-title-14 h5 span, .block-title-14 .h5 span, .block-title-14 h6 span, .block-title-14 .h6 span {
  display: inline-block;
  position: relative; }

.block-title-15 {
  position: relative;
  margin-bottom: 1.5rem; }

.block-title-15 h1, .block-title-15 .h1, .block-title-15 h2, .block-title-15 .h2, .block-title-15 h3, .block-title-15 .h3, .block-title-15 h4, .block-title-15 .h4, .block-title-15 h5, .block-title-15 .h5, .block-title-15 h6, .block-title-15 .h6 {
  border-bottom: #dee2e6 2px solid; }

.center-backline:before {
  margin-top: -.5px;
  margin-bottom: 0; }

.center-backline-lg:before {
  margin-top: -0.5rem; }

@media (max-width: 767.98px) {
  .center-backline:before {
    left: 5px;
    right: 5px; } }

.title-box-stripe {
  margin-top: -0.4rem; }

.stripe-line {
  background: url(../img/assets/stripe.png);
  height: 12px;
  overflow: hidden;
  margin-top: 0; }

.title-box-dot {
  margin-top: -0.4rem; }

.dot-line {
  background: url(../img/assets/dot.png);
  height: 12px;
  overflow: hidden;
  margin-top: 0; }

.title-arrow:before {
  content: '';
  width: 2.5rem;
  height: 2px;
  position: absolute;
  top: 2rem;
  background: #4f46e5; }

.u-backline:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #4f46e5;
  margin-top: 1.5rem; }

.box-title:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  bottom: -.5rem;
  left: 50%;
  border-top-color: #4f46e5;
  border-width: 0.5rem;
  border-bottom: 0;
  margin-left: -10px; }

.box-title.border-primary:before {
  border-top-color: #4f46e5; }

.box-title.border-secondary:before {
  border-top-color: #000; }

.box-title.border-success:before {
  border-top-color: #198754; }

.box-title.border-info:before {
  border-top-color: #0dcaf0; }

.box-title.border-warning:before {
  border-top-color: #ffc107; }

.box-title.border-danger:before {
  border-top-color: #dc3545; }

.box-title.border-light:before {
  border-top-color: #f8f9fa; }

.box-title.border-dark:before {
  border-top-color: #1b1b1b; }

.six-block-title span {
  margin-bottom: -2px;
  display: inline-block;
  position: relative; }

.nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7, .nav-block-more {
  position: absolute;
  top: 0;
  right: 3.2rem;
  margin: auto 0;
  z-index: 2;
  line-height: 30px;
  text-align: right;
  border: 0; }

.nav-block-link li, .nav-block-link1 li, .nav-block-link2 li, .nav-block-link3 li, .nav-block-link4 li, .nav-block-link5 li, .nav-block-link6 li, .nav-block-link7 li, .nav-block-more li {
  display: inline-block;
  margin-right: 1.5rem; }

.nav-block-link li a, .nav-block-link1 li a, .nav-block-link2 li a, .nav-block-link3 li a, .nav-block-link4 li a, .nav-block-link5 li a, .nav-block-link6 li a, .nav-block-link7 li a, .nav-block-more li a {
  color: #1b1b1b; }

.nav-block-link li a:hover, .nav-block-link1 li a:hover, .nav-block-link2 li a:hover, .nav-block-link3 li a:hover, .nav-block-link4 li a:hover, .nav-block-link5 li a:hover, .nav-block-link6 li a:hover, .nav-block-link7 li a:hover, .nav-block-more li a:hover {
  color: #4f46e5; }

.nav-block-link li a.nav-tabs .nav-item, .nav-block-link1 li a.nav-tabs .nav-item, .nav-block-link2 li a.nav-tabs .nav-item, .nav-block-link3 li a.nav-tabs .nav-item, .nav-block-link4 li a.nav-tabs .nav-item, .nav-block-link5 li a.nav-tabs .nav-item, .nav-block-link6 li a.nav-tabs .nav-item, .nav-block-link7 li a.nav-tabs .nav-item, .nav-block-more li a.nav-tabs .nav-item {
  margin-bottom: 3px; }

.nav-block-link li a.nav-link, .nav-block-link1 li a.nav-link, .nav-block-link2 li a.nav-link, .nav-block-link3 li a.nav-link, .nav-block-link4 li a.nav-link, .nav-block-link5 li a.nav-link, .nav-block-link6 li a.nav-link, .nav-block-link7 li a.nav-link, .nav-block-more li a.nav-link {
  display: inline;
  border: 0;
  color: #1b1b1b;
  background-color: transparent;
  padding: 0.5rem 0rem; }

.nav-block-link li a.nav-link.active, .nav-block-link li a.nav-link:hover, .nav-block-link1 li a.nav-link.active, .nav-block-link1 li a.nav-link:hover, .nav-block-link2 li a.nav-link.active, .nav-block-link2 li a.nav-link:hover, .nav-block-link3 li a.nav-link.active, .nav-block-link3 li a.nav-link:hover, .nav-block-link4 li a.nav-link.active, .nav-block-link4 li a.nav-link:hover, .nav-block-link5 li a.nav-link.active, .nav-block-link5 li a.nav-link:hover, .nav-block-link6 li a.nav-link.active, .nav-block-link6 li a.nav-link:hover, .nav-block-link7 li a.nav-link.active, .nav-block-link7 li a.nav-link:hover, .nav-block-more li a.nav-link.active, .nav-block-more li a.nav-link:hover {
  color: #4f46e5;
  background-color: transparent; }

.nav-block-link li.active a, .nav-block-link1 li.active a, .nav-block-link2 li.active a, .nav-block-link3 li.active a, .nav-block-link4 li.active a, .nav-block-link5 li.active a, .nav-block-link6 li.active a, .nav-block-link7 li.active a, .nav-block-more li.active a {
  color: #4f46e5; }

.nav-block-more li {
  margin-right: 1rem; }

.nav-block-more {
  right: 0; }

@media (max-width: 575.98px) {
  .nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7 {
    width: 50%; } }

@media (max-width: 767.98px) {
  .nav-block-link, .nav-block-link1, .nav-block-link2, .nav-block-link3, .nav-block-link4, .nav-block-link5, .nav-block-link6, .nav-block-link7 {
    overflow: hidden;
    width: 60%;
    height: 2rem; }
  .nav-block-more {
    width: inherit; } }

/*------------------------------------
  News Block
------------------------------------*/
.block-area, .widget {
  position: relative;
  clear: both;
  margin-bottom: 1.5rem; }

.block-area .btn {
  margin-bottom: 0.5rem; }

.sticky {
  margin-bottom: 1.5rem; }

.first-pt-0 article:first-child {
  padding-top: 0 !important; }

.block-primary a {
  color: #4f46e5; }

.block-primary a:hover {
  color: #4f46e5 !important; }

.block-primary a.badge {
  color: #fff !important;
  background-color: #4f46e5; }

.block-primary a.btn:hover {
  color: #fff !important; }

.block-primary h1 > a, .block-primary .h1 > a, .block-primary h2 > a, .block-primary .h2 > a, .block-primary h3 > a, .block-primary .h3 > a, .block-primary h4 > a, .block-primary .h4 > a, .block-primary h5 > a, .block-primary .h5 > a, .block-primary h6 > a, .block-primary .h6 > a, .block-primary .h1 > a, .block-primary .h2 > a, .block-primary .h3 > a, .block-primary .h4 > a, .block-primary .h5 > a, .block-primary .h6 > a {
  color: #1b1b1b; }

.block-primary h1 > a:hover, .block-primary .h1 > a:hover, .block-primary h2 > a:hover, .block-primary .h2 > a:hover, .block-primary h3 > a:hover, .block-primary .h3 > a:hover, .block-primary h4 > a:hover, .block-primary .h4 > a:hover, .block-primary h5 > a:hover, .block-primary .h5 > a:hover, .block-primary h6 > a:hover, .block-primary .h6 > a:hover, .block-primary .h1 > a:hover, .block-primary .h2 > a:hover, .block-primary .h3 > a:hover, .block-primary .h4 > a:hover, .block-primary .h5 > a:hover, .block-primary .h6 > a:hover {
  color: #4f46e5 !important; }

.block-primary .hover-a:hover h1 > a, .block-primary .hover-a:hover .h1 > a, .block-primary .hover-a:hover h2 > a, .block-primary .hover-a:hover .h2 > a, .block-primary .hover-a:hover h3 > a, .block-primary .hover-a:hover .h3 > a, .block-primary .hover-a:hover h4 > a, .block-primary .hover-a:hover .h4 > a, .block-primary .hover-a:hover h5 > a, .block-primary .hover-a:hover .h5 > a, .block-primary .hover-a:hover h6 > a, .block-primary .hover-a:hover .h6 > a, .block-primary .hover-a:hover .h1 > a, .block-primary .hover-a:hover .h2 > a, .block-primary .hover-a:hover .h3 > a, .block-primary .hover-a:hover .h4 > a, .block-primary .hover-a:hover .h5 > a, .block-primary .hover-a:hover .h6 > a {
  color: #4f46e5 !important; }

.block-primary .hover-a:hover h1 > a.text-white, .block-primary .hover-a:hover .h1 > a.text-white, .block-primary .hover-a:hover h2 > a.text-white, .block-primary .hover-a:hover .h2 > a.text-white, .block-primary .hover-a:hover h3 > a.text-white, .block-primary .hover-a:hover .h3 > a.text-white, .block-primary .hover-a:hover h4 > a.text-white, .block-primary .hover-a:hover .h4 > a.text-white, .block-primary .hover-a:hover h5 > a.text-white, .block-primary .hover-a:hover .h5 > a.text-white, .block-primary .hover-a:hover h6 > a.text-white, .block-primary .hover-a:hover .h6 > a.text-white, .block-primary .hover-a:hover .h1 > a.text-white, .block-primary .hover-a:hover .h2 > a.text-white, .block-primary .hover-a:hover .h3 > a.text-white, .block-primary .hover-a:hover .h4 > a.text-white, .block-primary .hover-a:hover .h5 > a.text-white, .block-primary .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-primary .sub-card ul li a {
  color: #1b1b1b !important; }

.block-primary .sub-card ul li a:hover {
  color: #4f46e5 !important; }

.block-secondary a {
  color: #000; }

.block-secondary a:hover {
  color: #000 !important; }

.block-secondary a.badge {
  color: #fff !important;
  background-color: #000; }

.block-secondary a.btn:hover {
  color: #fff !important; }

.block-secondary h1 > a, .block-secondary .h1 > a, .block-secondary h2 > a, .block-secondary .h2 > a, .block-secondary h3 > a, .block-secondary .h3 > a, .block-secondary h4 > a, .block-secondary .h4 > a, .block-secondary h5 > a, .block-secondary .h5 > a, .block-secondary h6 > a, .block-secondary .h6 > a, .block-secondary .h1 > a, .block-secondary .h2 > a, .block-secondary .h3 > a, .block-secondary .h4 > a, .block-secondary .h5 > a, .block-secondary .h6 > a {
  color: #1b1b1b; }

.block-secondary h1 > a:hover, .block-secondary .h1 > a:hover, .block-secondary h2 > a:hover, .block-secondary .h2 > a:hover, .block-secondary h3 > a:hover, .block-secondary .h3 > a:hover, .block-secondary h4 > a:hover, .block-secondary .h4 > a:hover, .block-secondary h5 > a:hover, .block-secondary .h5 > a:hover, .block-secondary h6 > a:hover, .block-secondary .h6 > a:hover, .block-secondary .h1 > a:hover, .block-secondary .h2 > a:hover, .block-secondary .h3 > a:hover, .block-secondary .h4 > a:hover, .block-secondary .h5 > a:hover, .block-secondary .h6 > a:hover {
  color: #000 !important; }

.block-secondary .hover-a:hover h1 > a, .block-secondary .hover-a:hover .h1 > a, .block-secondary .hover-a:hover h2 > a, .block-secondary .hover-a:hover .h2 > a, .block-secondary .hover-a:hover h3 > a, .block-secondary .hover-a:hover .h3 > a, .block-secondary .hover-a:hover h4 > a, .block-secondary .hover-a:hover .h4 > a, .block-secondary .hover-a:hover h5 > a, .block-secondary .hover-a:hover .h5 > a, .block-secondary .hover-a:hover h6 > a, .block-secondary .hover-a:hover .h6 > a, .block-secondary .hover-a:hover .h1 > a, .block-secondary .hover-a:hover .h2 > a, .block-secondary .hover-a:hover .h3 > a, .block-secondary .hover-a:hover .h4 > a, .block-secondary .hover-a:hover .h5 > a, .block-secondary .hover-a:hover .h6 > a {
  color: #000 !important; }

.block-secondary .hover-a:hover h1 > a.text-white, .block-secondary .hover-a:hover .h1 > a.text-white, .block-secondary .hover-a:hover h2 > a.text-white, .block-secondary .hover-a:hover .h2 > a.text-white, .block-secondary .hover-a:hover h3 > a.text-white, .block-secondary .hover-a:hover .h3 > a.text-white, .block-secondary .hover-a:hover h4 > a.text-white, .block-secondary .hover-a:hover .h4 > a.text-white, .block-secondary .hover-a:hover h5 > a.text-white, .block-secondary .hover-a:hover .h5 > a.text-white, .block-secondary .hover-a:hover h6 > a.text-white, .block-secondary .hover-a:hover .h6 > a.text-white, .block-secondary .hover-a:hover .h1 > a.text-white, .block-secondary .hover-a:hover .h2 > a.text-white, .block-secondary .hover-a:hover .h3 > a.text-white, .block-secondary .hover-a:hover .h4 > a.text-white, .block-secondary .hover-a:hover .h5 > a.text-white, .block-secondary .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-secondary .sub-card ul li a {
  color: #1b1b1b !important; }

.block-secondary .sub-card ul li a:hover {
  color: #000 !important; }

.block-success a {
  color: #198754; }

.block-success a:hover {
  color: #198754 !important; }

.block-success a.badge {
  color: #fff !important;
  background-color: #198754; }

.block-success a.btn:hover {
  color: #fff !important; }

.block-success h1 > a, .block-success .h1 > a, .block-success h2 > a, .block-success .h2 > a, .block-success h3 > a, .block-success .h3 > a, .block-success h4 > a, .block-success .h4 > a, .block-success h5 > a, .block-success .h5 > a, .block-success h6 > a, .block-success .h6 > a, .block-success .h1 > a, .block-success .h2 > a, .block-success .h3 > a, .block-success .h4 > a, .block-success .h5 > a, .block-success .h6 > a {
  color: #1b1b1b; }

.block-success h1 > a:hover, .block-success .h1 > a:hover, .block-success h2 > a:hover, .block-success .h2 > a:hover, .block-success h3 > a:hover, .block-success .h3 > a:hover, .block-success h4 > a:hover, .block-success .h4 > a:hover, .block-success h5 > a:hover, .block-success .h5 > a:hover, .block-success h6 > a:hover, .block-success .h6 > a:hover, .block-success .h1 > a:hover, .block-success .h2 > a:hover, .block-success .h3 > a:hover, .block-success .h4 > a:hover, .block-success .h5 > a:hover, .block-success .h6 > a:hover {
  color: #198754 !important; }

.block-success .hover-a:hover h1 > a, .block-success .hover-a:hover .h1 > a, .block-success .hover-a:hover h2 > a, .block-success .hover-a:hover .h2 > a, .block-success .hover-a:hover h3 > a, .block-success .hover-a:hover .h3 > a, .block-success .hover-a:hover h4 > a, .block-success .hover-a:hover .h4 > a, .block-success .hover-a:hover h5 > a, .block-success .hover-a:hover .h5 > a, .block-success .hover-a:hover h6 > a, .block-success .hover-a:hover .h6 > a, .block-success .hover-a:hover .h1 > a, .block-success .hover-a:hover .h2 > a, .block-success .hover-a:hover .h3 > a, .block-success .hover-a:hover .h4 > a, .block-success .hover-a:hover .h5 > a, .block-success .hover-a:hover .h6 > a {
  color: #198754 !important; }

.block-success .hover-a:hover h1 > a.text-white, .block-success .hover-a:hover .h1 > a.text-white, .block-success .hover-a:hover h2 > a.text-white, .block-success .hover-a:hover .h2 > a.text-white, .block-success .hover-a:hover h3 > a.text-white, .block-success .hover-a:hover .h3 > a.text-white, .block-success .hover-a:hover h4 > a.text-white, .block-success .hover-a:hover .h4 > a.text-white, .block-success .hover-a:hover h5 > a.text-white, .block-success .hover-a:hover .h5 > a.text-white, .block-success .hover-a:hover h6 > a.text-white, .block-success .hover-a:hover .h6 > a.text-white, .block-success .hover-a:hover .h1 > a.text-white, .block-success .hover-a:hover .h2 > a.text-white, .block-success .hover-a:hover .h3 > a.text-white, .block-success .hover-a:hover .h4 > a.text-white, .block-success .hover-a:hover .h5 > a.text-white, .block-success .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-success .sub-card ul li a {
  color: #1b1b1b !important; }

.block-success .sub-card ul li a:hover {
  color: #198754 !important; }

.block-info a {
  color: #0dcaf0; }

.block-info a:hover {
  color: #0dcaf0 !important; }

.block-info a.badge {
  color: #fff !important;
  background-color: #0dcaf0; }

.block-info a.btn:hover {
  color: #fff !important; }

.block-info h1 > a, .block-info .h1 > a, .block-info h2 > a, .block-info .h2 > a, .block-info h3 > a, .block-info .h3 > a, .block-info h4 > a, .block-info .h4 > a, .block-info h5 > a, .block-info .h5 > a, .block-info h6 > a, .block-info .h6 > a, .block-info .h1 > a, .block-info .h2 > a, .block-info .h3 > a, .block-info .h4 > a, .block-info .h5 > a, .block-info .h6 > a {
  color: #1b1b1b; }

.block-info h1 > a:hover, .block-info .h1 > a:hover, .block-info h2 > a:hover, .block-info .h2 > a:hover, .block-info h3 > a:hover, .block-info .h3 > a:hover, .block-info h4 > a:hover, .block-info .h4 > a:hover, .block-info h5 > a:hover, .block-info .h5 > a:hover, .block-info h6 > a:hover, .block-info .h6 > a:hover, .block-info .h1 > a:hover, .block-info .h2 > a:hover, .block-info .h3 > a:hover, .block-info .h4 > a:hover, .block-info .h5 > a:hover, .block-info .h6 > a:hover {
  color: #0dcaf0 !important; }

.block-info .hover-a:hover h1 > a, .block-info .hover-a:hover .h1 > a, .block-info .hover-a:hover h2 > a, .block-info .hover-a:hover .h2 > a, .block-info .hover-a:hover h3 > a, .block-info .hover-a:hover .h3 > a, .block-info .hover-a:hover h4 > a, .block-info .hover-a:hover .h4 > a, .block-info .hover-a:hover h5 > a, .block-info .hover-a:hover .h5 > a, .block-info .hover-a:hover h6 > a, .block-info .hover-a:hover .h6 > a, .block-info .hover-a:hover .h1 > a, .block-info .hover-a:hover .h2 > a, .block-info .hover-a:hover .h3 > a, .block-info .hover-a:hover .h4 > a, .block-info .hover-a:hover .h5 > a, .block-info .hover-a:hover .h6 > a {
  color: #0dcaf0 !important; }

.block-info .hover-a:hover h1 > a.text-white, .block-info .hover-a:hover .h1 > a.text-white, .block-info .hover-a:hover h2 > a.text-white, .block-info .hover-a:hover .h2 > a.text-white, .block-info .hover-a:hover h3 > a.text-white, .block-info .hover-a:hover .h3 > a.text-white, .block-info .hover-a:hover h4 > a.text-white, .block-info .hover-a:hover .h4 > a.text-white, .block-info .hover-a:hover h5 > a.text-white, .block-info .hover-a:hover .h5 > a.text-white, .block-info .hover-a:hover h6 > a.text-white, .block-info .hover-a:hover .h6 > a.text-white, .block-info .hover-a:hover .h1 > a.text-white, .block-info .hover-a:hover .h2 > a.text-white, .block-info .hover-a:hover .h3 > a.text-white, .block-info .hover-a:hover .h4 > a.text-white, .block-info .hover-a:hover .h5 > a.text-white, .block-info .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-info .sub-card ul li a {
  color: #1b1b1b !important; }

.block-info .sub-card ul li a:hover {
  color: #0dcaf0 !important; }

.block-warning a {
  color: #ffc107; }

.block-warning a:hover {
  color: #ffc107 !important; }

.block-warning a.badge {
  color: #fff !important;
  background-color: #ffc107; }

.block-warning a.btn:hover {
  color: #fff !important; }

.block-warning h1 > a, .block-warning .h1 > a, .block-warning h2 > a, .block-warning .h2 > a, .block-warning h3 > a, .block-warning .h3 > a, .block-warning h4 > a, .block-warning .h4 > a, .block-warning h5 > a, .block-warning .h5 > a, .block-warning h6 > a, .block-warning .h6 > a, .block-warning .h1 > a, .block-warning .h2 > a, .block-warning .h3 > a, .block-warning .h4 > a, .block-warning .h5 > a, .block-warning .h6 > a {
  color: #1b1b1b; }

.block-warning h1 > a:hover, .block-warning .h1 > a:hover, .block-warning h2 > a:hover, .block-warning .h2 > a:hover, .block-warning h3 > a:hover, .block-warning .h3 > a:hover, .block-warning h4 > a:hover, .block-warning .h4 > a:hover, .block-warning h5 > a:hover, .block-warning .h5 > a:hover, .block-warning h6 > a:hover, .block-warning .h6 > a:hover, .block-warning .h1 > a:hover, .block-warning .h2 > a:hover, .block-warning .h3 > a:hover, .block-warning .h4 > a:hover, .block-warning .h5 > a:hover, .block-warning .h6 > a:hover {
  color: #ffc107 !important; }

.block-warning .hover-a:hover h1 > a, .block-warning .hover-a:hover .h1 > a, .block-warning .hover-a:hover h2 > a, .block-warning .hover-a:hover .h2 > a, .block-warning .hover-a:hover h3 > a, .block-warning .hover-a:hover .h3 > a, .block-warning .hover-a:hover h4 > a, .block-warning .hover-a:hover .h4 > a, .block-warning .hover-a:hover h5 > a, .block-warning .hover-a:hover .h5 > a, .block-warning .hover-a:hover h6 > a, .block-warning .hover-a:hover .h6 > a, .block-warning .hover-a:hover .h1 > a, .block-warning .hover-a:hover .h2 > a, .block-warning .hover-a:hover .h3 > a, .block-warning .hover-a:hover .h4 > a, .block-warning .hover-a:hover .h5 > a, .block-warning .hover-a:hover .h6 > a {
  color: #ffc107 !important; }

.block-warning .hover-a:hover h1 > a.text-white, .block-warning .hover-a:hover .h1 > a.text-white, .block-warning .hover-a:hover h2 > a.text-white, .block-warning .hover-a:hover .h2 > a.text-white, .block-warning .hover-a:hover h3 > a.text-white, .block-warning .hover-a:hover .h3 > a.text-white, .block-warning .hover-a:hover h4 > a.text-white, .block-warning .hover-a:hover .h4 > a.text-white, .block-warning .hover-a:hover h5 > a.text-white, .block-warning .hover-a:hover .h5 > a.text-white, .block-warning .hover-a:hover h6 > a.text-white, .block-warning .hover-a:hover .h6 > a.text-white, .block-warning .hover-a:hover .h1 > a.text-white, .block-warning .hover-a:hover .h2 > a.text-white, .block-warning .hover-a:hover .h3 > a.text-white, .block-warning .hover-a:hover .h4 > a.text-white, .block-warning .hover-a:hover .h5 > a.text-white, .block-warning .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-warning .sub-card ul li a {
  color: #1b1b1b !important; }

.block-warning .sub-card ul li a:hover {
  color: #ffc107 !important; }

.block-danger a {
  color: #dc3545; }

.block-danger a:hover {
  color: #dc3545 !important; }

.block-danger a.badge {
  color: #fff !important;
  background-color: #dc3545; }

.block-danger a.btn:hover {
  color: #fff !important; }

.block-danger h1 > a, .block-danger .h1 > a, .block-danger h2 > a, .block-danger .h2 > a, .block-danger h3 > a, .block-danger .h3 > a, .block-danger h4 > a, .block-danger .h4 > a, .block-danger h5 > a, .block-danger .h5 > a, .block-danger h6 > a, .block-danger .h6 > a, .block-danger .h1 > a, .block-danger .h2 > a, .block-danger .h3 > a, .block-danger .h4 > a, .block-danger .h5 > a, .block-danger .h6 > a {
  color: #1b1b1b; }

.block-danger h1 > a:hover, .block-danger .h1 > a:hover, .block-danger h2 > a:hover, .block-danger .h2 > a:hover, .block-danger h3 > a:hover, .block-danger .h3 > a:hover, .block-danger h4 > a:hover, .block-danger .h4 > a:hover, .block-danger h5 > a:hover, .block-danger .h5 > a:hover, .block-danger h6 > a:hover, .block-danger .h6 > a:hover, .block-danger .h1 > a:hover, .block-danger .h2 > a:hover, .block-danger .h3 > a:hover, .block-danger .h4 > a:hover, .block-danger .h5 > a:hover, .block-danger .h6 > a:hover {
  color: #dc3545 !important; }

.block-danger .hover-a:hover h1 > a, .block-danger .hover-a:hover .h1 > a, .block-danger .hover-a:hover h2 > a, .block-danger .hover-a:hover .h2 > a, .block-danger .hover-a:hover h3 > a, .block-danger .hover-a:hover .h3 > a, .block-danger .hover-a:hover h4 > a, .block-danger .hover-a:hover .h4 > a, .block-danger .hover-a:hover h5 > a, .block-danger .hover-a:hover .h5 > a, .block-danger .hover-a:hover h6 > a, .block-danger .hover-a:hover .h6 > a, .block-danger .hover-a:hover .h1 > a, .block-danger .hover-a:hover .h2 > a, .block-danger .hover-a:hover .h3 > a, .block-danger .hover-a:hover .h4 > a, .block-danger .hover-a:hover .h5 > a, .block-danger .hover-a:hover .h6 > a {
  color: #dc3545 !important; }

.block-danger .hover-a:hover h1 > a.text-white, .block-danger .hover-a:hover .h1 > a.text-white, .block-danger .hover-a:hover h2 > a.text-white, .block-danger .hover-a:hover .h2 > a.text-white, .block-danger .hover-a:hover h3 > a.text-white, .block-danger .hover-a:hover .h3 > a.text-white, .block-danger .hover-a:hover h4 > a.text-white, .block-danger .hover-a:hover .h4 > a.text-white, .block-danger .hover-a:hover h5 > a.text-white, .block-danger .hover-a:hover .h5 > a.text-white, .block-danger .hover-a:hover h6 > a.text-white, .block-danger .hover-a:hover .h6 > a.text-white, .block-danger .hover-a:hover .h1 > a.text-white, .block-danger .hover-a:hover .h2 > a.text-white, .block-danger .hover-a:hover .h3 > a.text-white, .block-danger .hover-a:hover .h4 > a.text-white, .block-danger .hover-a:hover .h5 > a.text-white, .block-danger .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-danger .sub-card ul li a {
  color: #1b1b1b !important; }

.block-danger .sub-card ul li a:hover {
  color: #dc3545 !important; }

.block-light a {
  color: #f8f9fa; }

.block-light a:hover {
  color: #f8f9fa !important; }

.block-light a.badge {
  color: #fff !important;
  background-color: #f8f9fa; }

.block-light a.btn:hover {
  color: #fff !important; }

.block-light h1 > a, .block-light .h1 > a, .block-light h2 > a, .block-light .h2 > a, .block-light h3 > a, .block-light .h3 > a, .block-light h4 > a, .block-light .h4 > a, .block-light h5 > a, .block-light .h5 > a, .block-light h6 > a, .block-light .h6 > a, .block-light .h1 > a, .block-light .h2 > a, .block-light .h3 > a, .block-light .h4 > a, .block-light .h5 > a, .block-light .h6 > a {
  color: #1b1b1b; }

.block-light h1 > a:hover, .block-light .h1 > a:hover, .block-light h2 > a:hover, .block-light .h2 > a:hover, .block-light h3 > a:hover, .block-light .h3 > a:hover, .block-light h4 > a:hover, .block-light .h4 > a:hover, .block-light h5 > a:hover, .block-light .h5 > a:hover, .block-light h6 > a:hover, .block-light .h6 > a:hover, .block-light .h1 > a:hover, .block-light .h2 > a:hover, .block-light .h3 > a:hover, .block-light .h4 > a:hover, .block-light .h5 > a:hover, .block-light .h6 > a:hover {
  color: #f8f9fa !important; }

.block-light .hover-a:hover h1 > a, .block-light .hover-a:hover .h1 > a, .block-light .hover-a:hover h2 > a, .block-light .hover-a:hover .h2 > a, .block-light .hover-a:hover h3 > a, .block-light .hover-a:hover .h3 > a, .block-light .hover-a:hover h4 > a, .block-light .hover-a:hover .h4 > a, .block-light .hover-a:hover h5 > a, .block-light .hover-a:hover .h5 > a, .block-light .hover-a:hover h6 > a, .block-light .hover-a:hover .h6 > a, .block-light .hover-a:hover .h1 > a, .block-light .hover-a:hover .h2 > a, .block-light .hover-a:hover .h3 > a, .block-light .hover-a:hover .h4 > a, .block-light .hover-a:hover .h5 > a, .block-light .hover-a:hover .h6 > a {
  color: #f8f9fa !important; }

.block-light .hover-a:hover h1 > a.text-white, .block-light .hover-a:hover .h1 > a.text-white, .block-light .hover-a:hover h2 > a.text-white, .block-light .hover-a:hover .h2 > a.text-white, .block-light .hover-a:hover h3 > a.text-white, .block-light .hover-a:hover .h3 > a.text-white, .block-light .hover-a:hover h4 > a.text-white, .block-light .hover-a:hover .h4 > a.text-white, .block-light .hover-a:hover h5 > a.text-white, .block-light .hover-a:hover .h5 > a.text-white, .block-light .hover-a:hover h6 > a.text-white, .block-light .hover-a:hover .h6 > a.text-white, .block-light .hover-a:hover .h1 > a.text-white, .block-light .hover-a:hover .h2 > a.text-white, .block-light .hover-a:hover .h3 > a.text-white, .block-light .hover-a:hover .h4 > a.text-white, .block-light .hover-a:hover .h5 > a.text-white, .block-light .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-light .sub-card ul li a {
  color: #1b1b1b !important; }

.block-light .sub-card ul li a:hover {
  color: #f8f9fa !important; }

.block-dark a {
  color: #1b1b1b; }

.block-dark a:hover {
  color: #1b1b1b !important; }

.block-dark a.badge {
  color: #fff !important;
  background-color: #1b1b1b; }

.block-dark a.btn:hover {
  color: #fff !important; }

.block-dark h1 > a, .block-dark .h1 > a, .block-dark h2 > a, .block-dark .h2 > a, .block-dark h3 > a, .block-dark .h3 > a, .block-dark h4 > a, .block-dark .h4 > a, .block-dark h5 > a, .block-dark .h5 > a, .block-dark h6 > a, .block-dark .h6 > a, .block-dark .h1 > a, .block-dark .h2 > a, .block-dark .h3 > a, .block-dark .h4 > a, .block-dark .h5 > a, .block-dark .h6 > a {
  color: #1b1b1b; }

.block-dark h1 > a:hover, .block-dark .h1 > a:hover, .block-dark h2 > a:hover, .block-dark .h2 > a:hover, .block-dark h3 > a:hover, .block-dark .h3 > a:hover, .block-dark h4 > a:hover, .block-dark .h4 > a:hover, .block-dark h5 > a:hover, .block-dark .h5 > a:hover, .block-dark h6 > a:hover, .block-dark .h6 > a:hover, .block-dark .h1 > a:hover, .block-dark .h2 > a:hover, .block-dark .h3 > a:hover, .block-dark .h4 > a:hover, .block-dark .h5 > a:hover, .block-dark .h6 > a:hover {
  color: #1b1b1b !important; }

.block-dark .hover-a:hover h1 > a, .block-dark .hover-a:hover .h1 > a, .block-dark .hover-a:hover h2 > a, .block-dark .hover-a:hover .h2 > a, .block-dark .hover-a:hover h3 > a, .block-dark .hover-a:hover .h3 > a, .block-dark .hover-a:hover h4 > a, .block-dark .hover-a:hover .h4 > a, .block-dark .hover-a:hover h5 > a, .block-dark .hover-a:hover .h5 > a, .block-dark .hover-a:hover h6 > a, .block-dark .hover-a:hover .h6 > a, .block-dark .hover-a:hover .h1 > a, .block-dark .hover-a:hover .h2 > a, .block-dark .hover-a:hover .h3 > a, .block-dark .hover-a:hover .h4 > a, .block-dark .hover-a:hover .h5 > a, .block-dark .hover-a:hover .h6 > a {
  color: #1b1b1b !important; }

.block-dark .hover-a:hover h1 > a.text-white, .block-dark .hover-a:hover .h1 > a.text-white, .block-dark .hover-a:hover h2 > a.text-white, .block-dark .hover-a:hover .h2 > a.text-white, .block-dark .hover-a:hover h3 > a.text-white, .block-dark .hover-a:hover .h3 > a.text-white, .block-dark .hover-a:hover h4 > a.text-white, .block-dark .hover-a:hover .h4 > a.text-white, .block-dark .hover-a:hover h5 > a.text-white, .block-dark .hover-a:hover .h5 > a.text-white, .block-dark .hover-a:hover h6 > a.text-white, .block-dark .hover-a:hover .h6 > a.text-white, .block-dark .hover-a:hover .h1 > a.text-white, .block-dark .hover-a:hover .h2 > a.text-white, .block-dark .hover-a:hover .h3 > a.text-white, .block-dark .hover-a:hover .h4 > a.text-white, .block-dark .hover-a:hover .h5 > a.text-white, .block-dark .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.block-dark .sub-card ul li a {
  color: #1b1b1b !important; }

.block-dark .sub-card ul li a:hover {
  color: #1b1b1b !important; }

/*------------------------------------
	Breaking news
------------------------------------*/
.navigation-box {
  top: 0.8rem;
  right: 0.8rem;
  width: 2rem;
  position: absolute; }

.aribudin {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  text-overflow: ellipsis; }

.page-link.disabled {
  cursor: not-allowed; }

@media (max-width: 575.98px) {
  .breaking-box .carousel-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; } }

@media (min-width: 768px) {
  .breaking-box .carousel-item {
    line-height: 2.25rem; } }

/*------------------------------------
	Back to top
------------------------------------*/
.back-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s 0.3s; }

.back-top.backtop-is-visible {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  z-index: 99;
  visibility: visible;
  opacity: 1; }

.back-top.backtop-fade-out {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  opacity: 0.5; }

.no-touch .back-top:hover {
  transition: opacity 0.3s 0s, visibility 0s 0s;
  background-color: #4f46e5;
  opacity: 1; }

/*------------------------------------
 Mobile menu
------------------------------------*/
.sidenav-body-open {
  position: fixed;
  width: 100%;
  z-index: 1449; }

.sidenav-body-open .back-menu-start {
  right: 0;
  left: 17.5rem; }

.sidenav-body-open .back-menu-start span {
  display: block; }

.sidenav-body-open .back-menu-end {
  left: 0;
  right: 17.5rem; }

.sidenav-body-open .back-menu-end span {
  float: right;
  display: block;
  margin-left: auto; }

.sidenav-body-open:before {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1410;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1400; }

.sidenav-body-open .back-menu {
  position: fixed;
  visibility: visible;
  opacity: 1;
  text-decoration: none;
  top: 0;
  z-index: 1451;
  color: #fff;
  padding: 1rem;
  height: 100%;
  cursor: pointer; }

.side-link .nav-link {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-bottom: #dee2e6 1px solid; }

.side-link .dropdown-item {
  border-bottom: #dee2e6 1px solid; }

.menu-mobile {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 0;
  height: 100%;
  overflow: auto;
  z-index: 1452; }

.menu-mobile a {
  display: block;
  background: transparent;
  cursor: pointer;
  border: 0;
  font-size: 0.875rem;
  font-family: "Roboto";
  letter-spacing: 1px;
  padding: 0.75rem 1rem;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  z-index: 2000; }

.menu-mobile .mobile-copyright a {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: inherit; }

.push {
  transition: all 0.5s ease; }

.push-start {
  width: 17.5rem;
  left: -17.5rem; }

.push-start.push-open {
  left: 0; }

.push-end {
  width: 17.5rem;
  right: -17.5rem; }

.push-end.push-open {
  right: 0; }

.back-menu {
  opacity: 0;
  visibility: hidden; }

.back-menu span {
  display: none; }

.side-sign {
  text-align: center; }

.side-sign a {
  display: inline; }

.social-icons {
  margin-top: 1rem;
  text-align: center; }

.social-icons a {
  display: inline;
  max-width: 20%; }

.sidenav-menu .navbar-header, .sidenav-menu .navbar-nav, .sidenav-menu .navbar-nav > li {
  float: none; }

.sidenav-menu {
  margin-bottom: 1rem; }

.sidenav-menu a:after {
  font-size: inherit;
  text-rendering: auto;
  float: right;
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
  transition: all linear 0.25s; }

.sidenav-menu .navbar {
  padding: 0; }

.sidenav-menu .navbar-nav {
  width: 100%; }

.sidenav-menu .navbar-nav .dropdown-toggle:after {
  float: right;
  margin-right: 0 !important; }

.sidenav-menu .navbar-collapse {
  padding-left: 0;
  padding-right: 0; }

.sidenav-menu .dropdown a.show {
  color: #4f46e5; }

.sidenav-menu li.show > a.dropdown-toggle {
  color: #4f46e5; }

.sidenav-menu .dropdown-item:hover, .sidenav-menu .dropdown-item:focus, .sidenav-menu .dropdown-item:active {
  background: transparent;
  color: #4f46e5; }

.sidenav-menu .dropdown-menu {
  padding-top: 0;
  padding-left: 0.875rem;
  border: 0;
  box-shadow: none;
  position: static;
  float: none;
  min-width: 0; }

.sidenav-menu .dropdown-menu.show {
  border-left-width: 0 !important; }

.sidenav-menu .dropdown-menu li a {
  white-space: normal; }

.sidenav-menu .dropdown-submenu .dropdown-menu {
  border-radius: 0;
  box-shadow: none;
  border-left: 0;
  border-right: 0; }

.logo-sidenav {
  min-height: 4.5rem;
  text-align: center; }

.logo-sidenav a::before {
  content: "";
  width: 5rem;
  height: 1px;
  background: #4f46e5;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -2.5rem; }

.list-group-level1 {
  padding-left: 0.5rem;
  border-top: #dee2e6 1px solid; }

.list-group-level2 {
  padding-left: 1rem;
  border-top: #dee2e6 1px solid; }

/*------------------------------------
  Post page
------------------------------------*/
.post-content {
  line-height: 1.75;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  -webkit-font-smoothing: antialiased; }

.post-content h1, .post-content .h1, .post-content h2, .post-content .h2, .post-content h3, .post-content .h3, .post-content h4, .post-content .h4, .post-content h5, .post-content .h5, .post-content h6, .post-content .h6 {
  margin-bottom: 1rem; }

.post-content p {
  color: #495057; }

.post-content .align-start, .post-content img.align-start, .post-content .alignleft {
  float: left;
  margin-right: 1.2rem;
  margin-top: 4px; }

.post-content .align-end, .post-content img.align-end, .post-content .alignright {
  float: right;
  margin-left: 1.2rem;
  margin-top: 4px; }

.post-content .align-center, .post-content img.align-center, .post-content .aligncenter {
  text-align: center; }

.post-content .align-center figcaption, .post-content img.align-center figcaption, .post-content .aligncenter figcaption {
  text-align: center; }

.post-content ul, .post-content ol {
  color: #495057; }

.post-content ul li, .post-content ol li {
  margin-left: 0;
  line-height: 24px; }

.post-content img {
  max-width: 100%; }

.post-content .wp-block-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: auto; }

.post-content .wp-block-embed.is-type-video {
  overflow: hidden; }

.type-page {
  margin-bottom: 3rem; }

.post-content figure {
  margin-bottom: 1rem; }

.post-content figure .b-lazy {
  width: inherit; }

.post-content figure figcaption {
  font-family: Verdana,sans-serif;
  text-align: left;
  font-size: 0.8em;
  font-style: italic;
  font-weight: 400;
  line-height: 1rem;
  color: #6c757d;
  padding: 5px 0; }

.post-title {
  letter-spacing: -.1px;
  font-weight: 700; }

.post-title h1, .post-title .h1 {
  font-weight: 500; }

.before-arrow-dark li, .before-arrow li {
  list-style: none;
  margin-left: 0 !important; }

.before-arrow-dark li:before, .before-arrow li:before {
  content: "";
  width: .75rem;
  height: .75rem;
  margin-bottom: -1px;
  display: inline-block;
  background-repeat: no-repeat;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-right: 0.8rem;
  background-size: 1rem; }

.before-arrow li:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.before-arrow-dark li:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpolyline points='112 184 256 328 400 184' fill='none' stroke='rgba%2827, 27, 27, 0.9%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='48px'/%3e%3c/svg%3e"); }

.children-ul {
  padding-left: 2rem; }

.ql-editor {
  max-height: 40rem; }

.u-blockquote, .wp-block-quote {
  border-left: #4f46e5 3px solid;
  background: #f8f9fa;
  padding: 1rem; }

.u-blockquote p, .u-blockquote footer, .wp-block-quote p, .wp-block-quote footer {
  margin-left: 3.5rem; }

.u-blockquote::before, .wp-block-quote::before {
  content: "";
  width: 2rem;
  height: 2rem;
  margin-bottom: -1px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 270.000000 270.000000'%3e%3cg transform='translate%280.000000,270.000000%29 scale%280.100000,-0.100000%29' fill='%234f46e5' stroke='none'%3e%3cpath d='M920 2182 c-290 -124 -482 -341 -540 -610 -30 -140 -40 -296 -40 -644 l0 -328 370 0 370 0 0 370 0 370 -181 0 -181 0 7 63 c26 243 129 387 342 477 35 15 66 29 69 32 7 7 -132 298 -143 298 -4 0 -37 -13 -73 -28z'/%3e%3cpath d='M2179 2186 c-249 -103 -442 -295 -520 -516 -50 -142 -61 -247 -66 -677 l-5 -393 371 0 371 0 0 370 0 370 -181 0 -181 0 7 53 c21 170 67 281 150 363 51 49 143 107 215 134 19 7 39 17 44 21 10 9 -124 298 -139 298 -5 0 -35 -10 -66 -23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  position: absolute;
  line-height: 1; }

.wp-block-quote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.wp-block-quote footer {
  margin-left: 0; }

.wp-block-quote cite {
  margin-left: 3.5rem;
  display: block;
  font-size: 80%;
  color: #6c757d; }

.post-page blockquote {
  border-left: #4f46e5 3px solid;
  background: #f8f9fa;
  padding: 1rem; }

.post-page blockquote::before {
  content: "";
  width: 2rem;
  height: 2rem;
  margin-bottom: -1px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 270.000000 270.000000'%3e%3cg transform='translate%280.000000,270.000000%29 scale%280.100000,-0.100000%29' fill='%234f46e5' stroke='none'%3e%3cpath d='M920 2182 c-290 -124 -482 -341 -540 -610 -30 -140 -40 -296 -40 -644 l0 -328 370 0 370 0 0 370 0 370 -181 0 -181 0 7 63 c26 243 129 387 342 477 35 15 66 29 69 32 7 7 -132 298 -143 298 -4 0 -37 -13 -73 -28z'/%3e%3cpath d='M2179 2186 c-249 -103 -442 -295 -520 -516 -50 -142 -61 -247 -66 -677 l-5 -393 371 0 371 0 0 370 0 370 -181 0 -181 0 7 53 c21 170 67 281 150 363 51 49 143 107 215 134 19 7 39 17 44 21 10 9 -124 298 -139 298 -5 0 -35 -10 -66 -23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  position: absolute;
  line-height: 1; }

.post-page blockquote p, .post-page blockquote footer {
  margin-left: 3.5rem; }

.post-page .entry-title {
  margin-bottom: 1.5rem; }

.post-page strong {
  color: #1b1b1b; }

.dropcaps {
  float: left;
  display: block;
  margin: 0.7rem 0.7rem 0 0;
  padding: 2px 10px 5px;
  font-size: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  text-transform: uppercase;
  min-width: 3.5rem;
  min-height: 3.5rem; }

.dropcaps.rounded-circle {
  font-size: 2.75rem;
  min-width: 4rem; }

.dropcaps-one {
  color: #1b1b1b;
  font-size: 5rem;
  padding: 0 !important; }

.next-post-end {
  text-align: right; }

.prev-post-start {
  text-align: left; }

.prev-post-start a, .next-post-end a {
  color: #1b1b1b;
  font-size: 1rem;
  font-weight: 500; }

.prev-post-start a:hover, .next-post-end a:hover {
  color: #4f46e5; }

.follow-us li {
  display: inline-block; }

.post-type-icon {
  position: absolute;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 32px;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  font-size: 14px;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -ms-flex: 1;
  flex: 1; }

@media (max-width: 991.98px) {
  .image-single-wrapper {
    min-height: 9rem; }
  .post-content .video-full {
    padding-bottom: 56% !important; } }

@media (max-width: 767.98px) {
  .post-content .align-start, .post-content img.align-start, .post-content .alignleft {
    max-width: 15rem; }
  .post-content .align-end, .post-content img.align-end, .post-content .alignright {
    max-width: 15rem; }
  .prev-post-start {
    text-align: center;
    margin-bottom: 1rem; }
  .next-post-end {
    text-align: center; } }

@media (max-width: 575.98px) {
  .post-content .align-start, .post-content img.align-start, .post-content .alignleft, .post-content .align-end, .post-content img.align-end, .post-content .alignright {
    float: inherit;
    text-align: center;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto; }
  .post-content .align-start figcaption, .post-content img.align-start figcaption, .post-content .alignleft figcaption, .post-content .align-end figcaption, .post-content img.align-end figcaption, .post-content .alignright figcaption {
    text-align: center; }
  .author-box {
    display: inherit;
    text-align: center; } }

/*------------------------------------
  Suggestion post
------------------------------------*/
.suggestion-box {
  position: fixed;
  z-index: 999;
  bottom: 4rem;
  right: -18.3rem;
  width: 18.3rem;
  border: #dee2e6 1px solid;
  border-top: #4f46e5 4px solid;
  padding: 10px 20px 20px;
  transition: all .5s; }

.suggestion-box.show {
  right: 0; }

.suggestion-box.close {
  display: none; }

.close-suggestion {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1px 8px 3px;
  font-size: 1.2rem;
  cursor: pointer; }

/*------------------------------------
	Comment
------------------------------------*/
.comment-list li {
  list-style: none;
  margin-top: 2rem; }

.comment-list li .user-comment {
  border-bottom: #dee2e6 1px dashed;
  padding-bottom: 1rem; }

.comment-list li .user-comment .comment-author .avatar {
  float: left;
  margin-right: 1rem; }

.comment-list li .user-comment .title-comments .authors {
  font-weight: 700; }

.comment-list li .user-comment .comment-content p {
  margin-bottom: 0; }

.comment-list li .sub-comment {
  margin-left: 6rem; }

.comment-list li .sub-comment .sub-comment {
  margin-left: 0; }

.comment-awaiting-moderation {
  background: #ffc107; }

@media (min-width: 576px) {
  .comment-list li .sub-comment .sub-comment {
    margin-left: 6rem; }
  .comment-list .title-comments .dates {
    float: right; } }

.recentcomments a {
  font-style: italic;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-size: 0.875rem; }

.recentcomments .comment-author-link {
  font-style: italic;
  font-family: "Roboto", Helvetica, sans-serif !important;
  font-size: 0.875rem; }

.recentcomments .comment-author-link a.url {
  color: #4f46e5 !important; }

.icon-comments:after, .icon-comments-dark:after {
  display: inline-block;
  width: .75rem;
  height: .75rem;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: .75rem;
  margin-bottom: -.2rem;
  content: ""; }

.icon-comments:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231b1b1b' viewBox='0 0 16 16'%3e%3cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z'/%3e%3c/svg%3e");
  opacity: .9; }

.icon-comments-dark:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 16 16'%3e%3cpath d='M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z'/%3e%3c/svg%3e");
  opacity: .7; }

/*------------------------------------
	Archive
------------------------------------*/
.rotate-down {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.collapsed .rotate-down {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg); }

/*------------------------------------
  Ratings
------------------------------------*/
.star-rating, .back-stars, .back-stars-light, .front-stars {
  display: -ms-flexbox;
  display: flex; }

.star-rating {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.back-stars {
  color: #1b1b1b;
  position: relative; }

.back-stars-light {
  color: #f8f9fa;
  position: relative; }

.front-stars {
  color: #ffc107;
  overflow: hidden;
  position: absolute;
  top: 0; }

/*------------------------------------
	Tags
------------------------------------*/
.post-content .tags-list li {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-bottom: 0.25rem;
  margin-left: inherit;
  line-height: inherit; }

.post-content .tags-list li:hover {
  background-color: #4f46e5; }

.post-content .tags-list li:hover a {
  color: #fff; }

.post-content .tags-list li:first-child {
  padding-left: 0;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  border-color: transparent !important;
  font-weight: 500; }

.post-content .tags-list li:first-child span {
  color: #1b1b1b; }

.tags-links a {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: inherit;
  line-height: inherit; }

.tags-links a:hover {
  background-color: #4f46e5;
  color: #fff; }

.tags-list-cat li {
  padding: 0rem 0.5rem;
  display: inline-block;
  border: #dee2e6 1px solid;
  margin-bottom: 0.25rem; }

.tags-list-cat li:hover {
  background-color: #4f46e5; }

.tags-list-cat li:hover a {
  color: #fff; }

.tagcloud a:before {
  content: "#"; }

/*------------------------------------
  Messages
------------------------------------*/
.unread {
  cursor: pointer;
  background-color: #f8f9fa; }

.messages-box {
  max-height: 28rem;
  overflow: auto; }

.online-circle {
  border-radius: 5rem;
  width: 5rem;
  height: 5rem; }

.messages-title {
  float: right;
  margin: 0px 5px; }

.message-img {
  float: right;
  margin: 0px 5px; }

.message-header {
  text-align: right;
  width: 100%;
  margin-bottom: 0.5rem; }

.text-editor {
  min-height: 18rem; }

.messages-list li.messages-you .messages-title {
  float: left; }

.messages-list li.messages-you .message-img {
  float: left; }

.messages-list li.messages-you p {
  float: left;
  text-align: left; }

.messages-list li.messages-you .message-header {
  text-align: left; }

.messages-list li p {
  max-width: 60%;
  padding: 15px;
  border: #dee2e6 1px solid; }

.messages-list li.messages-me p {
  float: right; }

.ql-editor p {
  font-size: 1rem; }

/*------------------------------------
  Carousel
------------------------------------*/
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  right: auto;
  left: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  left: auto;
  right: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0; }

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1; }

.nav-dark-button .flickity-button {
  background: rgba(197, 198, 208, 0.7);
  color: #fff; }

.nav-dark-button .flickity-button:hover {
  background: #c5c6d0; }

.nav-primary-button .flickity-button {
  background: #4f46e5 !important;
  color: #fff; }

.nav-primary-button .flickity-button:hover {
  background: #4f46e5 !important;
  color: #fff;
  opacity: .9; }

.nav-primary-button .flickity-page-dots li {
  background: #4f46e5 !important; }

.nav-primary-button .flickity-page-dots li.is-selected {
  background: #4f46e5 !important; }

.nav-inset-button .flickity-button.previous {
  left: 1.5rem; }

.nav-inset-button .flickity-button.next {
  right: 1.5rem; }

.nav-inset-button .flickity-page-dots {
  bottom: 1.5rem; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 33%;
  top: 33%;
  width: 35%;
  height: 35%;
  opacity: .8; }

.slider-item img {
  cursor: pointer; }

.dot-in-content .flickity-page-dots {
  margin-bottom: 3rem; }

.nav-light-dots .flickity-page-dots li {
  background-color: #f8f9fa; }

.nav-light-dots .flickity-page-dots li.is-selected {
  background-color: #fff; }

.hero-slider .flickity-button, .hero-slider2 .flickity-button {
  opacity: 0;
  transition: all 1.2s ease; }

.hero-slider:hover .flickity-button, .hero-slider2:hover .flickity-button {
  opacity: 1;
  transition: all 1.2s ease; }

.hero-slider .flickity-page-dots, .hero-slider2 .flickity-page-dots {
  bottom: 2rem; }

.nav-slider-hover .flickity-button, .nav-hover-show .flickity-button {
  opacity: 0;
  transition: all 1.2s ease; }

.nav-slider-hover:hover .flickity-button, .nav-hover-show:hover .flickity-button {
  opacity: 1;
  transition: all 1.2s ease; }

.flickity-rtl .reviews-one::before, .flickity-rtl .reviews-two::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.flickity-rtl .reviews-four::after, .flickity-rtl .reviews-five::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.nav-hidden .flickity-button {
  opacity: 0; }

.nav-dots-top-right .flickity-page-dots {
  bottom: auto;
  top: 1rem;
  right: 1rem;
  text-align: right; }

.light-dots .dot {
  background: #f8f9fa;
  opacity: .75; }

.light-dots .dot.is-selected {
  background: #4f46e5; }

.box-carousel .flickity-button-icon {
  width: 20%; }

.box-carousel .flickity-button {
  background: transparent; }

.box-carousel .flickity-button:hover {
  background: transparent; }

.box-carousel .flickity-button.previous {
  left: auto;
  right: 2rem; }

.box-carousel .flickity-button.next {
  right: 0; }

/*------------------------------------
  Avatar
------------------------------------*/
.avatar-user {
  margin: 0.5rem auto;
  text-align: center; }

.card-avatar {
  border: #fff 5px solid; }

.avatar-rounded {
  border-radius: 3rem; }

.avatar-xs {
  width: 1.5625rem;
  height: 1.5625rem; }

.avatar-sm {
  width: 3.125rem;
  height: 3.125rem; }

.avatar-md {
  width: 4.6875rem;
  height: 4.6875rem; }

.avatar-lg {
  width: 6.25rem;
  height: 6.25rem; }

.avatar-xl {
  width: 10rem;
  height: 10rem; }

.nav-avatar {
  padding: 0rem 1rem; }

/*------------------------------------
	Five Grid Column
------------------------------------*/
.col-24,
.col-sm-24,
.col-md-24,
.col-lg-24,
.col-xl-24 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-24 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.offset-24 {
  margin-left: 20%; }

.col-24 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.offset-24 {
  margin-left: 20%; }

@media (min-width: 576px) {
  .col-sm-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .offset-sm-24 {
    margin-left: 20%; } }

@media (min-width: 768px) {
  .col-md-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .offset-md-24 {
    margin-left: 20%; } }

@media (min-width: 992px) {
  .col-lg-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .offset-lg-24 {
    margin-left: 20%; } }

@media (min-width: 1200px) {
  .col-xl-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .offset-xl-24 {
    margin-left: 20%; } }

@media (min-width: 1400px) {
  .col-xxl-24 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .offset-xxl-24 {
    margin-left: 20%; } }

/*------------------------------------
	Member area
------------------------------------*/
.sidebar-item.active, .sidebar-item:hover {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-right: 0;
  margin-right: -1px; }

.sidebar-menu {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #f8f9fa; }

.sidebar-menu li a.active, .sidebar-menu li a:hover {
  background-color: #fff; }

.sidebar-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent; }

.sidebar-item.active:hover {
  background-color: #fff; }

.card .icon-big {
  font-size: 3rem; }

.card .icon-big .notif {
  position: absolute;
  min-width: 25px;
  border-radius: 5rem;
  font-size: 1rem;
  background: #dc3545;
  color: #fff; }

.card .numbers {
  text-align: right; }

.card .numbers p {
  font-size: 1rem;
  margin: 0; }

.card .footer {
  padding: 0;
  line-height: 30px; }

.side-notif {
  padding-left: 7px;
  padding-right: 7px;
  font-size: 0.8rem;
  border-radius: 5rem;
  background-color: #dc3545;
  color: #fff; }

.statistics li {
  padding: 0.25rem 0; }

.member-item:hover .card {
  background-color: #f8f9fa; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa; }

@media (max-width: 575.98px) {
  .sidebar-item.active, .sidebar-item:hover {
    border-right: 1px solid #dee2e6;
    margin-left: 0.25rem;
    margin-right: 0.25rem; } }

/*------------------------------------
	Vertical slider
------------------------------------*/
.top-indicator {
  right: 0;
  top: 1rem;
  bottom: inherit;
  left: inherit;
  -ms-flex-pack: right;
      justify-content: right;
  margin-right: 1rem; }

.slider-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #fff;
  background-color: rgba(27, 27, 27, 0.5);
  text-align: left;
  font-size: 1rem;
  padding: 1.333rem;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/*------------------------------------
	Responsive maps
------------------------------------*/
.responsive-maps {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden; }

.responsive-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

/*------------------------------------
	Widget Social
------------------------------------*/
.social-two li, .social-one li {
  float: left;
  width: 49%; }

.social-two li:nth-child(even), .social-one li:nth-child(even) {
  float: right; }

.social-two li a, .social-one li a {
  display: block;
  font-size: 14px;
  line-height: 34px;
  border-radius: 0; }

.social-two li a:hover, .social-one li a:hover {
  opacity: 0.9; }

.social-two li a:focus, .social-one li a:focus {
  opacity: 0.9; }

.social-two li a::before, .social-one li a::before {
  float: left;
  margin-left: 0.8rem;
  vertical-align: inherit;
  border-top: inherit;
  border-right: inherit;
  border-bottom: 0;
  border-left: inherit;
  width: 34px;
  height: 34px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  background-size: 1rem;
  content: ""; }

.social-two .facebook a::before, .social-one .facebook a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 16 16'%3e%3cpath d='M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z'/%3e%3c/svg%3e"); }

.social-two .twitter a::before, .social-one .twitter a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231b1b1b' viewBox='0 0 16 16'%3e%3cpath d='M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z'/%3e%3c/svg%3e"); }

.social-two .vk a::before, .social-one .vk a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 512 512'%3e%3cpath d='M484.7,132c3.56-11.28,0-19.48-15.75-19.48H416.58c-13.21,0-19.31,7.18-22.87,14.86,0,0-26.94,65.6-64.56,108.13-12.2,12.3-17.79,16.4-24.4,16.4-3.56,0-8.14-4.1-8.14-15.37V131.47c0-13.32-4.06-19.47-15.25-19.47H199c-8.14,0-13.22,6.15-13.22,12.3,0,12.81,18.81,15.89,20.84,51.76V254c0,16.91-3,20-9.66,20-17.79,0-61-66.11-86.92-141.44C105,117.64,99.88,112,86.66,112H33.79C18.54,112,16,119.17,16,126.86c0,13.84,17.79,83.53,82.86,175.77,43.21,63,104.72,96.86,160.13,96.86,33.56,0,37.62-7.69,37.62-20.5V331.33c0-15.37,3.05-17.93,13.73-17.93,7.62,0,21.35,4.09,52.36,34.33C398.28,383.6,404.38,400,424.21,400h52.36c15.25,0,22.37-7.69,18.3-22.55-4.57-14.86-21.86-36.38-44.23-62-12.2-14.34-30.5-30.23-36.09-37.92-7.62-10.25-5.59-14.35,0-23.57-.51,0,63.55-91.22,70.15-122' fill-rule='evenodd'/%3e%3c/svg%3e"); }

.social-two .instagram a::before, .social-one .instagram a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 512 512'%3e%3cpath d='M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z'/%3e%3cpath d='M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z'/%3e%3cpath d='M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z'/%3e%3c/svg%3e"); }

.social-two .youtube a::before, .social-one .youtube a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 16 16'%3e%3cpath d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.122C.002 7.343.01 6.6.064 5.78l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z'/%3e%3c/svg%3e"); }

.social-two .linkedin a::before, .social-one .linkedin a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 512 512'%3e%3cpath d='M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z'/%3e%3c/svg%3e"); }

.social-two .vimeo a::before, .social-one .vimeo a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231b1b1b' viewBox='0 0 512 512'%3e%3cpath d='M476.9,114c-5-23.39-17.51-38.78-40.61-46.27s-64.92-4.5-94.12,16.79c-26.79,19.51-46.26,54.42-54,78.28a4,4,0,0,0,5.13,5c10.77-3.8,21.72-7.1,34-6.45,15,.8,24.51,12,24.91,25.29.3,9.79-.2,18.69-3.6,27.68C337.87,243,321,270.78,301.06,295.07a72.49,72.49,0,0,1-10,9.89c-10.21,8.29-18.81,6.1-25.41-5.2-5.4-9.29-9-18.88-12.2-29.08-12.4-39.67-16.81-80.84-23.81-121.52-3.3-19.48-7-39.77-18-56.86-11.6-17.79-28.61-24.58-50-22-14.7,1.8-36.91,17.49-47.81,26.39,0,0-56,46.87-81.82,71.35l21.2,27s17.91-12.49,27.51-18.29c5.7-3.39,12.4-4.09,17.2.2,4.51,3.9,9.61,9,12.31,14.1,5.7,10.69,11.2,21.88,14.7,33.37,13.2,44.27,25.51,88.64,37.81,133.22,6.3,22.78,13.9,44.17,28,63.55,19.31,26.59,39.61,32.68,70.92,21.49,25.41-9.09,46.61-26.18,66-43.87,33.11-30.18,59.12-65.36,85.52-101.14C433.59,270,450.49,242,464.59,210.72,478.5,179.74,484,147.26,476.9,114Z'/%3e%3c/svg%3e"); }

.social-two .pinterest a::before, .social-one .pinterest a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 512 512'%3e%3cpath d='M256.05,32c-123.7,0-224,100.3-224,224,0,91.7,55.2,170.5,134.1,205.2-.6-15.6-.1-34.4,3.9-51.4,4.3-18.2,28.8-122.1,28.8-122.1s-7.2-14.3-7.2-35.4c0-33.2,19.2-58,43.2-58,20.4,0,30.2,15.3,30.2,33.6,0,20.5-13.1,51.1-19.8,79.5-5.6,23.8,11.9,43.1,35.4,43.1,42.4,0,71-54.5,71-119.1,0-49.1-33.1-85.8-93.2-85.8-67.9,0-110.3,50.7-110.3,107.3,0,19.5,5.8,33.3,14.8,43.9,4.1,4.9,4.7,6.9,3.2,12.5-1.1,4.1-3.5,14-4.6,18-1.5,5.7-6.1,7.7-11.2,5.6-31.3-12.8-45.9-47-45.9-85.6,0-63.6,53.7-139.9,160.1-139.9,85.5,0,141.8,61.9,141.8,128.3,0,87.9-48.9,153.5-120.9,153.5-24.2,0-46.9-13.1-54.7-27.9,0,0-13,51.6-15.8,61.6-4.7,17.3-14,34.5-22.5,48a225.13,225.13,0,0,0,63.5,9.2c123.7,0,224-100.3,224-224S379.75,32,256.05,32Z'/%3e%3c/svg%3e"); }

.social-two .telegram a::before, .social-one .telegram a::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f8f9fa' viewBox='0 0 512 512'%3e%3cpath d='M53.12,199.94l400-151.39a8,8,0,0,1,10.33,10.33l-151.39,400a8,8,0,0,1-15-.34L229.66,292.45a16,16,0,0,0-10.11-10.11L53.46,215A8,8,0,0,1,53.12,199.94Z' fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3cline x1='460' y1='52' x2='227' y2='285' fill='none' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='32px'/%3e%3c/svg%3e"); }

.social-three li {
  margin: 0 -1px -1px 0 !important; }

.social-three li:hover {
  opacity: 0.9; }

.social-three li:focus {
  opacity: 0.9; }

.btn-social:hover {
  opacity: 0.9; }

.btn-social:focus {
  opacity: 0.9; }

@media (min-width: 768px) {
  .social-two li, .social-one li {
    width: 100%; } }

@media (min-width: 992px) {
  .social-two li {
    width: 49%; } }

/*------------------------------------
  Social color
------------------------------------*/
.text-facebook {
  color: #3b5898 !important; }

.hover-facebook-text:hover i {
  color: #3b5898 !important; }

.hover-facebook a:hover i {
  color: #3b5898 !important; }

.bg-facebook, .btn-facebook {
  background-color: #3b5898 !important;
  color: #fff; }

.text-twitter {
  color: #55aced !important; }

.hover-twitter-text:hover i {
  color: #55aced !important; }

.hover-twitter a:hover i {
  color: #55aced !important; }

.bg-twitter, .btn-twitter {
  background-color: #55aced !important;
  color: #fff; }

.text-instagram {
  color: #6d48cb !important; }

.hover-instagram-text:hover i {
  color: #6d48cb !important; }

.hover-instagram a:hover i {
  color: #6d48cb !important; }

.bg-instagram, .btn-instagram {
  background-color: #6d48cb !important;
  color: #fff; }

.text-youtube {
  color: #e02a21 !important; }

.hover-youtube-text:hover i {
  color: #e02a21 !important; }

.hover-youtube a:hover i {
  color: #e02a21 !important; }

.bg-youtube, .btn-youtube {
  background-color: #e02a21 !important;
  color: #fff; }

.text-vimeo {
  color: #7ac5f2 !important; }

.hover-vimeo-text:hover i {
  color: #7ac5f2 !important; }

.hover-vimeo a:hover i {
  color: #7ac5f2 !important; }

.bg-vimeo, .btn-vimeo {
  background-color: #7ac5f2 !important;
  color: #fff; }

.text-envelope {
  color: #f2610b !important; }

.hover-envelope-text:hover i {
  color: #f2610b !important; }

.hover-envelope a:hover i {
  color: #f2610b !important; }

.bg-envelope, .btn-envelope {
  background-color: #f2610b !important;
  color: #fff; }

.text-linkedin {
  color: #3371b7 !important; }

.hover-linkedin-text:hover i {
  color: #3371b7 !important; }

.hover-linkedin a:hover i {
  color: #3371b7 !important; }

.bg-linkedin, .btn-linkedin {
  background-color: #3371b7 !important;
  color: #fff; }

.text-telegram {
  color: #075c8d !important; }

.hover-telegram-text:hover i {
  color: #075c8d !important; }

.hover-telegram a:hover i {
  color: #075c8d !important; }

.bg-telegram, .btn-telegram {
  background-color: #075c8d !important;
  color: #fff; }

.text-pinterest {
  color: #c9261a !important; }

.hover-pinterest-text:hover i {
  color: #c9261a !important; }

.hover-pinterest a:hover i {
  color: #c9261a !important; }

.bg-pinterest, .btn-pinterest {
  background-color: #c9261a !important;
  color: #fff; }

.text-vk {
  color: #5a7fa6 !important; }

.hover-vk-text:hover i {
  color: #5a7fa6 !important; }

.hover-vk a:hover i {
  color: #5a7fa6 !important; }

.bg-vk, .btn-vk {
  background-color: #5a7fa6 !important;
  color: #fff; }

/*------------------------------------
	Popular post
------------------------------------*/
.post-number {
  counter-reset: my-counter; }

.post-number li {
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem 1rem; }

.post-number li a::before {
  font-size: 2rem;
  font-weight: 600;
  counter-increment: my-counter;
  content: counter(my-counter);
  float: left;
  color: #ced4da !important;
  width: 3rem;
  margin-right: 0.5rem;
  line-height: 2rem;
  text-align: center; }

.post-number li:hover a::before {
  color: #4f46e5 !important; }

/*------------------------------------
	Weather
------------------------------------*/
.weather-icon {
  width: 3rem;
  top: 0;
  position: absolute; }

.weather-span {
  margin-left: 2.8rem; }

.weather-small {
  width: 2rem; }

/*------------------------------------
  Youtube Playlist
------------------------------------*/
.playlist-title {
  max-height: 307px;
  overflow: auto; }

.playlist-title li {
  padding: 5px 0px; }

.playlist-title li:hover {
  background-color: #1b1b1b;
  cursor: pointer; }

.playlist-title li.active {
  background-color: #1b1b1b;
  cursor: pointer; }

.playlist-title li.active .video-thumb {
  border: #4f46e5 1px solid; }

.playlist-title li .video-thumb {
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent; }

.playlist-title li .video-title {
  margin: 0;
  display: inline;
  vertical-align: middle;
  text-align: left;
  color: #f8f9fa; }

.video-full iframe {
  width: 100% !important;
  height: 100% !important;
  border: 0; }

.video-youtube-area iframe, .video-youtube-area source {
  width: 100% !important;
  border: 0;
  height: 160%; }

/*------------------------------------
  Widget Score
------------------------------------*/
.match-score {
  text-align: center; }

.match-score > li {
  padding: 0.3rem 0; }

.match-score > li:hover {
  background-color: #f8f9fa; }

.match-title {
  padding: 0.3rem 0;
  background-color: #f8f9fa; }

.teamname {
  font-weight: 600; }

/*------------------------------------
  Timeline Blog List
------------------------------------*/
.timeline-post {
  list-style-type: none;
  position: relative; }

.timeline-post:before {
  content: ' ';
  background: #dee2e6;
  display: inline-block;
  position: absolute;
  left: 1.5rem;
  width: 2px;
  height: 100%;
  z-index: 5; }

.timeline-post > li {
  margin: 1rem 0;
  padding-left: 0.875rem;
  padding-right: 0.875rem; }

.timeline-post > li:first-child {
  margin-top: 0; }

.timeline-post > li:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  margin-top: .3rem;
  border-radius: 50%;
  border: 3px solid #dee2e6;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  z-index: 5; }

.timeline-post > li:hover:before {
  background: #4f46e5 !important; }

.timeline-post > li a .timeline-date, .timeline-post > li a .timeline-title {
  padding-left: 1.5rem; }

.timeline-post > li a .timeline-date:hover, .timeline-post > li a .timeline-title:hover {
  color: #4f46e5 !important; }

/*------------------------------------
  Widget
------------------------------------*/
.widget-title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding: 0.5rem 0; }

.widget ul, .widget ol {
  padding-left: 0;
  list-style: none; }

.widget ul li, .widget ol li {
  margin-bottom: 0.5rem; }

.widget ul li a, .widget ol li a {
  color: #1b1b1b; }

.widget ul li a.url, .widget ol li a.url {
  color: #4f46e5;
  font-style: italic; }

.widget ul li a:hover, .widget ol li a:hover {
  color: #4f46e5; }

.widget ul li a.active:hover, .widget ol li a.active:hover {
  color: #fff; }

.widget ul.no-mb li, .widget ol.no-mb li {
  margin-bottom: -1px; }

.widget ul.custom-category li, .widget ol.custom-category li {
  margin-bottom: 0; }

.footer-dark .widget ul li a, .footer-dark .widget ol li a {
  color: #dee2e6; }

.footer-dark .widget ul li a.url, .footer-dark .widget ol li a.url {
  color: #4f46e5;
  font-style: italic; }

.footer-dark .widget ul li a:hover, .footer-dark .widget ol li a:hover {
  color: #4f46e5; }

.tagcloud li {
  display: inline; }

/*------------------------------------
	Progress bars
------------------------------------*/
#progress-bar {
  z-index: 2000;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  border: none; }

.progress-one {
  height: 2px; }

.progress-two {
  height: 4px; }

.progress-three {
  height: 5px; }

progress {
  color: #4f46e5; }

progress::-webkit-progress-value, progress::-moz-progress-bar, progress::-webkit-progress-value, progress::-webkit-progress-bar {
  background: #4f46e5; }

/*------------------------------------
	Background images
------------------------------------*/
.boxed .bg-image, .framed .bg-image {
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 100%; }

.bg-cover .bg-image, .bg-cover2 .bg-image, .bg-cover3 .bg-image {
  background-color: #fff;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover; }

.bg-repeat .bg-image, .bg-repeat2 .bg-image, .bg-repeat3 .bg-image {
  background-color: #fff;
  position: fixed;
  background-repeat: repeat; }

.bg-cover .bg-image {
  background-image: url(../img/background/bg1.jpg); }

.bg-cover2 .bg-image {
  background-image: url(../img/background/bg2.jpg); }

.bg-cover3 .bg-image {
  background-image: url(../img/background/bg3.jpg); }

.bg-repeat .bg-image {
  background-image: url(../img/background/pattern1.png); }

.bg-repeat2 .bg-image {
  background-image: url(../img/background/pattern2.png); }

.bg-repeat3 .bg-image {
  background-image: url(../img/background/pattern3.png); }

.bg-pattern-repeat {
  background: #f8f9fa url(../img/background/pattern4.png) repeat; }

/*------------------------------------
	Background Shadow
------------------------------------*/
.bg-shadow {
  background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19, 19, 19, 0.8) 100%); }

.bg-lg-shadow {
  background: linear-gradient(to bottom, rgba(16, 11, 11, 0) 0%, rgba(0, 0, 0, 0.67) 49%, rgba(19, 19, 19, 0.88) 100%); }

.bg-shadow-two {
  background: rgba(53, 53, 53, 0.75); }

.bg-full-shadow {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .6;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  z-index: 2; }

/*------------------------------------
	Video Background
------------------------------------*/
.background-video {
  position: relative;
  background-color: #000;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden; }

.background-video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.background-video video {
  width: auto;
  height: auto; }

.background-video .video-youtube-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.background-video .container {
  position: relative;
  z-index: 2; }

.background-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 1; }

.mini-line {
  margin: 1rem auto;
  width: 6rem;
  border-top: #4f46e5 3px solid; }

@media (max-width: 575.98px) {
  .background-video {
    height: 30vh;
    min-height: 0; } }

@media (min-width: 576px) {
  .background-video {
    height: 40vh;
    min-height: 20rem; } }

@media (min-width: 768px) {
  .background-video {
    height: 50vh;
    min-height: 20rem; } }

@media (min-width: 992px) {
  .background-video {
    height: 75vh;
    min-height: 25rem; } }

/*------------------------------------
	Hover
------------------------------------*/
.hover-show {
  opacity: 0; }

.hover-a:hover a.h1, .hover-a:hover a.h2, .hover-a:hover a.h3, .hover-a:hover a.h4, .hover-a:hover a.h5, .hover-a:hover a.h6 {
  color: #4f46e5; }

.hover-a:hover h1 > a, .hover-a:hover .h1 > a, .hover-a:hover h2 > a, .hover-a:hover .h2 > a, .hover-a:hover h3 > a, .hover-a:hover .h3 > a, .hover-a:hover h4 > a, .hover-a:hover .h4 > a, .hover-a:hover h5 > a, .hover-a:hover .h5 > a, .hover-a:hover h6 > a, .hover-a:hover .h6 > a, .hover-a:hover .h1 > a, .hover-a:hover .h2 > a, .hover-a:hover .h3 > a, .hover-a:hover .h4 > a, .hover-a:hover .h5 > a, .hover-a:hover .h6 > a, .hover-a:hover p > a {
  color: #4f46e5; }

.hover-a:hover .bg-full-shadow {
  opacity: 0.5; }

.hover-a:hover .hover-block, .hover-a:hover .hover-show, .hover-a:hover .caret-up img, .hover-a:hover .caret-down img, .hover-a:hover .caret-left img, .hover-a:hover .caret-right img, .hover-a:hover img.card-avatar, .hover-a:hover .fa-stack-sea {
  opacity: 1; }

.hover-a:hover .hover-hide {
  opacity: 0; }

.hover-a li:hover a {
  color: #4f46e5; }

.hover-a li:hover a.h6 {
  color: #4f46e5; }

.hover-zoomin img {
  opacity: 1;
  transition: opacity 1s,-webkit-transform 1s;
  transition: opacity 1s,transform 1s;
  transition: opacity 1s,transform 1s,-webkit-transform 1s; }

.hover-zoomin:hover img {
  opacity: .9;
  -webkit-transform: scale3d(1.1, 1.1, 1);
          transform: scale3d(1.1, 1.1, 1); }

.fa-stack-sea {
  opacity: .7;
  background-color: #fff;
  border: #4f46e5 3px solid;
  border-radius: 3rem;
  display: inline-block;
  height: 2.5em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-sea.stack-2x {
  padding: 0.8rem;
  height: 4rem;
  width: 4rem; }

.hover-block {
  opacity: 0; }

a:hover {
  color: #4f46e5; }

h1 > a:hover, .h1 > a:hover, h2 > a:hover, .h2 > a:hover, h3 > a:hover, .h3 > a:hover, h4 > a:hover, .h4 > a:hover, h5 > a:hover, .h5 > a:hover, h6 > a:hover, .h6 > a:hover, .h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #4f46e5; }

.hover-link li a:hover {
  background-color: #f8f9fa; }

.cursor-pointer {
  cursor: pointer; }

.rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-n45 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate-n90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate-n180 {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.rotate-n270 {
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg); }

/*------------------------------------
	Zoom
------------------------------------*/
.overflow {
  position: relative;
  overflow: hidden; }

.overflow img {
  position: relative;
  display: block; }

.overflow video {
  position: relative;
  display: block; }

.zoom img {
  transition: all 0.2s linear; }

.zoom video {
  transition: all 0.2s linear; }

.zoom:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.zoom:hover video {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

/*------------------------------------
	Typograpy
------------------------------------*/
.h1 {
  font-size: 1.5rem; }

.h2 {
  font-size: 1.375rem; }

.h3 {
  font-size: 1.25rem; }

.h4 {
  font-size: 1.125rem; }

.h5 {
  font-size: 1rem; }

.h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 576px) {
  .h1-sm {
    font-size: 1.5rem; }
  .h2-sm {
    font-size: 1.375rem; }
  .h3-sm {
    font-size: 1.25rem; }
  .h4-sm {
    font-size: 1.125rem; }
  .h5-sm {
    font-size: 1rem; }
  .h6-sm {
    font-size: 0.875rem; }
  .lead-sm {
    font-size: 1.25rem;
    font-weight: 300; }
  .display-1-sm {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-2-sm {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-3-sm {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-4-sm {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2; } }

@media (min-width: 768px) {
  .h1-md {
    font-size: 1.5rem; }
  .h2-md {
    font-size: 1.375rem; }
  .h3-md {
    font-size: 1.25rem; }
  .h4-md {
    font-size: 1.125rem; }
  .h5-md {
    font-size: 1rem; }
  .h6-md {
    font-size: 0.875rem; }
  .lead-md {
    font-size: 1.25rem;
    font-weight: 300; }
  .display-1-md {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-2-md {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-3-md {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-4-md {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2; } }

@media (min-width: 992px) {
  .h1-lg {
    font-size: 1.5rem; }
  .h2-lg {
    font-size: 1.375rem; }
  .h3-lg {
    font-size: 1.25rem; }
  .h4-lg {
    font-size: 1.125rem; }
  .h5-lg {
    font-size: 1rem; }
  .h6-lg {
    font-size: 0.875rem; }
  .lead-lg {
    font-size: 1.25rem;
    font-weight: 300; }
  .display-1-lg {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-2-lg {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-3-lg {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-4-lg {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2; } }

@media (min-width: 1200px) {
  .h1-xl {
    font-size: 1.5rem; }
  .h2-xl {
    font-size: 1.375rem; }
  .h3-xl {
    font-size: 1.25rem; }
  .h4-xl {
    font-size: 1.125rem; }
  .h5-xl {
    font-size: 1rem; }
  .h6-xl {
    font-size: 0.875rem; }
  .lead-xl {
    font-size: 1.25rem;
    font-weight: 300; }
  .display-1-xl {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-2-xl {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-3-xl {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-4-xl {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2; } }

@media (min-width: 1400px) {
  .h1-xxl {
    font-size: 1.5rem; }
  .h2-xxl {
    font-size: 1.375rem; }
  .h3-xxl {
    font-size: 1.25rem; }
  .h4-xxl {
    font-size: 1.125rem; }
  .h5-xxl {
    font-size: 1rem; }
  .h6-xxl {
    font-size: 0.875rem; }
  .lead-xxl {
    font-size: 1.25rem;
    font-weight: 300; }
  .display-1-xxl {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-2-xxl {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-3-xxl {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2; }
  .display-4-xxl {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2; } }

.text-center-down {
  text-align: center; }

@media (max-width: 575.98px) {
  .text-center-sm-down {
    text-align: center; } }

@media (max-width: 767.98px) {
  .text-center-md-down {
    text-align: center; } }

@media (max-width: 991.98px) {
  .text-center-lg-down {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .text-center-xl-down {
    text-align: center; } }

@media (max-width: 1399.98px) {
  .text-center-xxl-down {
    text-align: center; } }

/*------------------------------------
	Gradient images efect
------------------------------------*/
.gradient > a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .6;
  display: block; }

.gradient-1 > a:after {
  background: linear-gradient(45deg, #211d6b 0, #ce381c 100%); }

.gradient-2 > a:after {
  background: linear-gradient(45deg, #18256f 0, #1fe6e4 100%); }

.gradient-3 > a:after {
  background: linear-gradient(45deg, #187957 0, #52e253 100%); }

.gradient-4 > a:after {
  background: linear-gradient(45deg, #9a8700 0, #dbff16 100%); }

.gradient-5 > a:after {
  background: linear-gradient(45deg, #971d7d 0, #f93c3c 100%); }

.gradient-6 > a:after {
  background: linear-gradient(45deg, #97931d 0, #0889bb 100%); }

/*------------------------------------
	Box Shadow
------------------------------------*/
.shadow-lrb-lg {
  box-shadow: 0 0.4rem 2.2rem 0 rgba(27, 30, 36, 0.1); }

.shadow-lrb-sm {
  box-shadow: 0 0.1rem 0.6rem 0 rgba(27, 30, 36, 0.1); }

.shadow-b {
  box-shadow: 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.1); }

.shadow-b-sm {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1); }

.shadow-t {
  box-shadow: 0 -0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.1); }

.shadow-r {
  box-shadow: 0.5rem 0 1rem -0.5rem rgba(0, 0, 0, 0.1); }

.shadow-l {
  box-shadow: -0.5rem 0 1rem -0.5rem rgba(0, 0, 0, 0.1); }

.no-shadow {
  box-shadow: none !important; }

/*------------------------------------
	Dark Skin
------------------------------------*/
.dark-skin {
  color: #6c757d;
  background-color: #1b1b1b; }

.dark-skin .bg-image {
  background-color: #1b1b1b; }

.dark-skin.full-width {
  background: #1b1b1b !important; }

.dark-skin pre {
  color: #f8f9fa;
  background: #000; }

.dark-skin .logo-sidenav a:before {
  background: #495057; }

.dark-skin .widget ol li a, .dark-skin .widget ul li a {
  color: #d0d0d0; }

.dark-skin .widget .recentcomments .comment-author-link {
  color: #f8f9fa; }

.dark-skin a.badge {
  color: #f8f9fa;
  background-color: #4f46e5; }

.dark-skin a.badge.badge-light {
  color: #f8f9fa;
  background-color: #1b1b1b; }

.dark-skin a.btn {
  color: #fff; }

.dark-skin a.btn.btn-light, .dark-skin a.btn.btn-warning {
  color: #1b1b1b; }

.dark-skin a.back-top {
  color: #f8f9fa; }

.dark-skin h1, .dark-skin .h1, .dark-skin h2, .dark-skin .h2, .dark-skin h3, .dark-skin .h3, .dark-skin h4, .dark-skin .h4, .dark-skin h5, .dark-skin .h5, .dark-skin h6, .dark-skin .h6, .dark-skin .h1, .dark-skin .h2, .dark-skin .h3, .dark-skin .h4, .dark-skin .h5, .dark-skin .h6 {
  color: #f8f9fa; }

.dark-skin h1 > a, .dark-skin .h1 > a, .dark-skin h2 > a, .dark-skin .h2 > a, .dark-skin h3 > a, .dark-skin .h3 > a, .dark-skin h4 > a, .dark-skin .h4 > a, .dark-skin h5 > a, .dark-skin .h5 > a, .dark-skin h6 > a, .dark-skin .h6 > a, .dark-skin .h1 > a, .dark-skin .h2 > a, .dark-skin .h3 > a, .dark-skin .h4 > a, .dark-skin .h5 > a, .dark-skin .h6 > a {
  color: #f8f9fa; }

.dark-skin .bg-themes {
  background: #1b1b1b !important; }

.dark-skin .footer-dark {
  background-color: #111111 !important; }

.dark-skin .hover-a:hover h1 > a, .dark-skin .hover-a:hover .h1 > a, .dark-skin .hover-a:hover h2 > a, .dark-skin .hover-a:hover .h2 > a, .dark-skin .hover-a:hover h3 > a, .dark-skin .hover-a:hover .h3 > a, .dark-skin .hover-a:hover h4 > a, .dark-skin .hover-a:hover .h4 > a, .dark-skin .hover-a:hover h5 > a, .dark-skin .hover-a:hover .h5 > a, .dark-skin .hover-a:hover h6 > a, .dark-skin .hover-a:hover .h6 > a, .dark-skin .hover-a:hover .h1 > a, .dark-skin .hover-a:hover .h2 > a, .dark-skin .hover-a:hover .h3 > a, .dark-skin .hover-a:hover .h4 > a, .dark-skin .hover-a:hover .h5 > a, .dark-skin .hover-a:hover .h6 > a {
  color: #4f46e5 !important; }

.dark-skin .hover-a:hover h1 > a.text-white, .dark-skin .hover-a:hover .h1 > a.text-white, .dark-skin .hover-a:hover h2 > a.text-white, .dark-skin .hover-a:hover .h2 > a.text-white, .dark-skin .hover-a:hover h3 > a.text-white, .dark-skin .hover-a:hover .h3 > a.text-white, .dark-skin .hover-a:hover h4 > a.text-white, .dark-skin .hover-a:hover .h4 > a.text-white, .dark-skin .hover-a:hover h5 > a.text-white, .dark-skin .hover-a:hover .h5 > a.text-white, .dark-skin .hover-a:hover h6 > a.text-white, .dark-skin .hover-a:hover .h6 > a.text-white, .dark-skin .hover-a:hover .h1 > a.text-white, .dark-skin .hover-a:hover .h2 > a.text-white, .dark-skin .hover-a:hover .h3 > a.text-white, .dark-skin .hover-a:hover .h4 > a.text-white, .dark-skin .hover-a:hover .h5 > a.text-white, .dark-skin .hover-a:hover .h6 > a.text-white {
  color: #fff !important; }

.dark-skin .sub-card ul li a {
  color: #f8f9fa; }

.dark-skin .sub-card ul li a:hover {
  color: #4f46e5 !important; }

.dark-skin .nav-tabs .nav-link {
  border-color: #495057;
  color: #f8f9fa; }

.dark-skin .nav-block-more a.nav-link {
  color: #f8f9fa; }

.dark-skin .page-link {
  background-color: #1b1b1b;
  border-color: #495057; }

.dark-skin .dark-menu .nav-tabs .nav-link {
  border-color: #1b1b1b; }

.dark-skin .sidenav-menu .dropdown-menu {
  background: #1b1b1b;
  border-color: #495057 !important; }

.dark-skin .side-link li a, .dark-skin .sidebar-link li a {
  color: #f8f9fa; }

.dark-skin .side-link li a.caret-collapse, .dark-skin .sidebar-link li a.caret-collapse {
  color: #4f46e5; }

.dark-skin .side-link li a.caret-collapse.collapsed, .dark-skin .sidebar-link li a.caret-collapse.collapsed {
  color: #f8f9fa; }

.dark-skin .side-link li a.caret-collapse.collapsed:hover, .dark-skin .side-link li a.caret-collapse.collapsed.active, .dark-skin .sidebar-link li a.caret-collapse.collapsed:hover, .dark-skin .sidebar-link li a.caret-collapse.collapsed.active {
  color: #4f46e5; }

.dark-skin .side-link li a:hover, .dark-skin .side-link li a.active, .dark-skin .sidebar-link li a:hover, .dark-skin .sidebar-link li a.active {
  color: #4f46e5; }

.dark-skin .dropcaps-one {
  color: #f6f6f6; }

.dark-skin .main-menu.navbar-light h1, .dark-skin .main-menu.navbar-light .h1, .dark-skin .main-menu.navbar-light h2, .dark-skin .main-menu.navbar-light .h2, .dark-skin .main-menu.navbar-light h3, .dark-skin .main-menu.navbar-light .h3, .dark-skin .main-menu.navbar-light h4, .dark-skin .main-menu.navbar-light .h4, .dark-skin .main-menu.navbar-light h5, .dark-skin .main-menu.navbar-light .h5, .dark-skin .main-menu.navbar-light h6, .dark-skin .main-menu.navbar-light .h6, .dark-skin .main-menu.navbar-light .h1, .dark-skin .main-menu.navbar-light .h2, .dark-skin .main-menu.navbar-light .h3, .dark-skin .main-menu.navbar-light .h4, .dark-skin .main-menu.navbar-light .h5, .dark-skin .main-menu.navbar-light .h6 {
  color: #1b1b1b !important; }

.dark-skin .main-menu.navbar-light h1 > a, .dark-skin .main-menu.navbar-light .h1 > a, .dark-skin .main-menu.navbar-light h2 > a, .dark-skin .main-menu.navbar-light .h2 > a, .dark-skin .main-menu.navbar-light h3 > a, .dark-skin .main-menu.navbar-light .h3 > a, .dark-skin .main-menu.navbar-light h4 > a, .dark-skin .main-menu.navbar-light .h4 > a, .dark-skin .main-menu.navbar-light h5 > a, .dark-skin .main-menu.navbar-light .h5 > a, .dark-skin .main-menu.navbar-light h6 > a, .dark-skin .main-menu.navbar-light .h6 > a, .dark-skin .main-menu.navbar-light .h1 > a, .dark-skin .main-menu.navbar-light .h2 > a, .dark-skin .main-menu.navbar-light .h3 > a, .dark-skin .main-menu.navbar-light .h4 > a, .dark-skin .main-menu.navbar-light .h5 > a, .dark-skin .main-menu.navbar-light .h6 > a {
  color: #1b1b1b !important; }

.dark-skin .main-menu.navbar-light h1:hover, .dark-skin .main-menu.navbar-light .h1:hover, .dark-skin .main-menu.navbar-light h2:hover, .dark-skin .main-menu.navbar-light .h2:hover, .dark-skin .main-menu.navbar-light h3:hover, .dark-skin .main-menu.navbar-light .h3:hover, .dark-skin .main-menu.navbar-light h4:hover, .dark-skin .main-menu.navbar-light .h4:hover, .dark-skin .main-menu.navbar-light h5:hover, .dark-skin .main-menu.navbar-light .h5:hover, .dark-skin .main-menu.navbar-light h6:hover, .dark-skin .main-menu.navbar-light .h6:hover, .dark-skin .main-menu.navbar-light .h1:hover, .dark-skin .main-menu.navbar-light .h2:hover, .dark-skin .main-menu.navbar-light .h3:hover, .dark-skin .main-menu.navbar-light .h4:hover, .dark-skin .main-menu.navbar-light .h5:hover, .dark-skin .main-menu.navbar-light .h6:hover {
  color: #4f46e5 !important; }

.dark-skin .main-menu.navbar-light .hover-a:hover h1 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h1 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h2 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h2 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h3 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h3 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h4 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h4 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h5 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h5 > a, .dark-skin .main-menu.navbar-light .hover-a:hover h6 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h6 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h1 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h2 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h3 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h4 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h5 > a, .dark-skin .main-menu.navbar-light .hover-a:hover .h6 > a {
  color: #4f46e5 !important; }

.dark-skin .main-menu.navbar-light .dropdown-item {
  color: #1b1b1b; }

.dark-skin .main-menu.navbar-light .dropdown-item.active {
  color: #4f46e5; }

.dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link {
  border-color: transparent;
  color: #1b1b1b !important; }

.dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link.active, .dark-skin .main-menu.navbar-light .vertical-tabs .nav-tabs .nav-link:hover {
  color: #4f46e5 !important;
  border-color: #dee2e6 #fff #dee2e6 #fff;
  background-color: #fff; }

@media (min-width: 992px) {
  .dark-skin .main-menu.navbar-light .hover-mode .main-nav li:hover > a {
    color: #4f46e5; }
  .dark-skin .main-menu.navbar-dark .hover-mode .main-nav li:hover > a {
    background-color: #1b1b1b; }
  .dark-skin .main-menu.navbar-dark .hover-mode .main-nav .mega-menu li.menu-item-has-children:hover > a {
    background-color: transparent; } }

.dark-skin .card, .dark-skin .sidebar-menu {
  background: #1b1b1b; }

.dark-skin .bg-light-dark {
  background: #1b1b1b !important; }

.dark-skin .bg-light-black {
  background: #000 !important; }

.dark-skin .back-top {
  background: #1b1b1b;
  color: #f8f9fa !important; }

.dark-skin .bg-input, .dark-skin .match-title {
  background: #000 !important; }

.dark-skin .hover-link li a:hover {
  background: #000; }

.dark-skin .match-score > li:hover {
  background: #000; }

.dark-skin .ql-toolbar.ql-snow {
  background: #fff;
  border-color: #495057; }

.dark-skin .bg-pattern-repeat {
  background: url(../img/background/pattern5.png) repeat #f6f6f6; }

.dark-skin .sidebar-menu li a:hover, .dark-skin .sidebar-menu li a.active, .dark-skin .unread, .dark-skin .table-striped tbody tr:nth-of-type(odd) {
  background-color: #000; }

.dark-skin .form-control {
  color: #f8f9fa;
  background-color: #000;
  border-color: #495057; }

.dark-skin .form-control:hover, .dark-skin .form-control:focus {
  background-color: #000; }

.dark-skin .input-group-text {
  color: #f8f9fa;
  background-color: #1b1b1b;
  border-color: #495057; }

.dark-skin .list-group-item {
  background-color: transparent; }

.dark-skin .table {
  color: #f8f9fa; }

.dark-skin .post-content ul, .dark-skin .post-content ol {
  color: #d0d0d0; }

.dark-skin p {
  color: #d0d0d0; }

.dark-skin .tags-list-cat li {
  border-color: #495057; }

.dark-skin .tags-list li {
  border-color: #495057; }

.dark-skin .tags-list li:first-child {
  border-color: transparent; }

.dark-skin .tags-list li:first-child span {
  color: #dee2e6; }

.dark-skin .u-blockquote, .dark-skin .wp-block-quote {
  background: #000; }

.dark-skin .close-suggestion i {
  color: #fff; }

.dark-skin .prev-post-left > a, .dark-skin .next-post-right > a {
  color: #d0d0d0; }

.dark-skin .prev-post-left > a:hover, .dark-skin .next-post-right > a:hover {
  color: #4f46e5; }

.dark-skin .comment-list li .user-comment {
  border-color: #495057; }

.dark-skin .invalid-feedback {
  padding-left: 15px;
  background: #dc3545;
  color: #fff; }

.dark-skin hr, .dark-skin .img-thumbnail, .dark-skin .table th, .dark-skin .table td, .dark-skin .table thead th, .dark-skin .table tbody + tbody, .dark-skin .table-bordered, .dark-skin .table-bordered th,
.dark-skin .table-bordered td, .dark-skin .table .thead-light th, .dark-skin .nav-tabs, .dark-skin .card, .dark-skin .card-header, .dark-skin .card-footer, .dark-skin .page-link, .dark-skin .page-link:hover, .dark-skin .page-item.disabled .page-link, .dark-skin .modal-header, .dark-skin .modal-footer, .dark-skin .ql-container.ql-snow {
  border-color: #495057; }

.dark-skin .nav-tabs .nav-link:hover, .dark-skin .nav-tabs .nav-link:focus {
  border-color: #1b1b1b #1b1b1b #495057; }

.dark-skin .nav-tabs .nav-link.active,
.dark-skin .nav-tabs .nav-item.show .nav-link {
  border-color: #495057 #495057 #000; }

.dark-skin .border, .dark-skin .border-top, .dark-skin .border-right, .dark-skin .border-bottom, .dark-skin .border-left {
  border-color: #495057 !important; }

.dark-skin .border-smooth {
  border-color: rgba(255, 255, 255, 0.12) !important; }

.dark-skin .border-smooth-light {
  border-color: #ced4da !important; }

.dark-skin .block-title-1, .dark-skin .block-title-2, .dark-skin .block-title-3, .dark-skin .block-title-4, .dark-skin .block-title-5, .dark-skin .block-title-5 h1 span, .dark-skin .block-title-5 .h1 span, .dark-skin .block-title-5 h2 span, .dark-skin .block-title-5 .h2 span, .dark-skin .block-title-5 h3 span, .dark-skin .block-title-5 .h3 span, .dark-skin .block-title-5 h4 span, .dark-skin .block-title-5 .h4 span, .dark-skin .block-title-5 h5 span, .dark-skin .block-title-5 .h5 span, .dark-skin .block-title-5 h6 span, .dark-skin .block-title-5 .h6 span,
.dark-skin .block-title-6 h1,
.dark-skin .block-title-6 .h1, .dark-skin .block-title-6 h2, .dark-skin .block-title-6 .h2, .dark-skin .block-title-6 h3, .dark-skin .block-title-6 .h3, .dark-skin .block-title-6 h4, .dark-skin .block-title-6 .h4, .dark-skin .block-title-6 h5, .dark-skin .block-title-6 .h5, .dark-skin .block-title-6 h6, .dark-skin .block-title-6 .h6,
.dark-skin .block-title-9 h1 span,
.dark-skin .block-title-9 .h1 span, .dark-skin .block-title-9 h2 span, .dark-skin .block-title-9 .h2 span, .dark-skin .block-title-9 h3 span, .dark-skin .block-title-9 .h3 span, .dark-skin .block-title-9 h4 span, .dark-skin .block-title-9 .h4 span, .dark-skin .block-title-9 h5 span, .dark-skin .block-title-9 .h5 span, .dark-skin .block-title-9 h6 span, .dark-skin .block-title-9 .h6 span, .dark-skin .block-title-15 h1, .dark-skin .block-title-15 .h1, .dark-skin .block-title-15 h2, .dark-skin .block-title-15 .h2, .dark-skin .block-title-15 h3, .dark-skin .block-title-15 .h3, .dark-skin .block-title-15 h4, .dark-skin .block-title-15 .h4, .dark-skin .block-title-15 h5, .dark-skin .block-title-15 .h5, .dark-skin .block-title-15 h6, .dark-skin .block-title-15 .h6,
.dark-skin .vertical-tabs .nav.nav-tabs, .dark-skin .list-group-level2, .dark-skin .list-group-level1, .dark-skin .suggestion-box, .dark-skin .comment-list article, .dark-skin .tags-list li, .dark-skin .tags-list-cat li, .dark-skin .sidebar-item.active, .dark-skin .sidebar-item:hover, .dark-skin .sidebar-menu, .dark-skin .messages-list li p, .dark-skin .timeline-post > li:before {
  border-color: #495057; }

.dark-skin .navbar-nav .vertical-tabs .nav-tabs .nav-link.active {
  border-color: #495057 #000 #495057 #000; }

.dark-skin .nav-tabs .nav-link {
  border-color: #495057 #495057 #000; }

.dark-skin .nav-tabs .nav-link:hover, .dark-skin .nav-tabs .nav-link:focus {
  border-color: #495057 #495057 #000; }

.dark-skin .border-top-first-0 > li, .dark-skin .border-top-first-0 > article, .dark-skin .border-left-first-0 > li, .dark-skin .border-left-first-0 > article, .dark-skin .border-right-last-0 > li, .dark-skin .border-right-last-0 > article, .dark-skin .border-bottom-last-0 > li, .dark-skin .border-bottom-last-0 > article {
  border-color: #495057; }

.dark-skin .border-top-first-0 > a, .dark-skin .border-left-first-0 > a, .dark-skin .border-right-last-0 > a, .dark-skin .border-bottom-last-0 > a {
  border-color: #495057; }

.boxed.dark-skin .wrapper, .framed.dark-skin .wrapper {
  box-shadow: 0 0 3px #000;
  background-color: #1b1b1b; }

.framed.dark-skin .wrapper {
  box-shadow: 0 0 3px #000;
  background-color: rgba(0, 0, 0, 0.7); }

.framed.dark-skin .wrapper main, .framed.dark-skin .wrapper .second-header {
  background: transparent; }

.dark-skin .border-top-first-0 > li, .dark-skin .border-top-first-0 > article, .dark-skin .border-left-first-0 > li, .dark-skin .border-left-first-0 > article, .dark-skin .border-right-last-0 > li, .dark-skin .border-right-last-0 > article, .dark-skin .border-bottom-last-0 > li, .dark-skin .border-bottom-last-0 > article {
  border-color: #495057; }

.dark-skin .border-top-first-0 > a, .dark-skin .border-left-first-0 > a, .dark-skin .border-right-last-0 > a, .dark-skin .border-bottom-last-0 > a {
  border-color: #495057; }

@media (min-width: 576px) {
  .dark-skin .border-top-first-sm-0 > li, .dark-skin .border-top-first-sm-0 > article, .dark-skin .border-left-first-sm-0 > li, .dark-skin .border-left-first-sm-0 > article, .dark-skin .border-right-last-sm-0 > li, .dark-skin .border-right-last-sm-0 > article, .dark-skin .border-bottom-last-sm-0 > li, .dark-skin .border-bottom-last-sm-0 > article {
    border-color: #495057; }
  .dark-skin .border-top-first-sm-0 > a, .dark-skin .border-left-first-sm-0 > a, .dark-skin .border-right-last-sm-0 > a, .dark-skin .border-bottom-last-sm-0 > a {
    border-color: #495057; } }

@media (min-width: 768px) {
  .dark-skin .border-top-first-md-0 > li, .dark-skin .border-top-first-md-0 > article, .dark-skin .border-left-first-md-0 > li, .dark-skin .border-left-first-md-0 > article, .dark-skin .border-right-last-md-0 > li, .dark-skin .border-right-last-md-0 > article, .dark-skin .border-bottom-last-md-0 > li, .dark-skin .border-bottom-last-md-0 > article {
    border-color: #495057; }
  .dark-skin .border-top-first-md-0 > a, .dark-skin .border-left-first-md-0 > a, .dark-skin .border-right-last-md-0 > a, .dark-skin .border-bottom-last-md-0 > a {
    border-color: #495057; } }

@media (min-width: 992px) {
  .dark-skin .border-top-first-lg-0 > li, .dark-skin .border-top-first-lg-0 > article, .dark-skin .border-left-first-lg-0 > li, .dark-skin .border-left-first-lg-0 > article, .dark-skin .border-right-last-lg-0 > li, .dark-skin .border-right-last-lg-0 > article, .dark-skin .border-bottom-last-lg-0 > li, .dark-skin .border-bottom-last-lg-0 > article {
    border-color: #495057; }
  .dark-skin .border-top-first-lg-0 > a, .dark-skin .border-left-first-lg-0 > a, .dark-skin .border-right-last-lg-0 > a, .dark-skin .border-bottom-last-lg-0 > a {
    border-color: #495057; } }

@media (min-width: 1200px) {
  .dark-skin .border-top-first-xl-0 > li, .dark-skin .border-top-first-xl-0 > article, .dark-skin .border-left-first-xl-0 > li, .dark-skin .border-left-first-xl-0 > article, .dark-skin .border-right-last-xl-0 > li, .dark-skin .border-right-last-xl-0 > article, .dark-skin .border-bottom-last-xl-0 > li, .dark-skin .border-bottom-last-xl-0 > article {
    border-color: #495057; }
  .dark-skin .border-top-first-xl-0 > a, .dark-skin .border-left-first-xl-0 > a, .dark-skin .border-right-last-xl-0 > a, .dark-skin .border-bottom-last-xl-0 > a {
    border-color: #495057; } }

@media (min-width: 1400px) {
  .dark-skin .border-top-first-xxl-0 > li, .dark-skin .border-top-first-xxl-0 > article, .dark-skin .border-left-first-xxl-0 > li, .dark-skin .border-left-first-xxl-0 > article, .dark-skin .border-right-last-xxl-0 > li, .dark-skin .border-right-last-xxl-0 > article, .dark-skin .border-bottom-last-xxl-0 > li, .dark-skin .border-bottom-last-xxl-0 > article {
    border-color: #495057; }
  .dark-skin .border-top-first-xxl-0 > a, .dark-skin .border-left-first-xxl-0 > a, .dark-skin .border-right-last-xxl-0 > a, .dark-skin .border-bottom-last-xxl-0 > a {
    border-color: #495057; } }

/*------------------------------------
	Z index
------------------------------------*/
.z-index-5 {
  z-index: 5; }

.z-index-10 {
  z-index: 10; }

.z-index-20 {
  z-index: 20; }

.z-index-50 {
  z-index: 50; }

.z-index-100 {
  z-index: 100; }

.z-index-500 {
  z-index: 500; }

.z-index-1000 {
  z-index: 1000; }

/*------------------------------------
 Font weight
------------------------------------*/
.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.font-size-1 {
  font-size: 1rem; }

.font-size-2 {
  font-size: 2rem; }

.font-size-3 {
  font-size: 3rem; }

.font-size-4 {
  font-size: 4rem; }

.font-size-5 {
  font-size: 5rem; }

/*------------------------------------
	Typograpy
------------------------------------*/
/*extra padding*/
.p-05 {
  padding: 0.125rem !important; }

.px-05, .ps-05 {
  padding-left: 0.125rem !important; }

.px-05, .pe-05 {
  padding-right: 0.125rem !important; }

.py-05, .pt-05 {
  padding-top: 0.125rem !important; }

.py-05, .pb-05 {
  padding-bottom: 0.125rem !important; }

@media (min-width: 576px) {
  /*extra padding*/
  .p-sm-05 {
    padding: 0.125rem !important; }
  .px-sm-05, .ps-sm-05 {
    padding-left: 0.125rem !important; }
  .px-sm-05, .pe-sm-05 {
    padding-right: 0.125rem !important; }
  .py-sm-05, .pt-sm-05 {
    padding-top: 0.125rem !important; }
  .py-sm-05, .pb-sm-05 {
    padding-bottom: 0.125rem !important; } }

@media (min-width: 768px) {
  /*extra padding*/
  .p-md-05 {
    padding: 0.125rem !important; }
  .px-md-05, .ps-md-05 {
    padding-left: 0.125rem !important; }
  .px-md-05, .pe-md-05 {
    padding-right: 0.125rem !important; }
  .py-md-05, .pt-md-05 {
    padding-top: 0.125rem !important; }
  .py-md-05, .pb-md-05 {
    padding-bottom: 0.125rem !important; } }

@media (min-width: 992px) {
  /*extra padding*/
  .p-lg-05 {
    padding: 0.125rem !important; }
  .px-lg-05, .ps-lg-05 {
    padding-left: 0.125rem !important; }
  .px-lg-05, .pe-lg-05 {
    padding-right: 0.125rem !important; }
  .py-lg-05, .pt-lg-05 {
    padding-top: 0.125rem !important; }
  .py-lg-05, .pb-lg-05 {
    padding-bottom: 0.125rem !important; } }

@media (min-width: 1200px) {
  /*extra padding*/
  .p-xl-05 {
    padding: 0.125rem !important; }
  .px-xl-05, .ps-xl-05 {
    padding-left: 0.125rem !important; }
  .px-xl-05, .pe-xl-05 {
    padding-right: 0.125rem !important; }
  .py-xl-05, .pt-xl-05 {
    padding-top: 0.125rem !important; }
  .py-xl-05, .pb-xl-05 {
    padding-bottom: 0.125rem !important; } }

@media (min-width: 1400px) {
  /*extra padding*/
  .p-xxl-05 {
    padding: 0.125rem !important; }
  .px-xxl-05, .ps-xxl-05 {
    padding-left: 0.125rem !important; }
  .px-xxl-05, .pe-xxl-05 {
    padding-right: 0.125rem !important; }
  .py-xxl-05, .pt-xxl-05 {
    padding-top: 0.125rem !important; }
  .py-xxl-05, .pb-xxl-05 {
    padding-bottom: 0.125rem !important; } }

.m-module {
  margin: 2rem; }

.mt-module {
  margin-top: 2rem; }

.mr-module {
  margin-right: 2rem; }

.mb-module {
  margin-bottom: 2rem; }

.ml-module {
  margin-left: 2rem; }

.mt-minus {
  margin-top: -2.5rem; }

.mt-minus-sm {
  margin-top: -1rem; }

.mt-minus-md {
  margin-top: -3rem; }

.mt-minus-lg {
  margin-top: -6rem; }

/*------------------------------------
	Space
------------------------------------*/
/*top*/
.t-0 {
  top: 0; }

.t-1 {
  top: 1rem; }

.t-2 {
  top: 2rem; }

.t-3 {
  top: 3rem; }

.t-4 {
  top: 4rem; }

/*bottom*/
.b-0 {
  bottom: 0; }

.b-1 {
  bottom: 1rem; }

.b-2 {
  bottom: 2rem; }

.b-3 {
  bottom: 3rem; }

.b-4 {
  bottom: 4rem; }

/*right*/
.r-0 {
  right: 0; }

.r-1 {
  right: 1rem; }

.r-2 {
  right: 2rem; }

.r-3 {
  right: 3rem; }

.r-4 {
  right: 4rem; }

/*left*/
.l-0 {
  left: 0rem; }

.l-1 {
  left: 1rem; }

.l-2 {
  left: 2rem; }

.l-3 {
  left: 3rem; }

.l-4 {
  left: 4rem; }

.no-margin {
  right: 0;
  bottom: 0;
  left: 0; }

/*------------------------------------
  Space Percent
------------------------------------*/
/*top*/
.top-1 {
  top: 10%; }

.top-2 {
  top: 20%; }

.top-3 {
  top: 30%; }

.top-4 {
  top: 40%; }

.top-5 {
  top: 50%; }

/*bottom*/
.bottom-1 {
  bottom: 10%; }

.bottom-2 {
  bottom: 20%; }

.bottom-3 {
  bottom: 30%; }

.bottom-4 {
  bottom: 40%; }

.bottom-5 {
  bottom: 50%; }

/*right*/
.right-1 {
  right: 10%; }

.right-2 {
  right: 20%; }

.right-3 {
  right: 30%; }

.right-4 {
  right: 40%; }

.right-5 {
  right: 50%; }

/*left*/
.left-1 {
  left: 10%; }

.left-2 {
  left: 20%; }

.left-3 {
  left: 30%; }

.left-4 {
  left: 40%; }

.left-5 {
  left: 50%; }

.h-0 {
  height: 0; }

.gap-0, .gap-05, .gap-075, .gap-1, .gap-15, .gap-2, .gap-25, .gap-3, .gap-4 {
  width: 100%;
  clear: both; }

.gap-0 {
  height: .1px; }

.gap-05 {
  height: 0.5rem; }

.gap-075 {
  height: 0.75rem; }

.gap-1 {
  height: 1rem; }

.gap-15 {
  height: 1.5rem; }

.gap-2 {
  height: 2rem; }

.gap-25 {
  height: 2.5rem; }

.gap-3 {
  height: 3rem; }

.gap-4 {
  height: 4rem; }

/*------------------------------------
	Border width
------------------------------------*/
.border-none {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-smooth {
  border-color: rgba(255, 255, 255, 0.12) !important; }

.border-smooth-light {
  border-color: #ced4da !important; }

.border-0 {
  border-width: 0px !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

@media (min-width: 576px) {
  .border-sm-0 {
    border-width: 0px !important; }
  .border-sm-1 {
    border-width: 1px !important; }
  .border-sm-2 {
    border-width: 2px !important; }
  .border-sm-3 {
    border-width: 3px !important; }
  .border-sm-4 {
    border-width: 4px !important; }
  .border-sm-5 {
    border-width: 5px !important; } }

@media (min-width: 768px) {
  .border-md-0 {
    border-width: 0px !important; }
  .border-md-1 {
    border-width: 1px !important; }
  .border-md-2 {
    border-width: 2px !important; }
  .border-md-3 {
    border-width: 3px !important; }
  .border-md-4 {
    border-width: 4px !important; }
  .border-md-5 {
    border-width: 5px !important; } }

@media (min-width: 992px) {
  .border-lg-0 {
    border-width: 0px !important; }
  .border-lg-1 {
    border-width: 1px !important; }
  .border-lg-2 {
    border-width: 2px !important; }
  .border-lg-3 {
    border-width: 3px !important; }
  .border-lg-4 {
    border-width: 4px !important; }
  .border-lg-5 {
    border-width: 5px !important; } }

@media (min-width: 1200px) {
  .border-xl-0 {
    border-width: 0px !important; }
  .border-xl-1 {
    border-width: 1px !important; }
  .border-xl-2 {
    border-width: 2px !important; }
  .border-xl-3 {
    border-width: 3px !important; }
  .border-xl-4 {
    border-width: 4px !important; }
  .border-xl-5 {
    border-width: 5px !important; } }

@media (min-width: 1400px) {
  .border-xxl-0 {
    border-width: 0px !important; }
  .border-xxl-1 {
    border-width: 1px !important; }
  .border-xxl-2 {
    border-width: 2px !important; }
  .border-xxl-3 {
    border-width: 3px !important; }
  .border-xxl-4 {
    border-width: 4px !important; }
  .border-xxl-5 {
    border-width: 5px !important; } }

/*------------------------------------
 Border Child
------------------------------------*/
.border-top-first-0 > li, .border-top-first-0 > article {
  border-top: 1px solid #dee2e6; }

.border-top-first-0 > li:first-child, .border-top-first-0 > article:first-child {
  border-top: none; }

.border-top-first-0 > a {
  border-top: 1px solid #dee2e6; }

.border-top-first-0 > a:first-child {
  border-top: none; }

.border-left-first-0 > li, .border-left-first-0 > article {
  border-left: 1px solid #dee2e6; }

.border-left-first-0 > li:first-child, .border-left-first-0 > article:first-child {
  border-left: none; }

.border-left-first-0 > a {
  border-left: 1px solid #dee2e6; }

.border-left-first-0 > a:first-child {
  border-left: none; }

.border-right-last-0 > li, .border-right-last-0 > article {
  border-right: 1px solid #dee2e6; }

.border-right-last-0 > li:last-child, .border-right-last-0 > article:last-child {
  border-right: none; }

.border-right-last-0 > a {
  border-right: 1px solid #dee2e6; }

.border-right-last-0 > a:last-child {
  border-right: none; }

.border-bottom-last-0 > li, .border-bottom-last-0 > article {
  border-bottom: 1px solid #dee2e6; }

.border-bottom-last-0 > li:last-child, .border-bottom-last-0 > article:last-child {
  border-bottom: none; }

.border-bottom-last-0 > a {
  border-bottom: 1px solid #dee2e6; }

.border-bottom-last-0 > a:last-child {
  border-bottom: none; }

.hr-after {
  background: #1b1b1b;
  height: 1px;
  width: 5rem; }

.border-top-first-0 > li, .border-top-first-0 > article {
  border-top: 1px solid #dee2e6; }

.border-top-first-0 > li:first-child, .border-top-first-0 > article:first-child {
  border-top: none; }

.border-top-first-0 > a {
  border-top: 1px solid #dee2e6; }

.border-top-first-0 > a:first-child {
  border-top: none; }

.border-left-first-0 > li, .border-left-first-0 > article {
  border-left: 1px solid #dee2e6; }

.border-left-first-0 > li:first-child, .border-left-first-0 > article:first-child {
  border-left: none; }

.border-left-first-0 > a {
  border-left: 1px solid #dee2e6; }

.border-left-first-0 > a:first-child {
  border-left: none; }

.border-right-last-0 > li, .border-right-last-0 > article {
  border-right: 1px solid #dee2e6; }

.border-right-last-0 > li:last-child, .border-right-last-0 > article:last-child {
  border-right: none; }

.border-right-last-0 > a {
  border-right: 1px solid #dee2e6; }

.border-right-last-0 > a:last-child {
  border-right: none; }

.border-bottom-last-0 > li, .border-bottom-last-0 > article {
  border-bottom: 1px solid #dee2e6; }

.border-bottom-last-0 > li:last-child, .border-bottom-last-0 > article:last-child {
  border-bottom: none; }

.border-bottom-last-0 > a {
  border-bottom: 1px solid #dee2e6; }

.border-bottom-last-0 > a:last-child {
  border-bottom: none; }

@media (min-width: 576px) {
  .border-top-first-sm-0 > li, .border-top-first-sm-0 > article {
    border-top: 1px solid #dee2e6; }
  .border-top-first-sm-0 > li:first-child, .border-top-first-sm-0 > article:first-child {
    border-top: none; }
  .border-top-first-sm-0 > a {
    border-top: 1px solid #dee2e6; }
  .border-top-first-sm-0 > a:first-child {
    border-top: none; }
  .border-left-first-sm-0 > li, .border-left-first-sm-0 > article {
    border-left: 1px solid #dee2e6; }
  .border-left-first-sm-0 > li:first-child, .border-left-first-sm-0 > article:first-child {
    border-left: none; }
  .border-left-first-sm-0 > a {
    border-left: 1px solid #dee2e6; }
  .border-left-first-sm-0 > a:first-child {
    border-left: none; }
  .border-right-last-sm-0 > li, .border-right-last-sm-0 > article {
    border-right: 1px solid #dee2e6; }
  .border-right-last-sm-0 > li:last-child, .border-right-last-sm-0 > article:last-child {
    border-right: none; }
  .border-right-last-sm-0 > a {
    border-right: 1px solid #dee2e6; }
  .border-right-last-sm-0 > a:last-child {
    border-right: none; }
  .border-bottom-last-sm-0 > li, .border-bottom-last-sm-0 > article {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-sm-0 > li:last-child, .border-bottom-last-sm-0 > article:last-child {
    border-bottom: none; }
  .border-bottom-last-sm-0 > a {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-sm-0 > a:last-child {
    border-bottom: none; } }

@media (min-width: 768px) {
  .border-top-first-md-0 > li, .border-top-first-md-0 > article {
    border-top: 1px solid #dee2e6; }
  .border-top-first-md-0 > li:first-child, .border-top-first-md-0 > article:first-child {
    border-top: none; }
  .border-top-first-md-0 > a {
    border-top: 1px solid #dee2e6; }
  .border-top-first-md-0 > a:first-child {
    border-top: none; }
  .border-left-first-md-0 > li, .border-left-first-md-0 > article {
    border-left: 1px solid #dee2e6; }
  .border-left-first-md-0 > li:first-child, .border-left-first-md-0 > article:first-child {
    border-left: none; }
  .border-left-first-md-0 > a {
    border-left: 1px solid #dee2e6; }
  .border-left-first-md-0 > a:first-child {
    border-left: none; }
  .border-right-last-md-0 > li, .border-right-last-md-0 > article {
    border-right: 1px solid #dee2e6; }
  .border-right-last-md-0 > li:last-child, .border-right-last-md-0 > article:last-child {
    border-right: none; }
  .border-right-last-md-0 > a {
    border-right: 1px solid #dee2e6; }
  .border-right-last-md-0 > a:last-child {
    border-right: none; }
  .border-bottom-last-md-0 > li, .border-bottom-last-md-0 > article {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-md-0 > li:last-child, .border-bottom-last-md-0 > article:last-child {
    border-bottom: none; }
  .border-bottom-last-md-0 > a {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-md-0 > a:last-child {
    border-bottom: none; } }

@media (min-width: 992px) {
  .border-top-first-lg-0 > li, .border-top-first-lg-0 > article {
    border-top: 1px solid #dee2e6; }
  .border-top-first-lg-0 > li:first-child, .border-top-first-lg-0 > article:first-child {
    border-top: none; }
  .border-top-first-lg-0 > a {
    border-top: 1px solid #dee2e6; }
  .border-top-first-lg-0 > a:first-child {
    border-top: none; }
  .border-left-first-lg-0 > li, .border-left-first-lg-0 > article {
    border-left: 1px solid #dee2e6; }
  .border-left-first-lg-0 > li:first-child, .border-left-first-lg-0 > article:first-child {
    border-left: none; }
  .border-left-first-lg-0 > a {
    border-left: 1px solid #dee2e6; }
  .border-left-first-lg-0 > a:first-child {
    border-left: none; }
  .border-right-last-lg-0 > li, .border-right-last-lg-0 > article {
    border-right: 1px solid #dee2e6; }
  .border-right-last-lg-0 > li:last-child, .border-right-last-lg-0 > article:last-child {
    border-right: none; }
  .border-right-last-lg-0 > a {
    border-right: 1px solid #dee2e6; }
  .border-right-last-lg-0 > a:last-child {
    border-right: none; }
  .border-bottom-last-lg-0 > li, .border-bottom-last-lg-0 > article {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-lg-0 > li:last-child, .border-bottom-last-lg-0 > article:last-child {
    border-bottom: none; }
  .border-bottom-last-lg-0 > a {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-lg-0 > a:last-child {
    border-bottom: none; } }

@media (min-width: 1200px) {
  .border-top-first-xl-0 > li, .border-top-first-xl-0 > article {
    border-top: 1px solid #dee2e6; }
  .border-top-first-xl-0 > li:first-child, .border-top-first-xl-0 > article:first-child {
    border-top: none; }
  .border-top-first-xl-0 > a {
    border-top: 1px solid #dee2e6; }
  .border-top-first-xl-0 > a:first-child {
    border-top: none; }
  .border-left-first-xl-0 > li, .border-left-first-xl-0 > article {
    border-left: 1px solid #dee2e6; }
  .border-left-first-xl-0 > li:first-child, .border-left-first-xl-0 > article:first-child {
    border-left: none; }
  .border-left-first-xl-0 > a {
    border-left: 1px solid #dee2e6; }
  .border-left-first-xl-0 > a:first-child {
    border-left: none; }
  .border-right-last-xl-0 > li, .border-right-last-xl-0 > article {
    border-right: 1px solid #dee2e6; }
  .border-right-last-xl-0 > li:last-child, .border-right-last-xl-0 > article:last-child {
    border-right: none; }
  .border-right-last-xl-0 > a {
    border-right: 1px solid #dee2e6; }
  .border-right-last-xl-0 > a:last-child {
    border-right: none; }
  .border-bottom-last-xl-0 > li, .border-bottom-last-xl-0 > article {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-xl-0 > li:last-child, .border-bottom-last-xl-0 > article:last-child {
    border-bottom: none; }
  .border-bottom-last-xl-0 > a {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-xl-0 > a:last-child {
    border-bottom: none; } }

@media (min-width: 1400px) {
  .border-top-first-xxl-0 > li, .border-top-first-xxl-0 > article {
    border-top: 1px solid #dee2e6; }
  .border-top-first-xxl-0 > li:first-child, .border-top-first-xxl-0 > article:first-child {
    border-top: none; }
  .border-top-first-xxl-0 > a {
    border-top: 1px solid #dee2e6; }
  .border-top-first-xxl-0 > a:first-child {
    border-top: none; }
  .border-left-first-xxl-0 > li, .border-left-first-xxl-0 > article {
    border-left: 1px solid #dee2e6; }
  .border-left-first-xxl-0 > li:first-child, .border-left-first-xxl-0 > article:first-child {
    border-left: none; }
  .border-left-first-xxl-0 > a {
    border-left: 1px solid #dee2e6; }
  .border-left-first-xxl-0 > a:first-child {
    border-left: none; }
  .border-right-last-xxl-0 > li, .border-right-last-xxl-0 > article {
    border-right: 1px solid #dee2e6; }
  .border-right-last-xxl-0 > li:last-child, .border-right-last-xxl-0 > article:last-child {
    border-right: none; }
  .border-right-last-xxl-0 > a {
    border-right: 1px solid #dee2e6; }
  .border-right-last-xxl-0 > a:last-child {
    border-right: none; }
  .border-bottom-last-xxl-0 > li, .border-bottom-last-xxl-0 > article {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-xxl-0 > li:last-child, .border-bottom-last-xxl-0 > article:last-child {
    border-bottom: none; }
  .border-bottom-last-xxl-0 > a {
    border-bottom: 1px solid #dee2e6; }
  .border-bottom-last-xxl-0 > a:last-child {
    border-bottom: none; } }

/*------------------------------------
	Mobile screen
------------------------------------*/
@media (max-width: 767.98px) {
  .container {
    max-width: 100%; } }

@media (max-width: 575.98px) {
  .w-100-mobile {
    width: 100% !important; } }

/*------------------------------------
  Insert Your Custom SCSS
------------------------------------*/

/*# sourceMappingURL=theme.css.map */
